import React, { useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import WalletPayments from "./WalletPayments";
import SendAmountModal from "./SendAmountModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchWalletDetailsStart } from "../../store/slices/WalletSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Common/NoDataFound";
import WalletTransaction from "./WalletTransaction";

const UserWalletIndex = (props) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const [sendAmountModal, setSendAmountModal] = React.useState(false);
  const profile = useSelector((state) => state.user.profile);
  const adminPaymentDetails = useSelector(
    (state) => state.wallet.adminPaymentDetails
  );
  const wallet = useSelector((state) => state.wallet.wallet);

  const closeSendAmountModal = () => {
    setSendAmountModal(false);
  };

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(fetchWalletDetailsStart());
    }
  }, [profile]);

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="update-wallet-header-sec">
                <h3>User Wallet</h3>
                {/* <div className="find-offer-btn-sec">
                  <Button
                    onClick={() => setSendAmountModal(true)}
                    className="default-btn"
                  >
                    Send Amount
                  </Button>
                </div> */}
              </div>
              {wallet.loading ? (
                <Skeleton height={80} style={{ margin: "1em 0" }} />
              ) : Object.keys(wallet.data).length > 0 &&
                Object.keys(wallet.data.wallets).length > 0 ? (
                wallet.data.wallets.map(
                  (wallet) =>
                    wallet.token_type == "USDT" && (
                      <div className="wallet-update-details-box">
                        <div className="profile-details-card">
                          <p>Total</p>
                          <h5>
                            {Number(wallet.total).toLocaleString(undefined, {maximumFractionDigits:3})} USDT
                            {/* {wallet.total} USDT */}
                          </h5>
                        </div>
                        {/* <div className="profile-details-card">
                          <p>Onhold</p>
                          <h5>
                            {wallet.onhold}
                          </h5>
                        </div>
                        <div className="profile-details-card">
                          <p>Used</p>
                          <h5>{wallet.used}</h5>
                        </div> */}
                        <div className="profile-details-card">
                          <p>Remaining</p>
                          <h5>
                            {Number(wallet.remaining).toLocaleString(undefined, {maximumFractionDigits:3})} {" "} USDT
                            {/* {wallet.remaining} USDT */}
                          </h5>
                        </div>
                      </div>
                    )
                )
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <WalletTransaction token_type="USDT" />
            </Col>
          </Row>
        </Container>
      </div>
      {sendAmountModal && (
        <SendAmountModal
          closeSendAmountModal={closeSendAmountModal}
          sendAmountModal={sendAmountModal}
        />
      )}
    </>
  );
};

export default UserWalletIndex;
