import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { Error } from "../Common/FormUtils";
import { bankAccountSaveStart, upiSaveStart } from "../../store/slices/WalletSlice";

const AddAccountModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  const bankAccountSave = useSelector((state) => state.wallet.bankAccountSave);
  const profile = useSelector((state) => state.user.profile);

  const validationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required("Required"),
    account_number: Yup.string().required("Required"),
    bank_name: Yup.string().required("Required"),
    ifsc_code: Yup.string().required("Required"),
    account_type: Yup.string().required("Required"),
    payment_type: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(bankAccountSaveStart(props.edit ? {
      ...values,
      trader_bank_account_id: props.addAccount.trader_bank_account_id,
      trader_unique_id: profile.data.reference_unique_id
    } : {
      ...values,
      trader_unique_id: profile.data.reference_unique_id
    }));
  };

  useEffect(() => {
    if (!skipRender && !bankAccountSave.loading && Object.keys(bankAccountSave.data).length > 0) {
      props.closeAddAccountModal();
    }
    setSkipRender(false);
  }, [bankAccountSave]);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.addAccount}
        onHide={props.closeAddAccountModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.edit ? "Edit" : "Add"} Bank Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="send-amount-sec">
            <React.Fragment>
              <Formik
                initialValues={{
                  account_holder_name: props.edit ? props.addAccount.account_holder_name :"",
                  account_number: props.edit ? props.addAccount.account_number :"",
                  bank_name: props.edit? props.addAccount.bank_name :"",
                  ifsc_code: props.edit? props.addAccount.ifsc_code :"",
                  account_type: props.edit ? props.addAccount.account_type :"",
                  payment_type: props.edit ? props.addAccount.payment_type :"",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, touched, values }) => (
                  <FORM className="setting-form" style={{ width: "100%" }}>
                    <div className="user-name-card">
                      <Form.Group className="mb-3">
                        <Form.Label>Account Holder Name</Form.Label>
                        <Field
                          className="form-control"
                          placeholder="Enter Name"
                          name="account_holder_name"
                          type="text"
                        />
                        <Error name="account_holder_name" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Account Number</Form.Label>
                        <Field
                          className="form-control"
                          placeholder="Enter Account Number"
                          name="account_number"
                          type="number"
                        />
                        <Error name="account_number" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Bank Name</Form.Label>
                        <Field
                          className="form-control"
                          placeholder="Enter Account Number"
                          name="bank_name"
                          type="text"
                        />
                        <Error name="bank_name" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>IFSC Code</Form.Label>
                        <Field
                          className="form-control"
                          placeholder="Enter Account Number"
                          name="ifsc_code"
                          type="text"
                        />
                        <Error name="ifsc_code" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Account Type</Form.Label>
                        <Field
                          className="form-control"
                          as="select"
                          name="account_type"
                        >
                          <option value="">Select Account Type</option>
                          <option value="savings">Savings</option>
                          <option value="current">Current</option>
                        </Field>
                        <Error name="account_type" />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>Payment Type</Form.Label>
                        <Field
                          className="form-control"
                          as="select"
                          name="payment_type"
                        >
                          <option value="">Select Payment Type</option>
                          <option value="NEFT">NEFT</option> 
                          <option value="IMPS">IMPS</option> 
                          <option value="RTGS">RTGS</option> 
                        </Field>
                        <Error name="payment_type" />
                      </Form.Group>
                      <div className="btn-sec">
                        <Button
                          type="submit"
                          className="default-btn w-100"
                          disabled={bankAccountSave.buttonDisable}
                        >
                          {bankAccountSave.buttonDisable
                            ? "Loading..."
                            : props.edit ? "Update" : "Save"}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                )}
              </Formik>
            </React.Fragment>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(AddAccountModal);
