import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./LandingPage.css";
import { Link } from "react-router-dom";

const YourExperienceIndex = (props) => {


    return (
        <>
            <div className="your-experience-sec">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={9}>
                            <div className="section-title">
                                <h2>Global Peer-to-Peer Trading Freedom</h2>
                                <p>Trade worldwide, your way: connecting with individuals around the globe while defining your pricing and payment choices.</p>
                            </div>
                        </Col>
                    </Row>
                   <Row>
                    <Col md={12}>
                        <div className="your-experience-box">
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    <Image
                                        className="your-experience-img"
                                        src={
                                            window.location.origin + "/assets/img/landing-page/client-number.png"
                                        }
                                    />
                                    <div className="your-experience-btn-sec">
                                        <Link to="/list" className="default-btn">
                                            GET STARTED
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                   </Row>
                </Container>
            </div>
        </>
    );
};

export default YourExperienceIndex;
