import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import AddUPIModal from "./AddUPIModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { upiListStart, paymentMethodsStart } from "../../store/slices/WalletSlice";
import PageLoader from "../Common/PageLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import CenterLoader from "../Common/CenterLoader";
import NoDataFound from "../Common/NoDataFound";
import DeleteUPIModal from "./DeleteUPIModal";
import { getErrorNotificationMessage } from "../helper/ToastNotification";

const UPIIndex = ({ isInfinite = false, ...props }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addUPI, setAddUPI] = React.useState(false);
  const [editUPI, setEditUPI] = React.useState(false);
  const [deleteUPI, setDeleteUPI] = React.useState(false);
  const [paymentMeathod, setPaymentMeathod] = useState('unavailable')

  const upiList = useSelector((state) => state.wallet.upiList);
  const profile = useSelector((state) => state.user.profile);
  const paymentMethods = useSelector((state) => state.wallet.paymentMethods);

  const closeAddUPIModal = () => {
    setAddUPI(false);
  };

  const closeEditUPIModal = () => {
    setEditUPI(false);
  };

  const closeDeleteUPIModal = () => {
    setDeleteUPI(false);
  };

  const fetchMoreData = () => {};

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(upiListStart({trader_unique_id: profile.data.reference_unique_id}));
      dispatch(
        paymentMethodsStart({
          trader_unique_id: profile.data.reference_unique_id,
        })
      );
    }
  }, [profile]);

  useEffect(() => {
    if (!paymentMethods.loading && Object.keys(paymentMethods.data).length > 0) {
      const pamentMethod = paymentMethods.data.payment_methods.find((method) => method.type == "UPI" && method.status == 1);
      if(pamentMethod)
        setPaymentMeathod('available');
    }
  }, [paymentMethods])

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="update-wallet-header-sec">
                <h3>UPI Accounts</h3>
                <div className="find-offer-btn-sec">
                  <Button
                    onClick={() => paymentMeathod !== "available" ? getErrorNotificationMessage(t("upi_payment_meathod_required")) : setAddUPI(true)}
                    className="default-btn"
                  >
                    Add UPI
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {upiList.loading ? (
                <PageLoader />
              ) : upiList.data.upis && upiList.data.upis.length > 0 ? (
                <div className="active-offers-tab-content-sec">
                  <div className="active-offers-list-table">
                    <div className="list-table-sec">
                      <InfiniteScroll
                        dataLength={upiList.data.upis.length}
                        next={fetchMoreData}
                        // hasMore={
                        //   upiList.data.upis.length < upiList.data.total_upis
                        // }
                        // loader={
                        //   <div className="d-flex justify-content-center mb-5">
                        //     <CenterLoader />
                        //   </div>
                        // }
                      >
                        <Table>
                          <tbody>
                            <tr>
                              <td>S. No.</td>
                              {/* <td>Name</td> */}
                              <td>UPI ID</td>
                              <td>Created At</td>
                              <td>Actions</td>
                            </tr>
                            {upiList.data.upis.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <div className="list-user-details">
                                    <div className="list-user-info">
                                      <p>{i + 1}</p>
                                    </div>
                                  </div>
                                </td>
                                {/* <td>
                                  <div className="list-transfer-speed">
                                    {item.name}
                                  </div>
                                </td> */}
                                <td>
                                  <div className="list-transfer-speed">
                                    {item.upi_id}
                                  </div>
                                </td>
                                <td>
                                  <span>{item.created_at}</span>
                                </td>
                                <td>
                                  <Button variant="ghost" onClick={()=> setEditUPI(item)}>
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                  <Button variant="ghost" onClick={() => setDeleteUPI(item.trader_upi_id)}>
                                    <i className="fas fa-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {addUPI && (
        <AddUPIModal closeAddUPIModal={closeAddUPIModal} addUPI={addUPI} />
      )}
      {editUPI && (  
        <AddUPIModal edit={1} closeAddUPIModal={closeEditUPIModal} addUPI={editUPI} />
      )}
      {deleteUPI && (
        <DeleteUPIModal deleteUPI={deleteUPI} closeDeleteUPIModal={closeDeleteUPIModal}  />
      )}
    </>
  );
};

export default UPIIndex;
