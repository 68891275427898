import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { buyTokenStart, updateBuyData } from "../../../store/slices/OfferSlice";
import { checkPaymentMethodStart, transactionInitiateStart } from "../../../store/slices/WalletSlice";
import { ErrorMsg } from "../../Common/FormUtils";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import { useDropzone } from "react-dropzone";
import CustomLazyLoad from "../../helper/CustomLazyLoad";
import Reload from "../../Common/Reload";
import { useTranslation, withTranslation } from "react-multi-lang";
import { apiUrl } from "../../../Environment";

const TransferPaymentModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const buyData = useSelector((state) => state.offer.buyData);
  const buyToken = useSelector((state) => state.offer.buyToken);
  const tokenOfferView = useSelector((state) => state.offer.tokenOfferView);
  const checkPaymentMethod = useSelector(
    (state) => state.wallet.checkPaymentMethod
  );
  const transactionInitiate = useSelector(
    (state) => state.wallet.transactionInitiate
  );

  const [step, setStep] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const [errorMsg, setErrorMsg] = useState({});

  useEffect(() => {
    if (tokenOfferView.data.token_type == "USDT")
      dispatch(
        checkPaymentMethodStart({
          amount: buyData.spendAmount,
          order_id: buyData.order_id,
          payment_method: buyData.payment_method,
          reference_id: tokenOfferView.data.user.reference_id,
          callback_url: apiUrl + 'offers_buy_status_callback'
        })
      );
  }, []);

  const onComplete = (e) => {
    e.preventDefault();
    let error = {};
    // if (!buyData.payment_id) error = { ...error, payment_id: "Required" }
    if (Object.keys(error).length === 0) {
      const { token_type, ...data } = buyData;
      dispatch(
        buyTokenStart({
          ...data,
          token_type: token_type.token,
          picture: files[0],
          payment_id: buyData.order_id,
          payment_mode: paymentType,
          wallet_address: walletAddress,
          token_offer_unique_id: tokenOfferView.data.token_offer_unique_id,
        })
      );
    } else setErrorMsg(error);
  };

  const onUSDTComplete = (e) => {
    e.preventDefault();
    let  data = buyData;
    
    if(buyData.payment_method == 'UPI')
      data = { ...data, utr_number: walletAddress}
    else
      data = { ...data, payment_id: walletAddress}

    if(files.length > 0)
      data = { ...data, payment_picture: files[0]}

    dispatch(
      transactionInitiateStart({
        ...data,
      })
    );
    
  };

  const onCopy = () => {
    getSuccessNotificationMessage("UPI Copied");
    setStep(2);
  };

  useEffect(() => {
    if (!skipRender && !buyToken.loading) {
      setStep(4);
    }
    // setSkipRender(false);
  }, [buyToken]);

  useEffect(() => {
    if (!skipRender && !transactionInitiate.loading && Object.keys(transactionInitiate.data).length > 0) {
      let { token_type, ...newData } = buyData;

      if(files.length > 0)
        newData = { ...newData, payment_picture: files[0]}

      dispatch(
        buyTokenStart({
          ...newData,
          token_type: token_type.token,
          payment_id: newData.order_id,
          payment_mode: paymentType,
          token_offer_unique_id: tokenOfferView.data.token_offer_unique_id,
        })
      );
      setStep(4);
    }
    setSkipRender(false);
  }, [transactionInitiate]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files.map((file) => (
    <div className="preview-added-image">
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => setFiles([])}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <Image
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const [walletAddress, setWalletAddress] = useState("");
  const [paymentType, setPaymentType] = useState(
    tokenOfferView.data.user_upi_id ? "UPI" : "BANK_TRANSFER"
  );

  return (
    <>
      <Modal
        className="modal-dialog-center transfer-payment-modal"
        size="md"
        centered
        show={true}
      >
        <Modal.Header closeButton onHide={props.closeTransferPaymentModal}>
          <Modal.Title>{t("transfer_payment")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {tokenOfferView.data.token_type == "USDT" ? (
            checkPaymentMethod.loading ? (
              "Loading"
            ) : Object.keys(checkPaymentMethod.data).length > 0 ? (
              <>
                {step == 1 && (
                  <div className="transfer-payment-box">
                    <div className="step-1">
                      <div className="transfer-payment-info">
                        <h4>{t("step")}-1</h4>
                        <p>
                          {t("transfer_amount")} :{" "}
                          <span>{buyData.spendAmount} ₹</span>
                        </p>
                      </div>
                      <div className="pricing-sell-sec">
                        <div className="pricing-sell-box text-center">
                          {checkPaymentMethod.data.payment_method == "UPI" ? (
                            <>
                            <img
                              src={
                                checkPaymentMethod.data.payment_details.qr_code
                              }
                            />
                            <div className="card-content-wrapper">
                                <span className="check-icon"></span>
                                <div className="card-content">
                                  <h5 className="mt-3">{t("upi_payment")}</h5>
                                  <p>{checkPaymentMethod.data.payment_details.upi_id}</p>
                                </div>
                              </div>
                              </>
                          ) : (
                            <div className="card-content-wrapper">
                              <span className="check-icon"></span>
                              <div className="card-content">
                                <h5>{t("bank_account")}</h5>
                                <p>
                                  {t("account_holder_name")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .account_holder_name
                                  }
                                </p>
                                <p>
                                  {t("account_number")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .account_number
                                  }
                                </p>
                                <p>
                                  {t("bank_name")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .bank_name
                                  }
                                </p>
                                <p>
                                  {t("bank_type")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .bank_type
                                  }
                                </p>
                                <p>
                                  {t("ifsc_code")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .ifsc_code
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <Form.Group className="mb-3">
                          <Form.Label>{checkPaymentMethod.data.payment_method == "UPI" ? "UTR ID" : "Payment ID"}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            placeholder={`Enter your ${checkPaymentMethod.data.payment_method == "UPI" ? "UTR ID" : "Payment ID"}`}
                            name="wallet_address"
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                          />
                        </Form.Group>
                        <Button
                          disabled={walletAddress == "" || paymentType == ""}
                          className="default-btn"
                          onClick={() => setStep(2)}
                        >
                          {t("next")}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {step == 2 && (
                  <div>
                    <Button onClick={() => setStep(1)}>Back</Button>
                    <Form onSubmit={onUSDTComplete}>
                      <div className="transfer-payment-box">
                        <div className="step-2">
                          <div className="transfer-payment-info">
                            <h4>{t("step")}-2</h4>
                          </div>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {files.length <= 0 && (
                              <div className="add-product-upload-box">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/settings/camera-icon.svg"
                                  }
                                  className="upload-icon"
                                />
                                <p>
                                  {t("upload_your_payment_screenshot_here")}
                                  <br /> {t("accept_only_jpeg_jpg_png")}
                                </p>
                              </div>
                            )}
                            <div>{images}</div>
                          </div>
                        </div>
                      </div>
                      <div className="buy-btn-sec">
                        <Button
                          className="default-btn w-100"
                          type="submit"
                          disabled={transactionInitiate.buttonDisable}
                        >
                          {transactionInitiate.buttonDisable ? "Loading" : "PROCEED"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
                {step == 4 && (
                <div className="step-3">
                  <div className="payment-waiting-img-sec">
                    <Image
                      className="payment-waiting-img"
                      src={window.location.origin +
                        "/assets/img/buy/payment-success.gif"}
                    />
                  </div>
                  {Object.keys(transactionInitiate.data).length > 0 ? (
                    <div className="transfer-payment-box waiting-payment-box">
                      <h4>{t("transaction_successful")}</h4>
                      <p>{t("wow_it_just_took_less_than_min")}</p>
                      <div className="buy-btn-sec mt-3">
                        <Button
                          className="default-btn w-100"
                          onClick={() => navigate("/list")}
                        >
                          {t("ok")}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="transfer-payment-box waiting-payment-box">
                      <h4>{t("transaction_failed")}</h4>
                      <div className="buy-btn-sec mt-3">
                        <Button
                          className="default-btn w-100"
                          onClick={() => navigate("/list")}
                        >
                          {t("ok")}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={window.location.origin + "/assets/img/error.png"}
                  alt="no-data"
                  className="mb-3"
                />
                <p>Sorry, Payment Details Not Found</p>
              </div>
            )
          ) : (
            <>
              {step == 1 && (
                <div className="transfer-payment-box">
                  <div className="step-1">
                    <div className="transfer-payment-info">
                      <h4>{t("step")}-1</h4>
                      <p>
                        {t("transfer_amount")} :{" "}
                        <span>{buyData.spendAmount} ₹</span>
                      </p>
                    </div>
                    <div className="pricing-sell-sec">
                      <div className="pricing-sell-box">
                        <Form.Label>{t("select_payment_type")}</Form.Label>
                        <div className="transfer-type-card">
                          {tokenOfferView.data.user_upi_id && (
                            <label
                              for="radio-card-1"
                              className="radio-card"
                              onClick={() => setPaymentType("UPI")}
                            >
                              <input
                                type="radio"
                                name="radio-card"
                                id="radio-card-1"
                                defaultChecked={true}
                              />
                              <div className="card-content-wrapper">
                                <span className="check-icon"></span>
                                <div className="card-content">
                                  <h5>{t("upi_payment")}</h5>
                                  <p>{tokenOfferView.data.user_upi_id}</p>
                                </div>
                              </div>
                            </label>
                          )}
                          {Object.keys(tokenOfferView.data.user_billing)
                            .length > 0 && (
                            <label
                              for="radio-card-2"
                              className="radio-card"
                              onClick={() => setPaymentType("BANK_TRANSFER")}
                            >
                              <input
                                type="radio"
                                name="radio-card"
                                id="radio-card-2"
                                defaultChecked={
                                  !tokenOfferView.data.user_upi_id
                                }
                                // checked={offerData.is_market_rate === 0}
                              />
                              <div className="card-content-wrapper">
                                <span className="check-icon"></span>
                                <div className="card-content">
                                  <h5>{t("bank_account")}</h5>
                                  <p>
                                    {t("account_holder_name")} :{" "}
                                    {
                                      tokenOfferView.data.user_billing
                                        .account_holder_name
                                    }
                                  </p>
                                  <p>
                                    {t("account_number")} :{" "}
                                    {
                                      tokenOfferView.data.user_billing
                                        .account_number
                                    }
                                  </p>
                                  <p>
                                    {t("bank_name")} :{" "}
                                    {tokenOfferView.data.user_billing.bank_name}
                                  </p>
                                  <p>
                                    {t("bank_type")} :{" "}
                                    {tokenOfferView.data.user_billing.bank_type}
                                  </p>
                                  <p>
                                    {t("ifsc_code")} :{" "}
                                    {tokenOfferView.data.user_billing.ifsc_code}
                                  </p>
                                </div>
                              </div>
                            </label>
                          )}
                        </div>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("wallet_address")}</Form.Label>
                        <Form.Control
                          className="form-control"
                          type="text"
                          placeholder={t("enter_your_wallet_address")}
                          name="wallet_address"
                          value={walletAddress}
                          onChange={(e) => setWalletAddress(e.target.value)}
                        />
                      </Form.Group>
                      <Button
                        disabled={walletAddress == "" || paymentType == ""}
                        className="default-btn"
                        onClick={() => setStep(2)}
                      >
                        {t("next")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {step == 2 && (
                <div>
                  <Button onClick={() => setStep(1)}>Back</Button>
                  <Form onSubmit={onComplete}>
                    <div className="transfer-payment-box">
                      <div className="step-2">
                        <div className="transfer-payment-info">
                          <h4>{t("step")}-2</h4>
                        </div>
                        {/* <div className="flex-label">
                      <Form.Label>Enter UTR Number</Form.Label>
                      <Form.Label className="text-blue"><Link to="#">What is UTR ?</Link></Form.Label>
                    </div>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="265566633333333"
                        value={buyData.payment_id}
                        onChange={e => dispatch(updateBuyData({ ...buyData, payment_id: e.target.value }))}
                      />
                    </Form.Group>
                    {errorMsg.payment_id ?
                      <ErrorMsg msg={errorMsg.payment_id} />
                      : null} */}
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {files.length <= 0 && (
                            <div className="add-product-upload-box">
                              <Image
                                src={
                                  window.location.origin +
                                  "/assets/img/settings/camera-icon.svg"
                                }
                                className="upload-icon"
                              />
                              <p>
                                {t("upload_your_payment_screenshot_here")}
                                <br /> {t("accept_only_jpeg_jpg_png")}
                              </p>
                            </div>
                          )}
                          <div>{images}</div>
                          {/* <div className="user-profile-card">
                        <div className="user-camera-icon-sec">
                          <Image
                            className="user-camera-icon"
                            src={
                              window.location.origin +
                              "/assets/img/settings/camera-icon.svg"
                            }
                          />
                        </div>
                      </div> */}
                        </div>

                        {/* <Form.Group className="mb-0">
                      <div className="error-sec">
                        <Image
                          className="coversion-icon"
                          src={
                            window.location.origin + "/assets/img/auth/info-icon.svg"
                          }
                        />
                        Your UTR Number is incorrect
                      </div>
                    </Form.Group> */}
                      </div>
                    </div>
                    {/* <div className="transfer-payment-timer-sec">
                    <h5>Make Payments In <span>04:50 Mins</span></h5>
                  </div> */}
                    <div className="buy-btn-sec">
                      <Button
                        className="default-btn w-100"
                        type="submit"
                        disabled={buyToken.buttonDisable || files.length <= 0}
                      >
                        {buyToken.buttonDisable ? "Loading" : "PROCEED"}
                      </Button>
                    </div>
                  </Form>
                </div>
              )}

              {step == 3 && (
                <div className="step-3">
                  <div className="payment-waiting-img-sec">
                    <Image
                      className="payment-waiting-img"
                      src={
                        window.location.origin +
                        "/assets/img/buy/payment-waiting.gif"
                      }
                    />
                  </div>
                  <div className="transfer-payment-box waiting-payment-box">
                    <h4>{t("waiting_for_payment_confimation")}</h4>
                    <p>{t("yay_now_just_sit_back_relax")}</p>
                    <div className="transfer-payment-card">
                      <div className="transfer-payment-item">
                        <h5>{t("utr")}</h5>
                        <p>228648259391</p>
                      </div>
                      <div className="transfer-payment-item">
                        <h5>{t("amount")}</h5>
                        <p>1000 ₹</p>
                      </div>
                      <div className="transfer-payment-item">
                        <h5>{t("coin_details")}</h5>
                        <p>
                          <Image
                            className="coversion-icon"
                            src={
                              window.location.origin +
                              "/assets/img/buy/bnb-icon.svg"
                            }
                          />
                          <span>{t("etherium")}</span>
                        </p>
                      </div>
                    </div>
                    <div className="transfer-footer-note">
                      {t("in_case")} <span>{t("utr")}</span>{" "}
                      {t("has_been_submitted_incorrectly")}{" "}
                      <Link to="#">{t("click_here")}</Link>{" "}
                      {t("and_create_para")}
                    </div>
                  </div>
                </div>
              )}
              {step == 4 && (
                <div className="step-3">
                  <div className="payment-waiting-img-sec">
                    <Image
                      className="payment-waiting-img"
                      src={
                        Object.keys(buyToken.data).length > 0
                          ? window.location.origin +
                            "/assets/img/buy/payment-success.gif"
                          : window.location.origin +
                            "/assets/img/buy/payment-failure.gif"
                      }
                    />
                  </div>
                  {Object.keys(buyToken.data).length > 0 ? (
                    <div className="transfer-payment-box waiting-payment-box">
                      <h4>{t("transaction_successful")}</h4>
                      <p>{t("wow_it_just_took_less_than_min")}</p>
                      {/* <div className="transfer-footer-note">
                    <Link to="#">Click Here</Link> For Transaction Details
                  </div> */}
                      <div className="buy-btn-sec mt-3">
                        <Button
                          className="default-btn w-100"
                          onClick={() => navigate("/list")}
                        >
                          {t("ok")}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="transfer-payment-box waiting-payment-box">
                      <h4>{t("transaction_failed")}</h4>
                      <div className="buy-btn-sec mt-3">
                        <Button
                          className="default-btn w-100"
                          onClick={() => navigate("/list")}
                        >
                          {t("ok")}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(TransferPaymentModal);
