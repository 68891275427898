import React, { useState, memo } from 'react';
import Countdown from 'react-countdown';


const CounterTimer = (props) => {
  const [time, setTime] = useState(props.time);
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      props.onComplete();
    } else {
      // Render a countdown
      return props.render({ hours, minutes, seconds })
      // return <span>{(minutes < 10 ? "0" : "") + minutes}:{(seconds < 10 ? "0" : "") + seconds} seconds</span>;
    }
  };
  return (
    <Countdown
      date={Date.now() + time}
      renderer={renderer}
    />
  )
}

const isEqual = (prevProps, nextProps) => {
  return prevProps.time === nextProps.time;
}


export default memo(CounterTimer, isEqual);