import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
  Dropdown,
  Table,
} from "react-bootstrap";
import "./Profile.css";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import PageLoader from "../../Common/PageLoader";
import SomethingWentWrong from "../../Common/SomethingWentWrong";
import {
  fetchMoreTokenOffersListStart,
  fetchTokenOffersListStart,
  tokenOfferDeleteStart,
} from "../../../store/slices/OfferSlice";
import NoDataFound from "../../Common/NoDataFound";
import CenterLoader from "../../Common/CenterLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomSelect from "../../Common/CustomSelect";
import configuration from "react-global-configuration";
import DeleteVerificationModal from "./DeleteVerificationModal";

const AllOffersIndex = (props, { isInfinite = true }) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tokenOfferDelete = useSelector(state => state.offer.tokenOfferDelete);

  const [tokenTypes, setTokenTypes] = useState([
    {
      api_id: "",
      label: "All",
      token: "",
      token_icon: window.location.origin + "/assets/img/create-offers/all-tokens.png",
      value: ""
    },
    ...configuration.get("configData.project_tokens_formatted")
  ])

  const [filter, setFilter] = useState({
    order_by: "newest",
    token_type: "",
  });
  const [verification, setVerification] = useState(false);

  const closeVerificationModal = () => {
    setVerification(false);
  }

  const fetchMoreOffers = () => {
    props.dispatch(
      fetchMoreTokenOffersListStart({
        ...filter,
        skip: props.tokenOffersList.data.token_offers.length,
        take: 12,
      })
    );
  };

  useEffect(() => {
    dispatch(fetchTokenOffersListStart({
      ...filter,
      skip: 0,
      take: 12
    }));
  }, [filter]);

  return (
    <>
      {props.profile.loading ? (
        <PageLoader />
      ) : (Object.keys(props.profile.data).length > 0 && props.profile.data.user_id) ? (
        <div className="profile-sec">
          <Container>
            <Row>
              <Col md={12}>
                {props.tokenOffersList.loading ? (
                  <PageLoader />
                ) :
                  Object.keys(props.tokenOffersList.data).length > 0 &&
                    props.tokenOffersList.data.token_offers.length > 0 ? (
                    <div className="active-offers-sec">
                      <div className="active-offers-header-sec choose-your-cryptocurrency-box">
                        <div className="active-offers-left-sec">
                          <h3>{t("my_offers")}</h3>
                          {isInfinite ? (
                            <div className="select-coin">
                              <CustomSelect
                                icon="token_icon"
                                options={tokenTypes}
                                value={tokenTypes.find(token => token.token === filter.token_type)}
                                onChange={val => setFilter({ ...filter, token_type: val.token })}
                              />
                            </div>
                          ) : null}
                        </div>
                        {isInfinite ? (
                          <div className="active-offers-right-sec">
                            <Dropdown>
                              <Dropdown.Toggle variant="success" id="dropdown-basic" className="list-action-btn">
                                <Image
                                  className="coversion-icon"
                                  src={
                                    window.location.origin + "/assets/img/list/sort-icon.svg"
                                  }
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setFilter({ ...filter, order_by: "price_lh" })}>{t("price_lowest_to_highest")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter({ ...filter, order_by: "price_hl" })}>{t("price_highest_to_lowest")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter({ ...filter, order_by: "newest" })}>{t("date")}  : {t("newest")}</Dropdown.Item>
                                <Dropdown.Item onClick={() => setFilter({ ...filter, order_by: "oldest" })}>{t("date")} : {t("oldest")}</Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        ) : null}
                      </div>

                      <div className="active-offers-tab-content-sec">
                        <div className="active-offers-draft"></div>
                        <div className="active-offers-list-table">
                          <div className="list-table-sec">
                            <InfiniteScroll
                              dataLength={
                                props.tokenOffersList.data.token_offers.length
                              }
                              next={fetchMoreOffers}
                              hasMore={
                                props.tokenOffersList.data.token_offers.length <
                                props.tokenOffersList.data.total_token_offers &&
                                isInfinite
                              }
                              loader={
                                <div className="d-flex justify-content-center mb-5">
                                  <CenterLoader />
                                </div>
                              }
                            >
                              <Table>
                                <tbody>
                                  {props.tokenOffersList.data.token_offers.map(
                                    (activeOffer, i) => (
                                      <tr key={i}>
                                        <td>
                                          <div className="list-user-details">
                                            <div className="list-user-img-sec">
                                              {/* <p>P</p> */}
                                              <Image
                                                src={activeOffer.user.picture}
                                              />
                                              <div className="status-online"></div>
                                            </div>
                                            <div className="list-user-info">
                                              <h4>{activeOffer.user.name}</h4>
                                              {activeOffer.user.last_seen_formatted ?
                                                <p>{activeOffer.user.last_seen_formatted}</p> : null}
                                              <div className="list-liked-user">
                                                <Image
                                                  className="coversion-icon"
                                                  src={
                                                    window.location.origin +
                                                    "/assets/img/list/like-icon.svg"
                                                  }
                                                />
                                                <span>{activeOffer.user.total_positive_feedback}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="list-price-details">
                                            <div className="price-amount">
                                              {activeOffer.total_tokens}
                                              {/* 20.24k <span>USD</span> */}
                                            </div>
                                            <div className="price-convertion-amount">
                                              <p>
                                                1 {t("usd")} ={" "}
                                                {activeOffer.single_token_rate}
                                              </p>
                                              {/* <p>1 USD = 1.03 USD of BTC</p> */}
                                            </div>
                                            <div className="price-convertion-percentage">
                                              <Image
                                                className="price-convertion-percentage-icon"
                                                src={
                                                  window.location.origin +
                                                  "/assets/img/list/arrow-down-icon.svg"
                                                }
                                              />
                                              <span>2%</span>
                                              <Image
                                                className="price-convertion-percentage-icon"
                                                src={
                                                  window.location.origin +
                                                  "/assets/img/list/info-icon.svg"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="limit-transaction-amount">
                                            <h5>
                                              {t("available")}{" "}
                                              <span>
                                                {activeOffer.remaining_tokens}
                                              </span>
                                            </h5>
                                            <h5>
                                              {t("limit")}{" "}
                                              <span>{activeOffer.limit}</span>
                                            </h5>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="list-transfer-speed">
                                            {activeOffer.created_at}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="payment-type-sec">
                                            {activeOffer.payment_mode}
                                          </div>
                                        </td>
                                        <td>
                                          <div className={`offer-status-sec-${activeOffer.status.toLowerCase()}`}>
                                            {activeOffer.status}
                                          </div>
                                        </td>
                                        <td>
                                          { activeOffer.token_type != "USDT" && activeOffer.status == "UpComing" ?
                                            <div className="list-action-btn-sec">
                                              <Button className="buy-btn" onClick={() => navigate(`/sell/${activeOffer.token_offer_unique_id}`)}>
                                               {t("edit")}
                                              </Button>
                                              <Button className="delete-btn" onClick={() => setVerification(activeOffer.token_offer_unique_id)}
                                                disabled={tokenOfferDelete.buttonDisable && activeOffer.token_offer_unique_id == tokenOfferDelete.data.token_offer_unique_id}
                                              >
                                                {(tokenOfferDelete.buttonDisable && activeOffer.token_offer_unique_id == tokenOfferDelete.data.token_offer_unique_id) ? "Loading" : "Delete"}
                                              </Button>
                                            </div>
                                            : null}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </InfiniteScroll>
                            {!isInfinite &&
                              props.tokenOffersList.data.token_offers.length <
                              props.tokenOffersList.data.total_token_offers ? (
                              <div className="d-flex justify-content-center list-action-btn-sec mb-3">
                                <Button
                                  className="buy-btn"
                                  onClick={() =>
                                    navigate(`/active-offer/${props.username}`)
                                  }
                                >
                                {t("load_more")}
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NoDataFound />
                  )}
              </Col>
            </Row>
          </Container>
          {verification ?
            <DeleteVerificationModal
              verification={verification}
              closeModal={closeVerificationModal}
            />
            : null
          }
        </div>
      ) : (
        <SomethingWentWrong
          showReload={true}
          reloadText={t("go_to_home")}
          onReload={() => navigate("/")}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  tokenOffersList: state.offer.tokenOffersList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(AllOffersIndex));
