import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import ChangeNumberForm from "./ChangeNumberFrom";
import ChangeEmailForm from "./ChangeEmailForm";
import ChangeNumberVerificationCode from "./ChangeNumberVerificationCode";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { updateProfileCredentialsStart, validateUpdateCredentialsStart } from "../../store/slices/UserSlice";

const ChangeNumberModal = (props) => {
  const t = useTranslation();

  const [step, setStep] = useState("1");
  const [skipRender, setSkipRender] = useState(true);
  const [data, setData] = useState();
  const [resendEnable, setResendEnable] = useState(false);

  const handleChangeCredentials = (values) => {
    setResendEnable(false);
    setData(values);
    props.dispatch(updateProfileCredentialsStart(values));
  }

  useEffect(() => {
    if (!skipRender && !props.updateProfileCredentials.loading) {
      if (Object.keys(props.updateProfileCredentials.data).length > 0) {
        setResendEnable(false);
        setStep("2");
      } else {
        setResendEnable(true);
      }
    }

  }, [props.updateProfileCredentials]);

  const handleConfirmChangeCredentials = (values) => {
    props.dispatch(validateUpdateCredentialsStart({
      ...values,
      ...data,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.validateUpdateCredentials.loading && Object.keys(props.validateUpdateCredentials.data).length > 0) {
      props.closeChangeNumberModal();
    }
    setSkipRender(false);
  }, [props.validateUpdateCredentials])

  return (
    <>
      <Modal
        className="modal-dialog-center change-number-modal"
        size="md"
        centered
        show={true}
      >
        <Modal.Header closeButton onHide={props.closeChangeNumberModal}>
          <Modal.Title>Change {props.type}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="setting-form">
            <div className="change-number-box">
              {step === "1" ?
                props.type === "Number" ?
                  <ChangeNumberForm
                    handleChangeCredentials={handleChangeCredentials} />
                  : <ChangeEmailForm
                    handleChangeCredentials={handleChangeCredentials}
                  />
                : null
              }
              {step === "2" && (
                <div>
                  <ChangeNumberVerificationCode
                    resendEnable={resendEnable}
                    setResendEnable={setResendEnable}
                    handleConfirmChangeCredentials={handleConfirmChangeCredentials}
                    handleSendOTP={() => handleChangeCredentials(data)}
                    setStep={setStep}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  updateProfileCredentials: state.user.updateProfileCredentials,
  validateUpdateCredentials: state.user.validateUpdateCredentials
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ChangeNumberModal));