import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./CreateOffer.css";
import { Link } from "react-router-dom";
import ConfirmPaymentModal from "./ConfirmPaymentModal";
import { useTranslation } from "react-multi-lang";

const TradeInstructions = ({ offerData, setOfferData, setStep, create }) => {
  const t = useTranslation();

  const [confirmPayment, setConfirmPayment] = useState(false);

  const closeConfirmPaymentModal = () => {
    setConfirmPayment(false);
  }

  return (
    <>
      <div className="sell-about-this-step-box">
        <h4>{t("make_offer.about_step")}</h4>
        <p>{t("make_offer.about_step_content")}</p>
      </div>
      <div className="trade-instruction-sec">
        <div className="trade-instruction-box">
          <h4>{t("trade_instructions")}</h4>
          <Row>
            <Col md={12}>
              <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-3">
                <Form.Label>{t("your_offer_label")}</Form.Label>
                <Form.Control
                  placeholder={t("maximum_characters_placeholder")}
                  className="your-label-form-control"
                  value={offerData.offer_lable}
                  onChange={e => setOfferData({
                    ...offerData, offer_lable: e.target.value
                  })}
                />
                <Form.Text className="text-muted">
                  {t("make_your_offer_stand_para")}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-3">
                <Form.Label>{t("offers_terms")}</Form.Label>
                <Form.Control
                  as="textarea" r
                  ows={5}
                  placeholder={t("write_your_terms_here")}
                  value={offerData.offer_terms}
                  onChange={e => setOfferData({
                    ...offerData, offer_terms: e.target.value
                  })}
                />
                <Form.Text className="text-muted">
                  {t("Anybody who views_para")}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1" className="mb-0">
                <Form.Label>{t("trade_instructions")}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder={t("write_your_terms_here")}
                  value={offerData.trade_instructions}
                  onChange={e => setOfferData({
                    ...offerData, trade_instructions: e.target.value
                  })}
                />
                <Form.Text className="text-muted">
                  {t("to_ensure_a_successful_para")}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div className="sell-footer-box">
          <Button className="previous-step-btn" onClick={() => setStep(step => step - 1)}>Previous Step</Button>
          <Button className="next-step-btn" onClick={() => setConfirmPayment(true)}>{create ? "Create Sell Order" : "Update Sell Order"}</Button>
        </div>
      </div>
      {confirmPayment ?
        <ConfirmPaymentModal
          create={create}
          offerData={offerData}
          closeConfirmPaymentModal={closeConfirmPaymentModal}
        />
        : null
      }
    </>
  );
};

export default TradeInstructions;
