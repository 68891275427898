import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button, FormGroup, FormControl } from "react-bootstrap";
import "./CreateOffer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { amountConversionStart } from "../../store/slices/OfferSlice";
import { ErrorMsg } from "../Common/FormUtils";
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

const PricingSellIndex = ({ offerData, setOfferData, setStep }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const amountConversion = useSelector(state => state.offer.amountConversion);
  const profile = useSelector((state) => state.user.profile);

  const [skipRender, setSkipRender] = useState(true);
  const [errorMsg, setErrorMsg] = useState({});

  useEffect(() => {
    if (offerData.is_market_rate === 1)
      dispatch(amountConversionStart({
        convertFrom: offerData.token_type.api_id.toLowerCase(),
        convertTo: offerData.token_rate_type.toLowerCase(),
        isNetwork: true,
      }));
  }, [offerData.is_market_rate]);

  useEffect(() => {
    if(offerData.token_type.token != "USDT") {
      if (!skipRender && !amountConversion.loading && Object.keys(amountConversion.data).length > 0) {
        setOfferData({
          ...offerData, single_token_rate: amountConversion.data.amount,
        })
      }
    } else {
      setOfferData({
        ...offerData, single_token_rate: profile.data.exchange_value,
      })
    }
    setSkipRender(false);
  }, [amountConversion]);

  const onStepComplete = e => {
    e.preventDefault();
    let error = {};
    if (!offerData.min_tokens) error = { ...error, min_tokens: "Required" };
    else if (offerData.min_tokens <= 0) error = { ...error, min_tokens: "Min tokens should not be 0" };
    else if (offerData.token_type.token != "USDT" && Number(offerData.min_tokens) > (Number(offerData.total_tokens) * Number(offerData.single_token_rate))) error = { ...error, min_tokens: "Min tokens should not excced total" };
    else if (offerData.token_type.token == "USDT" && Number(offerData.min_tokens) < 1000) error = { ...error, min_tokens: "Min tokens should not be less that 1000" };

    if (!offerData.max_tokens) error = { ...error, max_tokens: "Required" };
    else if (Number(offerData.max_tokens) < Number(offerData.min_tokens)) error = { ...error, max_tokens: "Max amount should not be less than min" };
    else if (Number(offerData.max_tokens) > (Number(offerData.total_tokens) * Number(offerData.single_token_rate))) error = { ...error, max_tokens: "Max amount should not exceed total" };

    if (!offerData.single_token_rate) error = { ...error, single_token_rate: "Required" };

    if (!offerData.offer_start_at) error = { ...error, offer_start_at: "Required" };
    if(offerData.token_type.token != "USDT") {
      if (!offerData.offer_time_limit) error = { ...error, offer_time_limit: "Required" };
      else if (offerData.offer_time_limit.includes(".")) error = { ...error, offer_time_limit: "Invalid" };
      else if (offerData.offer_time_limit < 5) error = { ...error, offer_time_limit: "Min 5 minites" }
    }

    if (Object.keys(error).length === 0) setStep(step => step + 1)
    else setErrorMsg(error);
  }

  function percentage(partialValue, totalValue) {
    return ((100 * partialValue) / totalValue - 100).toFixed(3).toString().replace("-", "");
  }

  function amount(partialValue, totalValue) {
    return (totalValue - partialValue).toString().replace("-", "");
  }

  return (
    <>
      <div className="sell-about-this-step-box">
        <h4>{t("make_offer.about_step")}</h4>
        <p>{t("make_offer.about_step_content")}</p>
      </div>
      <div className="pricing-sell-sec">
      {offerData.token_type.token != "USDT" ? (
        <div className="pricing-sell-box">
          <h4>{t("trade_pricing")}</h4>
          <Form.Label>{t("choose_bitcoin_rate_you_want_to_use")}</Form.Label>
          <div className="pricing-sell-card">
            <label for="radio-card-1" className="radio-card"
              onClick={() => setOfferData({
                ...offerData, is_market_rate: 1,
              })}>
              <input type="radio" name="radio-card" id="radio-card-1" checked={offerData.is_market_rate === 1} />
              <div className="card-content-wrapper">
                <span className="check-icon"></span>
                <div className="card-content">
                  <h5>{t("marketplace")}</h5>
                  <p>{t("your_offers_para")}</p>
                </div>
              </div>
            </label>

            <label for="radio-card-2" className="radio-card"
              onClick={() => setOfferData({
                ...offerData, is_market_rate: 0
              })}>
              <input type="radio" name="radio-card" id="radio-card-2" checked={offerData.is_market_rate === 0} />
              <div className="card-content-wrapper">
                <span className="check-icon"></span>
                <div className="card-content">
                  <h5>{t("fixed_price")}</h5>
                  <p>{t("your_offers_para")}</p>
                </div>
              </div>
            </label>
          </div>
        </div>
      ) : null}
        <div className="pricing-sell-box">
          <h4 className="mb-4">{t("offer_trade_limits")}</h4>
          <Row>
            <Col md={6} className="resp-marg-btm-xs">
              <Form.Label>{t("minimum")}</Form.Label>
              <InputGroup className="mb-0">
                <Form.Control
                  placeholder={t("enter_amount")}
                  value={offerData.min_tokens}
                  onChange={e => {
                    const val = e.target.value;
                    if (Number.isNaN(Number(val)))
                      e.preventDefault();
                    else
                      setOfferData({
                        ...offerData,
                        min_tokens: val,
                      })
                  }}
                />
                <InputGroup.Text id="basic-addon1">
                  {t("inr")}
                </InputGroup.Text>
              </InputGroup>
              {errorMsg.min_tokens ? <ErrorMsg msg={errorMsg.min_tokens} /> : null}
            </Col>
            <Col md={6}>
              <Form.Label>{t("maximum")}</Form.Label>
              <InputGroup className="mb-0">
                <Form.Control
                  placeholder={t("enter_amount")}
                  value={offerData.max_tokens}
                  onChange={e => {
                    const val = e.target.value;
                    if (Number.isNaN(Number(val)))
                      e.preventDefault();
                    else
                      setOfferData({
                        ...offerData,
                        max_tokens: val,
                      })
                  }}
                />
                <InputGroup.Text id="basic-addon1">
                  {t("inr")}
                </InputGroup.Text>
              </InputGroup>
              {errorMsg.max_tokens ? <ErrorMsg msg={errorMsg.max_tokens} /> : null}
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <div className="pricing-sell-success-text">
                <Image
                  className="pricing-sell-info-icon"
                  src={
                    window.location.origin + "/assets/img/create-offers/info-icon.svg"
                  }
                />
                <span>If the minimum you set in your currency drops below 3.00 USD in value, we’ll prompt the buyers to pick an amount worth at least 3.00 USD to proceed with the trade.</span>
              </div>
              <div className="pricing-sell-error-text">
                <Image
                  className="pricing-sell-info-icon"
                  src={
                    window.location.origin + "/assets/img/create-offers/info-icon.svg"
                  }
                />
                <span>If the minimum you set in your currency drops below 3.00 USD in value, we’ll prompt the buyers to pick an amount worth at least 3.00 USD to proceed with the trade.</span>
              </div>
            </Col>
          </Row> */}
        </div>
        <div className="pricing-sell-box">
          <h4>{t("fixed_price_market_rate_your_offer_will_list_at")}</h4>
          <Row className="mt-4">
            <Col md={6}>
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder={t("enter_amount")}
                  disabled={offerData.is_market_rate === 1}
                  value={offerData.token_type.token != "USDT" ? offerData.single_token_rate : profile.data.exchange_value}
                  onChange={e => {
                    const val = e.target.value;
                    if (Number.isNaN(Number(val)))
                      e.preventDefault();
                    else
                      setOfferData({
                        ...offerData,
                        single_token_rate: val,
                      })
                  }}
                />
                <InputGroup.Text id="basic-addon1">
                  {offerData.token_rate_type}
                </InputGroup.Text>
              </InputGroup>
              {errorMsg.single_token_rate ? <ErrorMsg msg={errorMsg.single_token_rate} /> : null}
            </Col>
            {/* <Col md={2}>
              <div className="pricing-inc-dec-btn-sec">
                <Button className="minus-btn">
                  -
                </Button>
                <Button className="plus-btn">
                  +
                </Button>
              </div>
            </Col> */}
          </Row>
          {amountConversion.loading ?
            "loading"
            : amountConversion.data.amount ?
              <Row>
                <Col md={12}>
                  <div className="fixed-price-market-info">
                    <p>{t("current_market_price_for")} 1 {offerData.token_type.token} : <strong>{offerData.token_type.token != "USDT" ? amountConversion.data.amount : profile.data.exchange_value} {offerData.token_rate_type}</strong></p>
                    {offerData.single_token_rate != amountConversion.data.amount ?
                      <>
                        <p>{t("your_price_is")} <strong>{percentage(offerData.single_token_rate, amountConversion.data.amount)}% {offerData.single_token_rate > amountConversion.data.amount ? "above" : "below"}</strong> the market price</p>
                        <p>{t("you_will_get")}: <strong>{amount(offerData.single_token_rate, amountConversion.data.amount)} {offerData.token_rate_type}</strong> {offerData.single_token_rate > amountConversion.data.amount ? "above" : "below"} the market price for every {offerData.token_type.token} you sell</p>
                        {/* <p>So for every <span>248.00 {offerData.token_rate_type}</span> worth of {offerData.token_type.token} you sell (your minimum trade limit), you will receive an
                          <span>186.00 {offerData.token_rate_type} in return.</span></p> */}
                      </>
                      : null
                    }
                  </div>
                </Col>
              </Row>
              : null
          }
        </div>
        <div className="pricing-sell-box">
          <h4>{t("offer_time")}</h4>
          <Row className="mt-4">
            <Col md={6}>
              <FormGroup>
                <Form.Label>{t("make_offer.pricing.starting_time")}</Form.Label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    type="date"
                    label={t("make_offer.pricing.starting_time")}
                    inputFormat="DD/MM/YYYY hh:mm"
                    value={dayjs(offerData.offer_start_at)}
                    minDateTime={dayjs().add(10, 'minute')}
                    onChange={val => setOfferData({
                      ...offerData,
                      offer_start_at: new Date(val)
                    })}
                    renderInput={(params) => <FormControl  {...params} value={params.inputProps.value} />}
                  />
                </LocalizationProvider>
                {errorMsg.offer_start_at ? <ErrorMsg msg={errorMsg.offer_start_at} /> : null}
              </FormGroup>
            </Col>
            {offerData.token_type.token != "USDT" ? (
            <Col md={6}>
              <FormGroup>
                <Form.Label>{t("make_offer.pricing.time_limit")}</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder={t("enter_time_in_mins")}
                    value={offerData.offer_time_limit}
                    onChange={e => {
                      const val = e.target.value;
                      if (parseInt(val) == val || val == "")
                        setOfferData({
                          ...offerData,
                          offer_time_limit: val,
                        })
                      else
                        e.preventDefault();
                    }}
                  />
                  <InputGroup.Text id="basic-addon1">
                    {t("min")}
                  </InputGroup.Text>
                </InputGroup>
                {errorMsg.offer_time_limit ? <ErrorMsg msg={errorMsg.offer_time_limit} /> : null}
              </FormGroup>
            </Col>
            ) : null}
          </Row>
          <Row>
            <Col md={12}>
              <div className="fixed-price-market-info">
                <p>{t("this_is_how_much_time_para")}</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="sell-footer-box">
          <Button className="previous-step-btn" onClick={() => setStep(step => step - 1)}>{t("previous_step")}</Button>
          <Button className="next-step-btn" onClick={onStepComplete}>{t("next_step")}</Button>
        </div>
      </div>
    </>
  );
};

export default PricingSellIndex;
