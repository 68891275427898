import React, { useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import WalletPayments from "./WalletPayments";
import SendAmountModal from "./SendAmountModal";
import { useDispatch, useSelector } from "react-redux";
import { adminPaymentDetailsStart } from "../../store/slices/WalletSlice";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../Common/NoDataFound";

const UpdateWalletIndex = (props) => {

  const t = useTranslation();
  const dispatch = useDispatch();
  const [sendAmountModal, setSendAmountModal] = React.useState(false);
  const profile = useSelector((state) => state.user.profile);
  const adminPaymentDetails = useSelector((state) => state.wallet.adminPaymentDetails);

  const closeSendAmountModal = () => {
    setSendAmountModal(false);
  };

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(adminPaymentDetailsStart({
        trader_unique_id: profile.data.reference_unique_id,
        skip: 0,
        take: 12,
      }));
    }
  }, [profile])

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="update-wallet-header-sec">
                <h3>Update Wallet</h3>
                {/* <div className="find-offer-btn-sec">
                  <Button
                    onClick={() => setSendAmountModal(true)}
                    className="default-btn"
                  >
                    Send Amount
                  </Button>
                </div> */}
              </div>
              {adminPaymentDetails.loading ? <Skeleton height={80} style={{margin:"1em 0"}}/> : (Object.keys(adminPaymentDetails.data).length > 0 && Object.keys(adminPaymentDetails.data.trader_wallet).length > 0) ? <div className="wallet-update-details-box">
                <div className="profile-details-card">
                  <p>Total</p>
                  <h5>{adminPaymentDetails.data.trader_wallet.total}</h5>
                </div>
                <div className="profile-details-card">
                  <p>Onhold</p>
                  <h5>{adminPaymentDetails.data.trader_wallet.onhold}</h5>
                </div>
                <div className="profile-details-card">
                  <p>Used</p>
                  <h5>{adminPaymentDetails.data.trader_wallet.used}</h5>
                </div>
                <div className="profile-details-card">
                  <p>Remaining</p>
                  <h5>{adminPaymentDetails.data.trader_wallet.remaining}</h5>
                </div>
              </div> : <NoDataFound/>}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <WalletPayments isInfinite={true} />
            </Col>
          </Row>
        </Container>
      </div>
      {sendAmountModal && (
        <SendAmountModal
          closeSendAmountModal={closeSendAmountModal}
          sendAmountModal={sendAmountModal}
        />
      )}
    </>
  );
};

export default UpdateWalletIndex;
