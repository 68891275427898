import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, InputGroup, Row, Image, FormControl } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from 'react-multi-lang';
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from 'yup';
import {
  updateTwoStepAuthStart,
  changePasswordStart,
  updateUserDetailsStart,
  deleteAccountStart,
  updateNotificationStart,
} from '../../store/slices/UserSlice';
import { Error, PasswordField } from '../Common/FormUtils';
import { useNavigate } from 'react-router-dom';

const NotificationPreferences = (props) => {
  const t = useTranslation();

  const [skipRender, setSkipRender] = useState(true);
  const [initialNotificationValues, setInitialNotificationValues] = useState({
    is_email_notification: props.profile.data.is_email_notification,
    is_mobile_notification: props.profile.data.is_mobile_notification,
  });
  const [notificationValues, setNotificationValues] = useState({
    is_email_notification: props.profile.data.is_email_notification,
    is_mobile_notification: props.profile.data.is_mobile_notification,
  });

  useEffect(() => {
    if (!skipRender && !props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setInitialNotificationValues({
        is_email_notification: props.profile.data.is_email_notification,
        is_mobile_notification: props.profile.data.is_mobile_notification,
      });
      setNotificationValues({
        is_email_notification: props.profile.data.is_email_notification,
        is_mobile_notification: props.profile.data.is_mobile_notification,
      });
    }
  }, [props.profile]);

  // useEffect(() => {
  //   if (!skipRender && (
  //     initialNotificationValues.is_email_notification != notificationValues.is_email_notification ||
  //     initialNotificationValues.is_mobile_notification != notificationValues.is_mobile_notification
  //   )) {
  //     props.dispatch(updateNotificationStart(notificationValues));
  //   }
  //   setSkipRender(false);
  // }, [notificationValues]);

  const handleToggle = (key, apiVal) => {
    if (props.profile.data.is_two_step_auth_enabled == 0) {
      setNotificationValues({
        ...notificationValues,
        [key]: !notificationValues[key]
      });
      props.dispatch(updateNotificationStart({
        notification_type: apiVal
      }));
    }
  }

  return (
    <>
      <div className="account-setting-box">
        <h3>{t("notification_preferences")}</h3>
        <p>{t("notification_notes")}</p>
        <Row>
          <Col md={2}>
            {props.profile.data.email ?
              <div className="two-step-auth-sec mb-3">
                <div className="two-step-auth-header-sec">
                  <div className="two-step-auth-info">
                    <h4>{t("email")}</h4>
                  </div>
                  <div className="two-step-auth-toggle-switch-sec">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      disabled={props.updateNotification.buttonDisable}
                      checked={notificationValues.is_email_notification}
                      onClick={() => handleToggle("is_email_notification", "email")}
                    />
                  </div>
                </div>
              </div>
              : null
            }
            {props.profile.data.mobile ?
              <div className="two-step-auth-sec">
                <div className="two-step-auth-header-sec">
                  <div className="two-step-auth-info">
                    <h4>{t("mobile")}</h4>
                  </div>
                  <div className="two-step-auth-toggle-switch-sec">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label=""
                      disabled={props.updateNotification.buttonDisable}
                      checked={notificationValues.is_mobile_notification}
                      onClick={() => handleToggle("is_mobile_notification", "mobile")}
                    />
                  </div>
                </div>
              </div>
              : null
            }
          </Col>
        </Row>
      </div>
    </>
  )
}



const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateNotification: state.user.updateNotification,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(NotificationPreferences));