import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import CustomWhiteSelect from "../Lists/CustomWhiteSelect";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { Error } from '../Common/FormUtils';
import {
	transferTokenStart,
	fetchWalletDetailsStart
} from "../../store/slices/WalletSlice";

const SendCryptoCurrencyModal = (props) => {
	const t = useTranslation();

	const [selectedOption, setSelectedOption] = useState({
		value: "BNB",
		label: "BNB",
		icon: ""
	})

	const [tokens, setTokens] = useState("");
	const [skipRender, setSkipRender] = useState(true);

	useEffect(() => {
		let tokens = [];
		props.wallets.map((token) => {
			tokens.push({ value: token.token_type, label: token.token_type, icon: token.icon });
			if (token.token_type == props.tokenType) setSelectedOption({ ...selectedOption, value: token.token_type, label: token.token_type, icon: token.icon });
		});
		setTokens(tokens);
	}, [])

	const sendTokenSchema = Yup.object().shape({
		receiver_wallet_address: Yup.string().required(t("required")),
		tokens: Yup.number().moreThan(0).required(t("required")),
	});

	const handlesendToken = (values) => {
		props.dispatch(transferTokenStart({
			...values,
			token_type: props.tokenType,
		}));
	}

	useEffect(() => {
		if (!skipRender && !props.transferToken.loading && Object.keys(props.transferToken.data).length > 0) {
			props.closeSendCryptoCurrencyModal()
			props.dispatch(fetchWalletDetailsStart());
		}
		setSkipRender(false)
	}, [props.transferToken]);

	useEffect(() => {
		props.setTokenType(selectedOption.value);
	}, [selectedOption]);

	return (
		<>
			<Modal
				className="modal-dialog-center send-crypto-currency-modal"
				size="md"
				centered
				show={props.sendCryptoCurrency}
				onHide={props.closeSendCryptoCurrencyModal}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						<Image
							className="send-icon"
							src={
								window.location.origin + "/assets/img/wallet/send-icon.svg"
							}
						/>
						<span>{t("send_cryptocurrency")}</span>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Formik
						initialValues={{
							receiver_wallet_address: "",
							tokens: "",
						}}
						validationSchema={sendTokenSchema}
						onSubmit={(values) => handlesendToken(values)}
					>
						{({ touched, errors }) =>
							<FORM className="send-crypto-currency-form">
								{tokens.length > 0 && (
									<div className="send-crypto-currency">
										<h4>{t("sending")}</h4>
										<CustomWhiteSelect
											options={tokens}
											selectedOption={selectedOption}
											setSelectedOption={setSelectedOption}
										/>
									</div>
								)}

								<Form.Group>
									<div className="send-crypto-wallet-address">
										<Form.Label>{t("send_to")}</Form.Label>
										<Field
											className="form-control"
											type="text"
											name="receiver_wallet_address"
											placeholder={t("enter_wallet_address")}
										/>
									</div>
									<Error name="receiver_wallet_address" />
								</Form.Group>

								{/* <div className="send-crypto-form-error-msg">
									<Image
										className="error-msg-icon"
										src={
											window.location.origin + "/assets/img/wallet/error-info-icon.svg"
										}
									/>
									<span>Invalid address. Please make sure you have entered it correctly.</span>
								</div> */}

								<Form.Group>
									<div className="send-crypto-wallet-address">
										<Form.Label>{t("enter_amount_to_send")}</Form.Label>
										<Field
											className="form-control"
											type="number"
											step="any"
											name="tokens"
											placeholder={t("enter_amount")}
										/>
									</div>
									<Error name="tokens" />
								</Form.Group>

								<div className="send-crypto-wallet-btn-sec">
									<Button
										className="default-btn w-100"
										type="submit"
										disabled={props.transferToken.buttonDisable}
									>
										{props.transferToken.buttonDisable ?
											t("loading")
											: t("send")
										}
									</Button>
								</div>
							</FORM>
						}
					</Formik>
				</Modal.Body>
			</Modal>
		</>
	);
};

const mapStateToPros = (state) => ({
	transferToken: state.wallet.transferToken,
});

function mapDispatchToProps(dispatch) {
	return { dispatch };
}

export default connect(
	mapStateToPros,
	mapDispatchToProps
)(withTranslation(SendCryptoCurrencyModal));