import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserDetailsFailure } from '../../store/slices/UserSlice';

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem("userLoginStatus");
    localStorage.removeItem("userId");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user_unique_id");
    dispatch(fetchUserDetailsFailure());
    navigate("/");
  }, []);

  return <></>;
}

export default Logout;