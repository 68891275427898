import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import SumsubWebSdk from "@sumsub/websdk-react";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import { kycStatusUpdateStart } from "../../store/slices/UserSlice";
import { useTranslation, withTranslation } from "react-multi-lang";

const KYCUpdateModal = (props) => {

  const dispatch = useDispatch();
  const t =useTranslation();

  const kycApplicant = useSelector((state) => state.user.kycApplicant);
  const errorHandler = (data) => console.log("onError", data);
  const accessTokenExpirationHandler = () => {};
  const [skip, setSkip] = useState(false);

  const options = {};
  const messageHandler = (type, payload) => {
    if (type == "idCheck.applicantReviewComplete" && payload.authCode) {
      if(!skip)
      {
        dispatch(
          kycStatusUpdateStart({
            sumsub_id: payload.authCode,
          })
        );
      }
    }
    setSkip(true)
  };

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.kyc}
        onHide={props.closeKyc}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("kyc_update")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {kycApplicant.loading ? (
            <div className="kyc-modal-loader-sec">
              <CenterLoader />
            </div>
          ) : Object.keys(kycApplicant.data).length > 0 ? (
            <SumsubWebSdk
              accessToken={kycApplicant.data.token}
              expirationHandler={accessTokenExpirationHandler}
              config={{}}
              options={options}
              onMessage={messageHandler}
              onError={errorHandler}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(KYCUpdateModal);
