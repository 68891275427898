import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./Wallet.css";
import { Link } from "react-router-dom";
import SendCryptoCurrencyModal from "./SendCryptoCurrencyModal";
import ReceiveCryptoCurrencyModal from "./ReceiveCryptoCurrencyModal";
import PageLoader from "../Common/PageLoader"
import { withTranslation, useTranslation } from "react-multi-lang"
import { connect } from 'react-redux'
import { fetchWalletDetailsStart } from '../../store/slices/WalletSlice';
import SomethingWentWrong from "../Common/SomethingWentWrong";
import WalletTransaction from "./WalletTransaction";


const WalletIndex = (props) => {

  const [tokenType, setTokenType] = useState("BNB");

  const [activeKey, setActiveKey] = useState("balance");

  const [sendCryptoCurrency, setSendCryptoCurrency] = useState(false);
  const t =useTranslation();

  const closeSendCryptoCurrencyModal = () => {
    setSendCryptoCurrency(false);
  };

  const [receiveCryptoCurrency, setReceiveCryptoCurrency] = useState(false);

  const closeReceiveCryptoCurrencyModal = () => {
    setReceiveCryptoCurrency(false);
  };

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      props.dispatch(fetchWalletDetailsStart())
    }
  }, [props.profile])

  return (
    <>
      {props.profile.loading || props.wallet.loading ?
        <PageLoader />
        : Object.keys(props.wallet.data).length > 0 && props.wallet.data.wallets ?
          <div className="wallet-sec">
            <Container>
              <Row>
                <Col md={12}>
                  <Tab.Container id="left-tabs-example" defaultActiveKey={activeKey}>
                    <div className="wallet-tab-nav-sec">
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link onClick={() => setActiveKey("balance")} eventKey="balance">Balance</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link onClick={() => setActiveKey("transaction")} eventKey="transaction">Transaction</Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                    <div className="wallet-tab-content-sec">
                      <Tab.Content>
                        <Tab.Pane eventKey="balance">
                          <div className="wallet-balance-box">
                            <div className="wallet-balance-card">
                              <h6>{t("total_holdings")}</h6>
                              <h4>{props.wallet.data.total_formatted}</h4>
                              {/* <p>≈ 0.00004869 BTC</p> */}
                            </div>
                          </div>
                          <div className="wallet-balance-list-box">
                            <Row>
                              {props.wallet.data.wallets.length > 0 ?
                                props.wallet.data.wallets.map((wallet, i) =>
                                  <Col md={6} xl={4} lg={4} key={i}>
                                    <div className="wallet-balance-list-card">
                                      <div className="wallet-balance-list-header">
                                        <Image
                                          className="wallet-balance-coin-icon"
                                          src={wallet.icon ? wallet.icon :
                                            window.location.origin + "/assets/img/wallet/bnb-icon.svg"
                                          }
                                        />
                                        <h5>{wallet.token_type}</h5>
                                        <div className="wallet-balance-coversion-currency">
                                          <p>1{wallet.token_type} =
                                            <span>
                                              {wallet.token_value}
                                              <Image
                                                className="coversion-icon"
                                                src={
                                                  window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                                }
                                              />
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="wallet-balance-list-body">
                                        <div className="wallet-balance-list-item">
                                          <h6>{t("total_holdings")}</h6>
                                          <h4>≈ {wallet.remaining_converted}</h4>
                                          <p>{wallet.remaining_formatted}</p>
                                        </div>
                                      </div>
                                      <div className="wallet-balance-list-footer">
                                        {/* <Button className="buy-btn">
                                          BUY
                                        </Button>
                                        <Button className="sell-btn">
                                          SELL
                                        </Button> */}
                                        <Button
                                          className="send-btn"
                                          onClick={() => {
                                            setSendCryptoCurrency(true);
                                            setTokenType(wallet.token_type)
                                          }
                                          }
                                        >
                                          <Image
                                            className="send-icon"
                                            src={
                                              window.location.origin + "/assets/img/wallet/send-icon.svg"
                                            }
                                          />
                                          <span>{t("send")}</span>
                                        </Button>
                                        <Button
                                          className="receive-btn"
                                          onClick={() => { 
                                            setReceiveCryptoCurrency(true);
                                           setTokenType(wallet.token_type)
                                          }}
                                        >
                                        <Image
                                          className="send-icon"
                                          src={
                                            window.location.origin + "/assets/img/wallet/next-icon.svg"
                                          }
                                        />
                                        <span>{t("Receive")}</span>
                                      </Button>
                                    </div>
                                  </div>
                                  </Col>
                            )
                            : t("no_wallets_found")
                              }
                            {/* <Col md={6} xl={4} lg={4}>
                                <div className="wallet-balance-list-card">
                                  <div className="wallet-balance-list-header">
                                    <Image
                                      className="wallet-balance-coin-icon"
                                      src={
                                        window.location.origin + "/assets/img/wallet/bnb-icon.svg"
                                      }
                                    />
                                    <h5>USDT <span>Teather</span></h5>
                                    <div className="wallet-balance-coversion-currency">
                                      <p>1BTC =
                                        <span>
                                          20.764 USD
                                          <Image
                                            className="coversion-icon"
                                            src={
                                              window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                            }
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="wallet-balance-list-body">
                                    <div className="wallet-balance-list-item">
                                      <h6>Total Holdings</h6>
                                      <h4>45,000 INR</h4>
                                      <p>≈ 0.00004869 BTC</p>
                                    </div>
                                  </div>
                                  <div className="wallet-balance-list-footer">
                                    <Button className="buy-btn">
                                      BUY
                                    </Button>
                                    <Button className="sell-btn">
                                      SELL
                                    </Button>
                                    <Button className="send-btn">
                                      <Image
                                        className="send-icon"
                                        src={
                                          window.location.origin + "/assets/img/wallet/send-icon.svg"
                                        }
                                      />
                                      <span>Send</span>
                                    </Button>
                                    <Button className="receive-btn">
                                      <Image
                                        className="send-icon"
                                        src={
                                          window.location.origin + "/assets/img/wallet/next-icon.svg"
                                        }
                                      />
                                      <span>Receive</span>
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6} xl={4} lg={4}>
                                <div className="wallet-balance-list-card">
                                  <div className="wallet-balance-list-header">
                                    <Image
                                      className="wallet-balance-coin-icon"
                                      src={
                                        window.location.origin + "/assets/img/wallet/bnb-icon.svg"
                                      }
                                    />
                                    <h5>ETH <span>Ethereum</span></h5>
                                    <div className="wallet-balance-coversion-currency">
                                      <p>1BTC =
                                        <span>
                                          20.764 USD
                                          <Image
                                            className="coversion-icon"
                                            src={
                                              window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                            }
                                          />
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="wallet-balance-list-body">
                                    <div className="wallet-balance-list-item">
                                      <h6>Total Holdings</h6>
                                      <h4>45,000 INR</h4>
                                      <p>≈ 0.00004869 BTC</p>
                                    </div>
                                  </div>
                                  <div className="wallet-balance-list-footer">
                                    <Button className="buy-btn">
                                      BUY
                                    </Button>
                                    <Button className="sell-btn">
                                      SELL
                                    </Button>
                                    <Button className="send-btn">
                                      <Image
                                        className="send-icon"
                                        src={
                                          window.location.origin + "/assets/img/wallet/send-icon.svg"
                                        }
                                      />
                                      <span>Send</span>
                                    </Button>
                                    <Button className="receive-btn">
                                      <Image
                                        className="send-icon"
                                        src={
                                          window.location.origin + "/assets/img/wallet/next-icon.svg"
                                        }
                                      />
                                      <span>Receive</span>
                                    </Button>
                                  </div>
                                </div>
                              </Col> */}
                          </Row>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="transaction">
                        {activeKey == "transaction" && (
                          <WalletTransaction />
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Tab.Container>
              </Col>
            </Row>
          </Container>
            {sendCryptoCurrency && (
        <SendCryptoCurrencyModal
          sendCryptoCurrency={sendCryptoCurrency}
          closeSendCryptoCurrencyModal={closeSendCryptoCurrencyModal}
          tokenType={tokenType}
          setTokenType={setTokenType}
          wallets={props.wallet.data.wallets}
        />
      )}
      {receiveCryptoCurrency && (
        <ReceiveCryptoCurrencyModal
          receiveCryptoCurrency={receiveCryptoCurrency}
          closeReceiveCryptoCurrencyModal={closeReceiveCryptoCurrencyModal}
          setReceiveCryptoCurrency={setReceiveCryptoCurrency}
          tokenType={tokenType}
        />
      )}
    </div>
          : <SomethingWentWrong
  showReload={true}
  reloadText="Retry"
  onReload={() => props.dispatch(fetchWalletDetailsStart())}
/>
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  wallet: state.wallet.wallet,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(WalletIndex));
