import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  saveFeedback: getCommonInitialState(),
  feedbackList: getCommonInitialState(),
  singleFeedback: getCommonInitialState(),
  deleteFeedback: getCommonInitialState(),
  receivedFeedbackList: getCommonInitialState({ user_feed_backs: [], total_user_feed_backs: 0 }),
};

const FeedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {

    // Save Feedback
    saveFeedbackStart: (state) => { state.saveFeedback = getCommonStart() },
    saveFeedbackSuccess: (state, action) => { state.saveFeedback = getCommonSuccess(action.payload) },
    saveFeedbackFailure: (state, action) => { state.saveFeedback = getCommonFailure(action.payload) },

    //Feedback List
    fetchFeedbackListStart: (state) => { state.feedbackList = getCommonStart() },
    fetchFeedbackListSuccess: (state, action) => { state.feedbackList = getCommonSuccess(action.payload) },
    fetchFeedbackListFailure: (state, action) => { state.feedbackList = getCommonFailure(action.payload) },

    //Single Feedback
    fetchSingleFeedbackStart: (state) => { state.singleFeedback = getCommonStart() },
    fetchSingleFeedbackSuccess: (state, action) => { state.singleFeedback = getCommonSuccess(action.payload) },
    fetchSingleFeedbackFailure: (state, action) => { state.singleFeedback = getCommonFailure(action.payload) },

    //Delete Feedback
    deleteFeedbackStart: (state) => { state.deleteFeedback = getCommonStart() },
    deleteFeedbackSuccess: (state, action) => { state.deleteFeedback = getCommonSuccess(action.payload) },
    deleteFeedbackFailure: (state, action) => { state.deleteFeedback = getCommonFailure(action.payload) },

    //Feedback List
    fetchReceivedFeedbackListStart: (state) => { state.receivedFeedbackList = getCommonStart({ user_feed_backs: [], total_user_feed_backs: 0 }) },
    fetchMoreReceivedFeedbackListStart: (state) => state,
    fetchReceivedFeedbackListSuccess: (state, action) => { state.receivedFeedbackList = getCommonSuccess(action.payload) },
    fetchReceivedFeedbackListFailure: (state, action) => { state.receivedFeedbackList = getCommonFailure(action.payload) },
  }
});

export const {
  saveFeedbackStart,
  saveFeedbackSuccess,
  saveFeedbackFailure,
  fetchFeedbackListStart,
  fetchFeedbackListSuccess,
  fetchFeedbackListFailure,
  fetchSingleFeedbackStart,
  fetchSingleFeedbackSuccess,
  fetchSingleFeedbackFailure,
  deleteFeedbackStart,
  deleteFeedbackSuccess,
  deleteFeedbackFailure,
  fetchReceivedFeedbackListStart,
  fetchMoreReceivedFeedbackListStart,
  fetchReceivedFeedbackListSuccess,
  fetchReceivedFeedbackListFailure,

} = FeedbackSlice.actions;

export default FeedbackSlice.reducer;