import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getTraderCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  traderOfferBuySuccess,
  traderOfferBuyFailure,
  traderOfferListSuccess,
  traderOfferListFailure,
} from "../slices/TraderSlice";

function* traderOfferListAPI(action) {
  const traderOfferListData = yield select((state) => state.trader.traderOfferList.data);
  yield getTraderCommonSaga({
    apiUrl: "traders/offers",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => {
        if(Object.keys(traderOfferListData).length > 0){
          store.dispatch(
            traderOfferListSuccess({
                sellers: [...traderOfferListData.sellers, ...data.sellers],
                total_sellers: data.total_sellers,
            })
          );
        }
        else{
          store.dispatch(
            traderOfferListSuccess(data)
          );
        }
    },
    failure: traderOfferListFailure,
  });
}

function* traderOfferBuyAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "trader_offers/buy",
    payload: action.payload,
    successNotify: false,
    success: traderOfferBuySuccess,
    failure: traderOfferBuyFailure,
  });
}

export default function* PageSaga() {
  yield all([
    yield takeLatest("trader/traderOfferListStart", traderOfferListAPI),
    yield takeLatest("trader/moreTraderOfferListStart", traderOfferListAPI),
    yield takeLatest("trader/traderOfferBuyStart", traderOfferBuyAPI),
  ]);
}
