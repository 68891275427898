import React, { useState, useEffect } from "react";
import { Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateBuyData } from "../../store/slices/OfferSlice";
import { kycApplicantStart } from "../../store/slices/UserSlice";

const TraderSingleOfferRow = ({ activeOffer, i, setKyc }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);

  const buyData = useSelector((state) => state.offer.buyData);
  const profile = useSelector((state) => state.user.profile);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);

  const onBuy = (e) => {
    e.preventDefault();
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.kyc_verified === 0) {
        dispatch(kycApplicantStart());
      } else {
        dispatch(
          updateBuyData({
            ...buyData,
            trader_unique_id: activeOffer.trader_unique_id,
            trader_id: activeOffer.trader_id,
            offer_amount: activeOffer.offer_amount,
            offer_amount_formatted: activeOffer.offer_amount_formatted,
            trader_exchange_value: activeOffer.exchange_value,
          })
        );
        navigate("/trader-buy");
      }
    } else navigate("/login");
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycApplicant.loading &&
      Object.keys(kycApplicant.data).length > 0
    ) {
      setKyc(true);
    }
    setSkipRender(false);
  }, [kycApplicant]);

  return (
    <tr key={i}>
      <td>{activeOffer.trader_unique_id}</td>
      <td>
        <div className="list-user-details">
          <div className="list-user-info">
            <h4>{activeOffer.name}</h4>
            <p>{activeOffer.email}</p>
          </div>
        </div>
      </td>
      {/* <td>{activeOffer.name}</td> */}
      <td>
        <div className="list-price-details">
          <div className="price-amount">
            {activeOffer.offer_amount_formatted}
          </div>
          <div className="price-convertion-amount">
            <p>1 USDT = {activeOffer.exchange_value} INR</p>
          </div>
        </div>
      </td>
      {/* <td>{activeOffer.email}</td> */}
      <td>
        <div className="limit-transaction-amount">
          <h5>
            Available <span>{activeOffer.offer_amount_formatted}</span>
          </h5>
          <h5>
            Limit <span>{activeOffer.offer_amount_formatted}</span>
          </h5>
        </div>
      </td>
      <td>
        <div className="list-action-btn-sec">
          <Button
            disabled={kycApplicant.buttonDisable}
            className="buy-btn"
            onClick={onBuy}
          >
            BUY
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default TraderSingleOfferRow;
