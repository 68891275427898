import React, { useState, useEffect } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Button, NavDropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect, useDispatch, useSelector } from "react-redux";
import configuration from 'react-global-configuration'
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import { becomeASellerStart } from '../../../store/slices/UserSlice';
import { updateBuyData } from "../../../store/slices/OfferSlice";

const HeaderIndex = (props) => {
  const t = useTranslation();
  const { isTransparent } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector(state => state.user.profile);
  const becomeASeller = useSelector(state => state.user.becomeASeller);
  const buyData = useSelector(state => state.offer.buyData);

  const [dropdownOpen, setDropdownOpen] = useState(false);


  const handleCreateOffer = e => {
    e.preventDefault();
    if(!profile.loading && Object.keys(profile.data).length > 0) {
      if(profile.data.status == 1)
        props.dispatch(becomeASellerStart());
      else if(profile.data.status == 2)
        getSuccessNotificationMessage('Seller request sent to the admin');
      else
        navigate('/sell');
    }
    else {
      navigate("/login")
    }
  }

  const redirectOfferList = (e) => {
    e.preventDefault();
    dispatch(updateBuyData({
      ...buyData,
      token_type: {
        api_id: "",
        label: "All",
        token: "",
        token_icon: window.location.origin + "/assets/img/create-offers/all-tokens.png",
        value: ""
      }
    }));
    navigate('/list');
  }

  return (
    <>
      <header className={isTransparent ? "header-nav-center" : "auth-header-nav-center"}>
        <Navbar expand="lg">
          <Container>
            <Link className="navbar-band" to="/">
              <Image
                className="logo"
                src={isTransparent ?
                  configuration.get("configData.site_logo")
                  : configuration.get("configData.site_dark_logo")
                }
              />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <Image
                className="hamburger-menu"
                src={
                  isTransparent ?
                    window.location.origin + "/assets/img/hamburger-menu.svg"
                    : window.location.origin + "/assets/img/hamburger-menu-black.svg"
                }
              />
            </Navbar.Toggle>
            {localStorage.getItem("userId") &&
              localStorage.getItem("accessToken") &&
              localStorage.getItem("userLoginStatus") ?
              <Navbar.Collapse>
                <Nav className="ms-auto">
                  {!props.profile.loading && props.profile.data.user_id ?
                    <>
                      <Link className="nav-link" onClick={handleCreateOffer}>{becomeASeller.buttonDisable ? "Loading" : "Create An Offer"}</Link>
                      <Link className="nav-link" onClick={redirectOfferList}>Active Offers</Link>
                      {/* <Link className="nav-link" to="/trader-offers">Trader Offers</Link> */}
                      {/* <Link className="nav-link" to="/wallet">Wallet</Link> */}
                      {/* <div className="user-profile-details">
                        <h4>{props.profile.data.username}</h4>
                        <p>82.95 INR
                          <Button className="eye-hide-btn">
                            <Image
                              className="eye-hide-icon"
                              src={
                                window.location.origin + "/assets/img/home-page/eye-hide.svg"
                              }
                            />
                          </Button>
                        </p>
                      </div> */}
                    </>
                    : "Loading"
                  }
                  <div className="profile-dropdown-sec">
                    <NavDropdown title={
                      <Image
                        className="eye-hide-icon"
                        src={
                          window.location.origin + "/assets/img/home-page/profile-dropdown-icon.svg"
                        }
                      />
                    } id="basic-nav-dropdown"
                      onClick={()=> setDropdownOpen(!dropdownOpen)}
                      show={dropdownOpen}>
                        <Link className="dropdown-item" to="/settings">
                          <Image
                            className="dropdown-icon"
                            src={
                              window.location.origin + "/assets/img/landing-page/icons/settings-icon.svg"
                            }
                          />
                         {t("settings")}
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/profile">
                          <Image
                            className="dropdown-icon"
                            src={
                              window.location.origin + "/assets/img/landing-page/icons/profile-icon.svg"
                            }
                          />
                         {t("profile")}
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/my-offers">
                          <Image
                            className="dropdown-icon"
                            src={
                              window.location.origin + "/assets/img/landing-page/icons/my-offer.svg"
                            }
                          />
                        {t("my_offers")}
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/payment-methods">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" className="dropdown-icon" width={24} height={24}>
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        Payment Methods
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/upi">
                        <svg width="30" height="9" viewBox="0 0 30 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.1455 8.79638H21.583L23.7546 0.193542H25.3196L23.1455 8.79638Z" fill="#71716F"/>
                          <path d="M22.3333 0.464499C22.2266 0.299079 22.0598 0.21637 21.833 0.21637H13.232L12.808 1.90334H20.6288L20.1723 3.70372H12.3522L11.0547 8.83856H12.6196L13.4888 5.3934H20.5224C20.7485 5.3917 20.9672 5.30522 21.1422 5.14837C21.3274 4.9971 21.4616 4.78282 21.5238 4.53908L22.393 1.09392C22.4616 0.833402 22.4397 0.626305 22.3333 0.464499Z" fill="#71716F"/>
                          <path d="M10.141 8.25678C10.0988 8.4224 10.0085 8.56843 9.8837 8.67272C9.75891 8.77702 9.60649 8.83389 9.44953 8.83472H1.38454C1.29034 8.84234 1.19591 8.82385 1.10995 8.78096C1.02399 8.73807 0.949276 8.67216 0.892694 8.5893C0.83816 8.50096 0.803543 8.3997 0.791688 8.29384C0.779833 8.18799 0.791078 8.08056 0.824499 7.9804L2.79154 0.219879H4.35684L2.59933 7.15859H8.85875L10.6163 0.219879H12.1812L10.141 8.25678Z" fill="#71716F"/>
                          <path d="M28.0217 0.209015L30.0001 4.52011L25.8398 8.83121L28.0217 0.209015Z" fill="#078142"/>
                          <path d="M26.6313 0.209015L28.6097 4.52011L24.4463 8.83121L26.6313 0.209015Z" fill="#EA7726"/>
                        </svg>
                        UPIs
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/bank-account">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.5 17.5H17.5" stroke="#111111" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M4.18359 10V15" stroke="#111111" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M10.0088 10V15" stroke="#111111" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M15.8506 10V15" stroke="#111111" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M3.08371 5.54997L8.44204 2.87497C8.93038 2.62816 9.46988 2.49957 10.017 2.49957C10.5642 2.49957 11.1037 2.62816 11.592 2.87497L16.942 5.54164C17.1504 5.64538 17.3176 5.8164 17.4166 6.02702C17.5156 6.23764 17.5406 6.47552 17.4876 6.70212C17.4346 6.92873 17.3066 7.1308 17.1244 7.2756C16.9422 7.42041 16.7164 7.49946 16.4837 7.49997H3.54204C3.30808 7.50386 3.07983 7.42759 2.89521 7.28381C2.7106 7.14004 2.58073 6.93743 2.52719 6.70964C2.47365 6.48186 2.49966 6.24261 2.6009 6.03165C2.70214 5.82069 2.87251 5.65072 3.08371 5.54997Z" stroke="#111111" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Bank Accounts
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/update-wallet">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"  className="dropdown-icon" width={24} height={24}>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                          </svg>
                        Trader Wallet
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/user-wallet">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor"  className="dropdown-icon" width={24} height={24}>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3" />
                          </svg>
                          User Wallet
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/transaction-history">
                          <Image
                            className="dropdown-icon"
                            src={
                              window.location.origin + "/assets/img/landing-page/icons/trade-history-icon.svg"
                            }
                          />
                         {t("transaction_history")}
                        </Link>
                        <NavDropdown.Divider />
                        <Link className="dropdown-item" to="/logout">
                          <Image
                            className="dropdown-icon"
                            src={
                              window.location.origin + "/assets/img/landing-page/icons/logout-icon.svg"
                            }
                          />
                          {t("logout")}
                        </Link>
                    </NavDropdown>
                  </div>
                  {/* <div className="notification-dropdown-sec">
                    <NavDropdown title={
                      <Image
                        className="eye-hide-icon"
                        src={
                          window.location.origin + "/assets/img/home-page/notification-icon.svg"
                        }
                      />
                    } id="basic-nav-dropdown">

                    </NavDropdown>
                  </div> */}
                </Nav>
              </Navbar.Collapse>
              : <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <Link className="nav-link" onClick={handleCreateOffer}>{t("create_an_offer")}</Link>
                  <Link to="/login" className="nav-link header-login-btn">
                   {t("login")}
                  </Link>
                  <Link to="/register" className="nav-link header-register-btn">{t("register")}</Link>
                </Nav>
              </Navbar.Collapse>
            }
          </Container>
        </Navbar>
      </header>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(HeaderIndex));
