import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import AddAccountModal from "./AddAccountModal"
import { useDispatch, useSelector } from "react-redux";
import { userBillingsListStart } from "../../store/slices/UserSlice";
import CenterLoader from "../Common/CenterLoader";
import { useTranslation, withTranslation } from "react-multi-lang";

const BankAccountsSection = () => {

  const [addAccount, setAddAccount] = useState(false);
  const t = useTranslation();
  const dispatch = useDispatch();
  const userBillingsList = useSelector((state)=> state.user.userBillingsList);
  const closeAddAccountModal = () => {
    setAddAccount(false);
  }

  useEffect(()=> {
    dispatch(userBillingsListStart());
  },[])

  return (
    <>
      <div>
        <h4>Bank Accounts</h4>
        <div className="">
          {userBillingsList.loading ? <CenterLoader/> : Object.keys(userBillingsList.data).length>0 && Object.keys(userBillingsList.data.user_billing_accounts).length>0 ? 
          <div>
            <p>Account Holder Name : {userBillingsList.data.user_billing_accounts.account_holder_name}</p>
            <p>Account Number : {userBillingsList.data.user_billing_accounts.account_number}</p>
            <p>Bank Name : {userBillingsList.data.user_billing_accounts.bank_name}</p>
            <p>Bank Type : {userBillingsList.data.user_billing_accounts.bank_type == "checking" ? "Current" : userBillingsList.data.user_billing_accounts.bank_type}</p>
            <p>IFSC Code : {userBillingsList.data.user_billing_accounts.ifsc_code}</p>
            <Button type="button" className="default-btn" onClick={()=> setAddAccount(true)}>
            {t("edit_account")}
          </Button>
          </div> : <Button type="button" className="default-btn" onClick={()=> setAddAccount(true)}>
            {t("add_account")}
          </Button>}
        </div>
      </div>
      {addAccount && <AddAccountModal addAccount={addAccount} closeAddAccountModal={closeAddAccountModal} />}
    </>
  );
};

export default withTranslation(BankAccountsSection);
