import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {
  setTranslations,
  setDefaultLanguage,
  translate,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import LandingPageIndex from "../LandingPage/LandingPageIndex";
import EmptyLayout from "../layouts/EmptyLayout";
import MainLayout from "../layouts/MainLayout";
import RegisterIndex from "../Auth/RegisterIndex";
import LoginIndex from "../Auth/LoginIndex";
import SettingsIndex from "../Settings/SettingsIndex";
import TradeHistoryIndex from "../Trade/TradeHistoryIndex";
import ListIndex from "../Lists/ListIndex";
import ProfileIndex from "../Account/Profile/ProfileIndex";
import WalletIndex from "../Wallet/WalletIndex";
import SellIndex from "../CreateOffer/SellIndex";
import ForgotPasswordIndex from "../Auth/ForgotPasswordIndex";
import BuyIndex from "../CreateOffer/Buy/BuyIndex";
import ResetPassword from "../Auth/ResetPassword";
import Logout from '../Account/Logout';
import Page404 from "../Common/Page404";
import StaticIndex from "../Static/StaticIndex";
import ActiveOffersIndex from "../Account/Profile/ActiveOffersIndex";
import TransactionIndex from "../Transaction/TransactionIndex";
import AllOffersIndex from "../Account/Profile/AllOffersIndex";
import TraderOffersIndex from "../TraderOffers/TraderOffersIndex";
import UpdateWalletIndex from "../Wallet/UpdateWalletIndex";
import UPIIndex from "../UPI/UPIIndex";
import BankAccountIndex from "../BankAccounts/BankAccountIndex";
import PaymentMethodsIndex from "../PaymentMethods/PaymentMethodsIndex";
import TraderBuyIndex from "../CreateOffer/Buy/TraderBuyIndex";
import UserWalletIndex from "../Wallet/UserWalletIndex";

setTranslations({ en });


const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("accessToken");
  let userLoginStatus = localStorage.getItem("userLoginStatus");
  let authentication =
    userId && accessToken && userLoginStatus == "true" ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps} {...rest}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  const location = useLocation();
  setLanguage("en");

  const [lastpath, setLastPath] = useState();

  useEffect(() => {
    let userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setLanguage(userLanguage);
  }, []);

  useEffect(() => {
    if (lastpath !== location.pathname) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setLastPath(location.pathname);
    }
  }, [location]);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>

      <Routes>
        <Route
          path={"/"}
          element={
            <AppRoute
              component={LandingPageIndex}
              layout={MainLayout}
              isTransparent={true}
            />
          }
        />
        <Route
          path={"/register"}
          element={
            <AppRoute
              component={RegisterIndex}
              layout={EmptyLayout}
            />
          }
        />
        <Route
          path={"/login"}
          element={
            <AppRoute
              component={LoginIndex}
              layout={EmptyLayout}
            />
          }
        />
        <Route
          path={"/forgot-password"}
          element={
            <AppRoute
              component={ForgotPasswordIndex}
              layout={EmptyLayout}
            />
          }
        />
        <Route
          path={"/reset-password"}
          element={
            <AppRoute
              component={ResetPassword}
              layout={EmptyLayout}
            />
          }
        />
        <Route
          path={"/settings"}
          element={
            <PrivateRoute
              component={SettingsIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/trade-history"}
          element={
            <PrivateRoute
              component={TradeHistoryIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/list"}
          element={
            <AppRoute
              component={ListIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/profile"}
          element={
            <PrivateRoute
              component={ProfileIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/profile/:username"}
          element={
            <AppRoute
              component={ProfileIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/my-offers"}
          element={
            <PrivateRoute
              component={AllOffersIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/active-offer"}
          element={
            <PrivateRoute
              component={ActiveOffersIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/active-offer/:username"}
          element={
            <AppRoute
              component={ActiveOffersIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/wallet"}
          element={
            <PrivateRoute
              component={WalletIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/sell"}
          element={
            <PrivateRoute
              component={SellIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/sell/:token_offer_unique_id"}
          element={
            <PrivateRoute
              component={SellIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/buy"}
          element={
            <PrivateRoute
              component={BuyIndex}
              layout={MainLayout}
              isTransparent={true}
            />
          }
        />
        <Route
          path={"/trader-buy"}
          element={
            <PrivateRoute
              component={TraderBuyIndex}
              layout={MainLayout}
              isTransparent={true}
            />
          }
        />
        <Route
          path={"/transaction-history"}
          element={
            <PrivateRoute
              component={TransactionIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/logout"}
          element={
            <PrivateRoute
              component={Logout}
              layout={EmptyLayout}
            />
          }
        />
        <Route
          path={"/page/:static_page_unique_id"}
          element={
            <AppRoute
              component={StaticIndex}
              layout={MainLayout}
              redirect={false}
            />
          }
        />
        <Route
          path={"/trader-offers"}
          element={
            <AppRoute
              component={TraderOffersIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/update-wallet"}
          element={
            <AppRoute
              component={UpdateWalletIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/user-wallet"}
          element={
            <AppRoute
              component={UserWalletIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/upi"}
          element={
            <AppRoute
              component={UPIIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/bank-account"}
          element={
            <AppRoute
              component={BankAccountIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/payment-methods"}
          element={
            <AppRoute
              component={PaymentMethodsIndex}
              layout={MainLayout}
            />
          }
        />
        <Route
          path={"/*"}
          element={
            <AppRoute
              component={Page404}
              layout={EmptyLayout}
              redirect={false}
            />
          }
        />
      </Routes>
    </>
  );
};

export default App;
