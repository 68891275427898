import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, InputGroup, Row, Image, FormControl } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation, useTranslation } from 'react-multi-lang';
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from 'yup';
import {
  updateTwoStepAuthStart,
  changePasswordStart,
  updateUserDetailsStart,
  deleteAccountStart,
} from '../../store/slices/UserSlice';
import { Error, PasswordField } from '../Common/FormUtils';
import { useNavigate } from 'react-router-dom';

const GeneralSettings = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const changePasswordForm = useRef();

  const [skipRender, setSkipRender] = useState(true);
  const [initialTwoStep, setInitialTwoStep] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);

  const changePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required("Required"),
    password: Yup.string().required("Required").when("old_password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().notOneOf(
        [Yup.ref("old_password")],
        "Should not be old password"
      )
    }),
    password_confirmation: Yup.string().required("Required").when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    }),
  });

  useEffect(() => {
    if (!props.profile.loading && Object.keys(props.profile.data).length > 0) {
      setInitialTwoStep(props.profile.data.is_two_step_auth_enabled === 0 ? false : true);
      setTwoStepAuth(props.profile.data.is_two_step_auth_enabled === 0 ? false : true);
    }
  }, [props.profile.data]);

  useEffect(() => {
    if (!skipRender && !props.deleteAccount.loading && Object.keys(props.deleteAccount.data).length > 0) {
      navigate("/logout");
    }
  }, [props.deleteAccount]);

  useEffect(() => {
    if (!skipRender && !props.changePassword.loading && Object.keys(props.changePassword.data).length > 0) {
      changePasswordForm.current.resetForm();
    }
    setSkipRender(false);
  }, [props.changePassword]);

  const changeTwoStepAuth = values => {
    props.dispatch(updateTwoStepAuthStart(values));
  }

  const changePassword = values => {
    props.dispatch(changePasswordStart(values));
  }

  const deleteAccount = values => {
    props.dispatch(deleteAccountStart(values));
  }

  return (
    <>
      <div className="account-setting-box setting-form">
        <h3>{t("general_settings")}</h3>
        <Row>
          <Col md={6}>
            <div className="two-step-auth-sec">
              <div className="two-step-auth-header-sec">
                <div className="two-step-auth-info">
                  <h4>{t("two_step_auth")}</h4>
                  <p>{t("two_step_auth_notes")}</p>
                </div>
                <div className="two-step-auth-toggle-switch-sec">
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={twoStepAuth}
                    onClick={() => setTwoStepAuth(!twoStepAuth)}
                  />
                </div>
              </div>
              {initialTwoStep != twoStepAuth ?
                <div className="two-step-auth-password-change-sec">
                  <Formik
                    initialValues={{
                      password: "",
                    }}
                    validationSchema={Yup.object().shape({
                      password: Yup.string().required("Required"),
                    })}
                    onSubmit={(values) => changeTwoStepAuth(values)}
                  >
                    {({ touched, errors }) =>
                      <FORM>
                        <Form.Group className="mb-0">
                          <Form.Label>{t("your_password")}</Form.Label>
                          <PasswordField
                            placeholder={t("password")}
                            name="password"
                          />
                          <Error name="password" />
                        </Form.Group>
                        <div className="two-step-auth-btn-sec">
                          <Button
                            type="submit"
                            className="default-btn"
                            disabled={props.updateTwoStep.buttonDisable}
                          >
                            {props.updateTwoStep.buttonDisable ?
                              t("loading")
                              : t("save")
                            }
                          </Button>
                        </div>
                      </FORM>
                    }
                  </Formik>
                </div>
                : null
              }
            </div>
          </Col>
          <Col md={6}>
            <Formik
              innerRef={changePasswordForm}
              initialValues={{
                old_password: "",
                password: "",
                password_confirmation: "",
              }}
              validationSchema={changePasswordSchema}
              onSubmit={(values) => changePassword(values)}
            >
              {({ errors, touched }) =>
                <FORM>
                  <div className="change-password-sec">
                    <div className="two-step-auth-info">
                      <h4>{t("change_password")}</h4>
                      <p>{t("change_password_notes")}</p>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("old_password")}*</Form.Label>
                            <PasswordField
                              name="old_password"
                              placeholder={t("old_password_placeholder")}
                            />
                            <Error name="old_password" />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("new_password")}*</Form.Label>
                            <PasswordField
                              name="password"
                              placeholder={t("new_password_placeholder")}
                            />
                            <Error name="password" />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t("confirm_password")}*</Form.Label>
                            <PasswordField
                              name="password_confirmation"
                              placeholder={t("confirm_password_placeholder")}
                            />
                            <Error name="password_confirmation" />
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    <div className="two-step-auth-btn-sec">
                      <Button
                        type="submit"
                        className="default-btn"
                        disabled={props.changePassword.buttonDisable}
                      >
                        {props.changePassword.buttonDisable ?
                          t("loading")
                          : t("save")
                        }
                      </Button>
                    </div>
                  </div>
                </FORM>
              }
            </Formik>
          </Col>
        </Row>
      </div>
      <SocialMediaUpdator />
      <div className="attach-social-media-links-box setting-form">
        <Formik
          initialValues={{
            password: "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required("Required"),
          })}
          onSubmit={(values) => deleteAccount(values)}
        >
          {({ errors, touched }) =>
            <FORM>
              <h4>{t("delete_account")}</h4>
              {/* <p>{t("two_step_auth_notes")}</p> */}
              <Row className="d-flex">
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <PasswordField
                      name="password"
                      placeholder={t("password_placeholder")}
                    />
                    <Error name="password" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12} className="d-flex">
                  <Button
                    type="submit"
                    className="default-btn"
                    disabled={props.deleteAccount.buttonDisable}
                  >
                    {props.deleteAccount.buttonDisable ?
                      t("loading")
                      : t("delete_my_account")
                    }
                  </Button>
                </Col>
              </Row>
            </FORM>
          }
        </Formik>
      </div>
    </>
  )
}

const SocialMediaUpdator = (props) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const updateProfile = useSelector(state => state.user.updateProfile);

  const validateLink = (value) => {
    if (value.replaceAll(" ", "") !== value) {
      return "Invalid link";
    }
    return "";
  };

  const handleSubmit = (values) => {
    if (profile.data.twitter_link != values.twitter_link ||
      profile.data.instagram_link != values.instagram_link ||
      profile.data.linkedin_link != values.linkedin_link ||
      profile.data.twitch_link != values.twitch_link
    )
      dispatch(updateUserDetailsStart(values));
  };

  return (
    <div className="attach-social-media-links-box">
      <h4>Attach Your Social Media Links</h4>
      <Formik
        initialValues={{
          twitter_link: profile.data.twitter_link
            ? profile.data.twitter_link
            : "",
          instagram_link: profile.data.instagram_link
            ? profile.data.instagram_link
            : "",
          linkedin_link: profile.data.linkedin_link
            ? profile.data.linkedin_link
            : "",
          twitch_link: profile.data.twitch_link
            ? profile.data.twitch_link
            : "",
        }}
        // validationSchema={{}}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched }) =>
          <FORM>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("twitter_label")}</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("twitter_placeholder")}
                    validate={validateLink}
                    name="twitter_link"
                  />
                  <Error name="twitter_link" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("instagram_label")}</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("instagram_placeholder")}
                    validate={validateLink}
                    name="instagram_link"
                  />
                  <Error name="instagram_link" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("linkedin_label")}</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("linkedin_placeholder")}
                    validate={validateLink}
                    name="linkedin_link"
                  />
                  <Error name="linkedin_link" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t("twitch_label")}</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("twitch_placeholder")}
                    validate={validateLink}
                    name="twitch_link"
                  />
                  <Error name="twitch_link" />
                </Form.Group>
              </Col>
              <Col md={12} className="d-flex justify-content-center">
                <Button
                  type="submit"
                  className="default-btn"
                  disabled={updateProfile.buttonDisable}
                >
                  {updateProfile.buttonDisable ?
                    t("loading")
                    : t("save")
                  }
                </Button>
              </Col>
            </Row>
          </FORM>
        }
      </Formik>
    </div >
  );
}

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateTwoStep: state.user.updateTwoStep,
  changePassword: state.user.changePassword,
  deleteAccount: state.user.deleteAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(GeneralSettings));