import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { upiDeleteStart, upiSaveStart } from "../../store/slices/WalletSlice";

const DeleteUPIModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  const upiDelete = useSelector((state) => state.wallet.upiDelete)
  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (
      !skipRender &&
      !upiDelete.loading &&
      Object.keys(upiDelete.data).length > 0
    ) {
      props.closeDeleteUPIModal();
    }
    setSkipRender(false);
  }, [upiDelete]);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.deleteUPI}
        onHide={props.closeDeleteUPIModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete UPI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="send-amount-sec">
            <React.Fragment>
              <p>
                Are you sure to delete this UPI? It will be deleted permanently.
              </p>
              <div className="btn-sec">
                <Button
                  type="submit"
                  onClick={() => dispatch(upiDeleteStart({ trader_upi_id: props.deleteUPI, trader_unique_id: profile.data.reference_unique_id }))}
                  className="default-btn w-100"
                  disabled={upiDelete.buttonDisable}
                >
                  {upiDelete.buttonDisable
                    ? "Loading..."
                    : "Delete"}
                </Button>
              </div>
            </React.Fragment>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteUPIModal);
