import React from 'react'
import { Image } from 'react-bootstrap'

const Wizard = ({ currentStep = 1, steps = [] }) => {

  return (
    <div className="sell-steps-status-box">
      <div className="progress-wrap">
        {steps.map((step, i) =>
          <div className="step-card">
            <div className={`step ${currentStep > i + 1 ? "active" : ""}`}>
              {currentStep > i + 1 ?
                <Image
                  className="step-active"
                  src={
                    window.location.origin + "/assets/img/create-offers/check-tick.svg"
                  }
                />
                : null
              }
            </div>
            <span className={currentStep >= i + 1 ? "active" : ""}>{step.name}</span>
          </div>
        )}
      </div>
    </div >
  )
}

export default Wizard