import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialBuyData = {
  token_offer_unique_id: "",
  spendAmount: 100,
  tokens: 0,
  token_type: null,
  payment_mode: "UPI",
  payment_id: "",
  trader_unique_id: "",
  trader_id: "",
  offer_amount: 0,
  offer_amount_formatted: "",
  trader_exchange_value: "",
}

const initialState = {
  activeTokenOffers: getCommonInitialState({ token_offers: [], total_token_offers: 0 }),
  amountConversion: getCommonInitialState(),
  createTokenOffer: getCommonInitialState(),
  tokenOffersList: getCommonInitialState({ token_offers: [], total_token_offers: 0 }),
  offerTransactionList: getCommonInitialState({ offer_transactions: [], total_offer_transactions: 0 }),
  buyToken: getCommonInitialState(),
  updateBuyTokenStatus: getCommonInitialState(),
  updateUsdtTokenStatus: getCommonInitialState(),
  tokenOfferView: getCommonInitialState(),
  tokenOfferDelete: getCommonInitialState(),
  buyData: initialBuyData,
  offerFeedbackList: getCommonInitialState({ user_feed_backs: [], total_user_feed_backs: 0 }),
};

const OfferSlice = createSlice({
  name: "offer",
  initialState,
  reducers: {

    // Active Token Offers List
    fetchActiveTokenOffersStart: (state) => { state.activeTokenOffers = getCommonStart({ token_offers: [], total_token_offers: 0 }) },
    fetchMoreActiveTokenOffersStart: (state) => state,
    fetchActiveTokenOffersSuccess: (state, action) => { state.activeTokenOffers = getCommonSuccess(action.payload) },
    fetchActiveTokenOffersFailure: (state, action) => { state.activeTokenOffers = getCommonFailure(action.payload) },

    amountConversionStart: (state) => { state.amountConversion = getCommonStart() },
    amountConversionSuccess: (state, action) => { state.amountConversion = getCommonSuccess(action.payload) },
    amountConversionFailure: (state, action) => { state.amountConversion = getCommonFailure(action.payload) },

    createTokenOfferStart: (state) => { state.createTokenOffer = getCommonStart() },
    createTokenOfferSuccess: (state, action) => { state.createTokenOffer = getCommonSuccess(action.payload) },
    createTokenOfferFailure: (state, action) => { state.createTokenOffer = getCommonFailure(action.payload) },

    fetchTokenOffersListStart: (state) => { state.tokenOffersList = getCommonStart({ token: [], total_token_offer: 0 }) },
    fetchMoreTokenOffersListStart: (state) => state,
    fetchTokenOffersListSuccess: (state, action) => { state.tokenOffersList = getCommonSuccess(action.payload) },
    fetchTokenOffersListFailure: (state, action) => { state.tokenOffersList = getCommonFailure(action.payload) },

    fetchOfferTransactionListStart: (state) => { state.offerTransactionList = getCommonStart({ offer_transactions: [], total_offer_transactions: 0 }) },
    fetchMoreOfferTransactionListStart: (state) => state,
    fetchOfferTransactionListSuccess: (state, action) => { state.offerTransactionList = getCommonSuccess(action.payload) },
    fetchOfferTransactionListFailure: (state, action) => { state.offerTransactionList = getCommonFailure(action.payload) },

    buyTokenStart: (state) => { state.buyToken = getCommonStart() },
    buyTokenSuccess: (state, action) => { state.buyToken = getCommonSuccess(action.payload) },
    buyTokenFailure: (state, action) => { state.buyToken = getCommonFailure(action.payload) },

    updateBuyTokenStatusStart: (state, action) => { state.updateBuyTokenStatus = getCommonStart(action.payload) },
    updateBuyTokenStatusSuccess: (state, action) => { state.updateBuyTokenStatus = getCommonSuccess(action.payload) },
    updateBuyTokenStatusFailure: (state, action) => { state.updateBuyTokenStatus = getCommonFailure(action.payload) },

    updateUsdtTokenStatusStart: (state, action) => { state.updateUsdtTokenStatus = getCommonStart(action.payload) },
    updateUsdtTokenStatusSuccess: (state, action) => { state.updateUsdtTokenStatus = getCommonSuccess(action.payload) },
    updateUsdtTokenStatusFailure: (state, action) => { state.updateUsdtTokenStatus = getCommonFailure(action.payload) },

    tokenOfferViewStart: (state) => { state.tokenOfferView = getCommonStart() },
    tokenOfferViewSuccess: (state, action) => { state.tokenOfferView = getCommonSuccess(action.payload) },
    tokenOfferViewFailure: (state, action) => { state.tokenOfferView = getCommonFailure(action.payload) },

    tokenOfferDeleteStart: (state, action) => { state.tokenOfferDelete = getCommonStart(action.payload) },
    tokenOfferDeleteSuccess: (state, action) => { state.tokenOfferDelete = getCommonSuccess(action.payload) },
    tokenOfferDeleteFailure: (state, action) => { state.tokenOfferDelete = getCommonFailure(action.payload) },

    updateBuyData: (state, action) => { state.buyData = action.payload ? action.payload : initialBuyData },

    //Offer feedback
    offerFeedbackListStart: (state) => { state.offerFeedbackList = getCommonStart({ user_feed_backs: [], total_user_feed_backs: 0 }) },
    moreOfferFeedbackListStart: (state) => { state.offerFeedbackList = state.offerFeedbackList },
    offerFeedbackListSuccess: (state, action) => { state.offerFeedbackList = getCommonSuccess(action.payload) },
    offerFeedbackListFailure: (state, action) => { state.offerFeedbackList = getCommonFailure(action.payload) },

  }
});

export const {
  fetchActiveTokenOffersStart,
  fetchMoreActiveTokenOffersStart,
  fetchActiveTokenOffersSuccess,
  fetchActiveTokenOffersFailure,
  amountConversionStart,
  amountConversionSuccess,
  amountConversionFailure,
  createTokenOfferStart,
  createTokenOfferSuccess,
  createTokenOfferFailure,
  fetchTokenOffersList,
  fetchMoreTokenOffersList,
  fetchTokenOffersListSuccess,
  fetchTokenOffersListFailure,
  fetchOfferTransactionListStart,
  fetchMoreOfferTransactionListStart,
  fetchOfferTransactionListSuccess,
  fetchOfferTransactionListFailure,
  buyTokenStart,
  buyTokenSuccess,
  buyTokenFailure,
  updateBuyTokenStatusStart,
  updateBuyTokenStatusSuccess,
  updateBuyTokenStatusFailure,
  updateUsdtTokenStatusStart,
  updateUsdtTokenStatusSuccess,
  updateUsdtTokenStatusFailure,
  tokenOfferViewStart,
  tokenOfferViewSuccess,
  tokenOfferViewFailure,
  tokenOfferDeleteFailure,
  tokenOfferDeleteStart,
  tokenOfferDeleteSuccess,
  updateBuyData,
  offerFeedbackListStart,
  moreOfferFeedbackListStart,
  offerFeedbackListSuccess,
  offerFeedbackListFailure,
  fetchTokenOffersListStart,
  fetchMoreTokenOffersListStart

} = OfferSlice.actions;

export default OfferSlice.reducer;