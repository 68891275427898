import { all, fork } from "redux-saga/effects";
import UserSaga from "./UserSaga";
import WalletSaga from "./WalletSaga";
import FeedbackSaga from "./FeedbackSaga";
import PageSaga from "./PageSaga";
import OfferSaga from "./OfferSaga";
import TraderSaga from "./TraderSaga";

export default function* rootSaga() {
  yield all([
    fork(UserSaga),
    fork(WalletSaga),
    fork(FeedbackSaga),
    fork(PageSaga),
    fork(OfferSaga),
    fork(TraderSaga),
  ]);
}