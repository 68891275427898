import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import { kycStatusUpdateStart } from "../../store/slices/UserSlice";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { Error } from "../Common/FormUtils";
import Dropzone from "react-dropzone";
import {
  adminWalletDetailsStart,
  sendAmountStart,
} from "../../store/slices/WalletSlice";
import PageLoader from "../Common/PageLoader";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";

const SendAmountModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [files, setFiles] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  const sendAmount = useSelector((state) => state.wallet.sendAmount);
  const profile = useSelector((state) => state.user.profile);
  const adminWalletDetails = useSelector(
    (state) => state.wallet.adminWalletDetails
  );

  const validationSchema = Yup.object().shape({
    total_tokens: Yup.string().required("Required"),
    transaction_hash: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    const data = values;
    if(files.length > 0) {
      data.picture = files[0];
    }
    dispatch(sendAmountStart({
      ...data,
      trader_unique_id: profile.data.reference_unique_id,
    }));
  };

  useEffect(() => {
    if (!skipRender && Object.keys(sendAmount.data).length > 0) {
      props.closeSendAmountModal();
    }
    setSkipRender(false);
  }, [sendAmount]);

  useEffect(() => {
    dispatch(adminWalletDetailsStart());
  }, []);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.sendAmountModal}
        onHide={props.closeSendAmountModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="send-amount-sec">
            {adminWalletDetails.loading ? (
              <svg
                width="50px"
                height="50px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="41"
                  stroke-width="8"
                  stroke="#41c3ec"
                  stroke-dasharray="64.40264939859075 64.40264939859075"
                  fill="none"
                  stroke-linecap="round"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    dur="1.0526315789473684s"
                    repeatCount="indefinite"
                    keyTimes="0;1"
                    values="0 50 50;360 50 50"
                  ></animateTransform>
                </circle>
                <circle
                  cx="50"
                  cy="50"
                  r="32"
                  stroke-width="8"
                  stroke="#3ade99"
                  stroke-dasharray="50.26548245743669 50.26548245743669"
                  stroke-dashoffset="50.26548245743669"
                  fill="none"
                  stroke-linecap="round"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    dur="1.0526315789473684s"
                    repeatCount="indefinite"
                    keyTimes="0;1"
                    values="0 50 50;-360 50 50"
                  ></animateTransform>
                </circle>
              </svg>
            ) : Object.keys(adminWalletDetails.data).length > 0 ? (
              <React.Fragment>
                <h6>Do you want to send money to admin?</h6>
                <CustomLazyLoad
                  className="qr-code-img"
                  src={adminWalletDetails.data.qr_code}
                  alt="qr_code"
                />
                <Formik
                  initialValues={{
                    total_tokens: "",
                    transaction_hash: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => handleSubmit(values)}
                >
                  {({ errors, touched, values }) => (
                    <FORM className="setting-form">
                      <div className="user-name-card">
                        <Form.Label>Admin Wallet Address</Form.Label>
                        <InputGroup className="mb-3">
                          <Field
                            className="form-control border-right"
                            name="language"
                            value={adminWalletDetails.data.wallet_address}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            className="border-left"
                          >
                            <Button className="edit-btn" type="submit">
                              Copy
                            </Button>
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Group className="mb-3">
                          <Form.Label>Total Tokens</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={"Enter tokens"}
                            name="total_tokens"
                            type="number"
                          />
                          <Error name="total_tokens" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Transaction Hash</Form.Label>
                          <Field
                            className="form-control"
                            placeholder={"Enter transaction hash"}
                            name="transaction_hash"
                          />
                          <Error name="transaction_hash" />
                        </Form.Group>
                        <div className="image-upload mb-3">
                          {files.length <= 0 && (
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                setFiles(
                                  acceptedFiles.map((file) =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                    })
                                  )
                                )
                              }
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <Form.Label>
                                    Enter Payment Screenshot
                                  </Form.Label>
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="utr-file-overlay">
                                      <p>
                                        {" "}
                                        <span>
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="26"
                                            height="26"
                                            fill="none"
                                            viewBox="0 0 26 26"
                                          >
                                            <path
                                              fill="#5C9EEB"
                                              d="M19.055 9.128a6.243 6.243 0 00-12.11-.007 6.25 6.25 0 00.586 12.473h2.344a.781.781 0 000-1.563H7.531a4.688 4.688 0 01-.027-9.375.812.812 0 00.86-.667 4.68 4.68 0 019.267 0 .843.843 0 00.838.667 4.687 4.687 0 110 9.375h-2.344a.781.781 0 100 1.563h2.344a6.25 6.25 0 00.586-12.466z"
                                            ></path>
                                            <path
                                              fill="#5C9EEB"
                                              d="M16.354 15.896a.781.781 0 001.105-1.105l-3.906-3.906a.781.781 0 00-1.105 0l-3.906 3.906a.781.781 0 001.104 1.105l2.573-2.573v9.833a.781.781 0 101.562 0v-9.833l2.573 2.573z"
                                            ></path>
                                          </svg>
                                        </span>{" "}
                                        Upload screenshot of the completed
                                        transaction
                                      </p>
                                    </div>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          )}
                          {files.length > 0 &&
                            files.map((file) => (
                              <div className="screenshot">
                                <Button onClick={() => setFiles([])}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M7.293 16.707a1 1 0 001.414 0L12 13.414l3.293 3.293a1 1 0 001.414-1.414L13.414 12l3.293-3.293a1 1 0 10-1.414-1.414L12 10.586 8.707 7.293a1 1 0 10-1.414 1.414L10.586 12l-3.293 3.293a1 1 0 000 1.414z"></path>
                                  </svg>
                                </Button>
                                <Image src={file.preview} />
                              </div>
                            ))}
                        </div>
                        <div className="btn-sec">
                          <Button
                            type="submit"
                            className="default-btn w-100"
                            disabled={sendAmount.buttonDisable}
                          >
                            {sendAmount.buttonDisable ? "Loading..." : "Send"}
                          </Button>
                        </div>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </React.Fragment>
            ) : (
              <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <Image src={window.location.origin + "/assets/img/error.png"} alt="no-data" className="mb-3"/>
                <p>Sorry, Admin Wallet Details Not Found</p>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(SendAmountModal);
