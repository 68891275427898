import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import { Formik, Field, Form as FORM } from "formik";
import * as Yup from "yup";
import { Error } from "../Common/FormUtils";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";

const ChangeEmailForm = (props) => {
  const t = useTranslation();

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("invalid_email"))
            .required(t("required"))
            .notOneOf([props.profile.data.email], t("email_already_using")),
        })}
        onSubmit={(values) => { props.handleChangeCredentials(values); }}
      >
        {({ errors, touch, values }) =>
          <FORM>
            <div className="change-number-step-1">
              <div className="change-number-info">
                <h4>Step - 1</h4>
                <Form.Group className="mb-3">
                  <Form.Label>Enter the email to get the otp</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("enter_your_email")}
                    name="email"
                  />
                  <Error name="email" />
                </Form.Group>
              </div>
              <div className="send-otp-btn-sec">
                <Button
                  type="submit"
                  className="next-btn w-100"
                  disabled={props.updateProfileCredentials.buttonDisable}
                >
                  {props.updateProfileCredentials.buttonDisable ?
                    t("loading")
                    : t("send_verification_code")
                  }
                </Button>
              </div>
            </div>
          </FORM>
        }
      </Formik>
    </>
  );
};

const mapStateToPros = (state) => ({
  updateProfileCredentials: state.user.updateProfileCredentials,
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ChangeEmailForm));