import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import "./Buy.css";
import { Link, useNavigate } from "react-router-dom";
import TransferPaymentModal from "./TransferPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  moreOfferFeedbackListStart,
  offerFeedbackListStart,
  tokenOfferViewStart,
  updateBuyData,
} from "../../../store/slices/OfferSlice";
import CenterLoader from "../../Common/CenterLoader";
import { ErrorMsg } from "../../Common/FormUtils";
import PageLoader from "../../Common/PageLoader";
import NoDataFound from "../../Common/NoDataFound";
import { useTranslation, withTranslation } from "react-multi-lang";
import Select from "react-select";

const BuyIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const tokenOfferView = useSelector((state) => state.offer.tokenOfferView);
  const offerFeedbackList = useSelector(
    (state) => state.offer.offerFeedbackList
  );
  const profile = useSelector((state) => state.user.profile);
  const buyData = useSelector((state) => state.offer.buyData);
  const setBuyData = (data) => dispatch(updateBuyData(data));

  const [skipRender, setSkipRender] = useState(true);
  const [error, setError] = useState({});
  const [transferPayment, setTransferPayment] = useState(false);
  const [feedbackType, setFeedbackType] = useState("1");

  const options = [
    { label: "UPI", value: "UPI" },
    { label: "NEFT", value: "NEFT" },
    { label: "IMPS", value: "IMPS" },
    { label: "RTGS", value: "RTGS" },
  ];

  useEffect(() => {
    if (!buyData.token_offer_unique_id) navigate("/list");
    else {
      dispatch(
        tokenOfferViewStart({
          token_offer_unique_id: buyData.token_offer_unique_id,
        })
      );
      fetchFeedBack();
    }
  }, []);

  useEffect(() => {
    if (!skipRender) fetchFeedBack();
  }, [feedbackType]);

  useEffect(() => {
    if (!skipRender && !tokenOfferView.loading)
      if (Object.keys(tokenOfferView.data).length > 0)
        setBuyData({
          ...buyData,
          token_type: tokenOfferView.data.project_token,
          tokens: buyData.spendAmount / tokenOfferView.data.single_token_rate,
          order_id: Math.floor(100000 + Math.random() * 900000),
          payment_method: "UPI"
        });
      else navigate("/list");
    setSkipRender(false);
  }, [tokenOfferView]);

  const fetchFeedBack = () => {
    dispatch(
      offerFeedbackListStart({
        token_offer_unique_id: buyData.token_offer_unique_id,
        feedback_type: feedbackType,
      })
    );
  };

  const fetchMoreFeedback = () => {
    dispatch(
      moreOfferFeedbackListStart({
        token_offer_unique_id: buyData.token_offer_unique_id,
        feedback_type: feedbackType,
        skip: offerFeedbackList.data.user_feed_backs.length,
        take: 12,
      })
    );
  };

  const closeTransferPaymentModal = () => {
    setTransferPayment(false);
    if (!buyData.token_offer_unique_id) navigate("/list");
  };

  const onComplete = (e) => {
    e.preventDefault();
    let error = {};
    if (buyData.spendAmount < tokenOfferView.data.min_tokens)
      error = {
        ...error,
        tokens: "Min " + tokenOfferView.data.min_tokens + " INR worth tokens",
      };
    else if (buyData.spendAmount > tokenOfferView.data.max_tokens)
      error = {
        ...error,
        tokens: "Max " + tokenOfferView.data.max_tokens + " INR worth tokens",
      };
    else if (buyData.tokens > tokenOfferView.data.remaining_tokens)
      error = {
        ...error,
        tokens: "Only " + tokenOfferView.data.remaining_tokens + " tokens are remaining in the offer",
      };

    if (Object.keys(error).length === 0) setTransferPayment(true);
    setError(error);
  };

  return (
    <>
      {tokenOfferView.loading ? (
        <div className="d-flex justify-content-center">
          <CenterLoader />
        </div>
      ) : tokenOfferView.data.token_offer_id ? (
        <div className="buy-body-sec">
          <div className="buy-sec">
          <div className="vertical-center">
            <Container>
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <div className="banner-box">
                      <div className="banner-content">
                        <h1>
                          {t("trade_bitcoin_with")}
                          <br></br>
                          {t("nearbuycoins")}
                        </h1>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <div className="how-much-you-want-to-buy-card">
                      <h3>{t("how_much_do_you_want_to_spend")}</h3>
                      <Form className="sell-form">
                        <Row className="mb-3">
                          <Col md={6}>
                            <Form.Group>
                              <Form.Label>{t("i_want_to_buy")}</Form.Label>
                              <InputGroup className="mb-0">
                                <Form.Control
                                  placeholder={t("enter_amount")}
                                  value={buyData.spendAmount}
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    if (Number(val) == val) {
                                      setBuyData({
                                        ...buyData,
                                        spendAmount: val,
                                        tokens:
                                          val /
                                          tokenOfferView.data.single_token_rate,
                                      });
                                    }
                                    e.preventDefault();
                                  }}
                                />
                                <InputGroup.Text id="basic-addon1">
                                  {t("inr")}
                                </InputGroup.Text>
                              </InputGroup>

                              {/* <div className="error-sec-1">
                                <Image
                                  className="coversion-icon"
                                  src={
                                    window.location.origin + "/assets/img/auth/info-icon.svg"
                                  }
                                />
                                Enter Amount to get started
                              </div> */}
                            </Form.Group>
                          </Col>
                          <Col md={6} className="resp-marg-btm-xs">
                            <Form.Label>{t("and_recieve")}</Form.Label>
                            <InputGroup className="mb-0">
                              <Form.Control
                                readOnly={true}
                                placeholder={t("you_will_recieve")}
                                value={buyData.tokens}
                              />
                              <InputGroup.Text id="basic-addon1">
                                {buyData.token_type?.token}
                              </InputGroup.Text>
                            </InputGroup>
                            {error.tokens ? (
                              <ErrorMsg msg={error.tokens} />
                            ) : null}
                          </Col>
                        </Row>
                        {tokenOfferView.data.token_type == "USDT" && (
                          <Row>
                            <Col md={6}>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Payment Method</Form.Label>
                                <Select
                                  placeholder={t("select")}
                                  options={options}
                                  value={
                                    buyData.payment_method
                                      ? {
                                          label: buyData.payment_method,
                                          value: buyData.payment_method,
                                        }
                                      : {}
                                  }
                                  onChange={(val) =>
                                    setBuyData({
                                      ...buyData,
                                      payment_method: val.value,
                                    })
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col md={12}>
                            <div className="buy-btn-sec">
                              <Button
                                className="default-btn w-100"
                                onClick={onComplete}
                              >
                                {t("purchase")}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
            </Container>
            </div>
          </div>
          <div className="about-this-offer-sec">
            <Container>
              <Row className="justify-content-center">
                <Col md={10} xl={9} lg={9}>
                  <div className="buy-about-this-offer-box">
                    <h3>{t("about_this_offer")}</h3>
                    <div className="buy-about-this-offer-card">
                      <div className="buy-about-this-offer-item">
                        <p>{t("seller_rate")}</p>
                        <h5>
                          <strong>
                            1 {buyData.token_type?.token} ={" "}
                            {tokenOfferView.data.single_token_rate} INR
                          </strong>
                        </h5>
                      </div>
                      <div className="buy-about-this-offer-item">
                        <p>{t("buy_limits")}</p>
                        <h5>
                          {t("min")}{" "}
                          <strong>{tokenOfferView.data.min_tokens}</strong> .
                          Max <strong>{tokenOfferView.data.max_tokens}</strong>{" "}
                          INR
                        </h5>
                      </div>
                      <div className="buy-about-this-offer-item">
                        <p>{t("trade_time_limit")}</p>
                        <h5>
                          <strong>
                            {tokenOfferView.data.offer_time_limit} Min
                          </strong>
                        </h5>
                      </div>
                      <div className="buy-about-this-offer-item">
                        <p>{t("nearbuycoins_fee")}</p>
                        <h5>
                          <strong>0%</strong>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="about-this-buyer-sec">
            <Container>
              <Row className="justify-content-center">
                <Col md={10} xl={9} lg={9}>
                  <div className="about-this-buyer-box">
                    <h3>{t("about_this_buyer")}</h3>
                    <div className="about-this-buyer-card">
                      <Image
                        className="about-this-user-img"
                        src={tokenOfferView.data.user.picture}
                      />
                      <div className="about-this-buyer-user-info">
                        <Link
                          to={`/profile/${tokenOfferView.data.user.user_unique_id}`}
                          className="about-this-user-name"
                        >
                          {tokenOfferView.data.user.name}
                        </Link>
                        <p>
                          <Image
                            className="about-this-buyer-user-seen-icon"
                            src={
                              window.location.origin +
                              "/assets/img/profile/eye-icon.svg"
                            }
                          />
                          {tokenOfferView.data.user.last_seen}
                        </p>
                      </div>
                      <div className="about-this-buyer-feedback-box">
                        <div className="about-this-buyer-feedback-card">
                          <div className="about-this-buyer-feedback-count">
                            <h5>
                              +
                              {
                                tokenOfferView.data.user
                                  .positive_feed_backs_count
                              }
                            </h5>
                            <p>Positive Feedback</p>
                          </div>
                          <Image
                            className="about-this-buyer-feedback-icon"
                            src={
                              window.location.origin +
                              "/assets/img/buy/like-icon.svg"
                            }
                          />
                        </div>
                        <div className="about-this-buyer-feedback-card">
                          <div className="about-this-buyer-feedback-count">
                            <h5>
                              +
                              {
                                tokenOfferView.data.user
                                  .negative_feed_backs_count
                              }
                            </h5>
                            <p>{t("negative_feedback")}</p>
                          </div>
                          <Image
                            className="about-this-buyer-feedback-icon"
                            src={
                              window.location.origin +
                              "/assets/img/buy/dislike.svg"
                            }
                          />
                        </div>
                      </div>
                      <div className="about-this-buyer-verified-box">
                        {/* <div className="about-this-buyer-verified-card">
                            Address Verified
                            <span>
                              <Image
                                className="about-this-buyer-verified-tick-icon"
                                src={
                                  window.location.origin + "/assets/img/profile/tick-mark-icon.svg"
                                }
                              />
                            </span>
                          </div> */}
                        {tokenOfferView.data.user.is_email_verified == 1 ? (
                          <div className="about-this-buyer-verified-card">
                            {t("email_verified")}
                            <span>
                              <Image
                                className="about-this-buyer-verified-tick-icon"
                                src={
                                  window.location.origin +
                                  "/assets/img/profile/tick-mark-icon.svg"
                                }
                              />
                            </span>
                          </div>
                        ) : null}
                        {tokenOfferView.data.user.is_mobile_verified == 1 ? (
                          <div className="about-this-buyer-verified-card">
                            {t("phone_verify")}
                            <span>
                              <Image
                                className="about-this-buyer-verified-tick-icon"
                                src={
                                  window.location.origin +
                                  "/assets/img/profile/tick-mark-icon.svg"
                                }
                              />
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <div className="trade-time-limit-btn-sec">
                        <p>
                          {t("trade_time_limit")} -
                          <span>
                            {tokenOfferView.data.offer_time_limit} Min
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          {tokenOfferView.data.offer_terms ? (
            <div className="offers-terms-sec">
              <Container>
                <Row className="justify-content-center">
                  <Col md={10} xl={9} lg={9}>
                    <div className="about-this-buyer-box">
                      <h3>{t("offer_terms")}</h3>
                      <p>{tokenOfferView.data.offer_terms}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : null}
          {tokenOfferView.data.trade_instructions ? (
            <div className="trade-instruction-sec">
              <Container>
                <Row className="justify-content-center">
                  <Col md={10} xl={9} lg={9}>
                    <div className="about-this-buyer-box">
                      <h3>{t("trade_instructions")}</h3>
                      <p>{tokenOfferView.data.trade_instructions}</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          ) : null}
          <div className="buy-feedback-box">
            <Container>
              <Row className="justify-content-center">
                <Col md={10} xl={9} lg={9}>
                  <div className="buy-feedback-sec">
                    <Tab.Container
                      id="left-tabs-example"
                      activeKey={feedbackType}
                    >
                      <div className="buy-feedback-header-sec">
                        <div className="buy-feedback-left-sec">
                          <h3>{t("feedback")}</h3>
                          <div className="buy-feedback-tab-nav-sec">
                            <Nav variant="pills">
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="1"
                                  onClick={() => setFeedbackType("1")}
                                >
                                  {t("positive")}
                                  {/* <span>1</span> */}
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey="2"
                                  onClick={() => setFeedbackType("2")}
                                >
                                  {t("negative")}
                                  {/* <span>1</span> */}
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                      </div>
                      <div className="buy-feedback-tab-content-sec">
                        {offerFeedbackList.loading ? (
                          <PageLoader />
                        ) : offerFeedbackList.data.user_feed_backs.length >
                          0 ? (
                          <div className="feedback-box">
                            {offerFeedbackList.data.user_feed_backs.map(
                              (feedback, i) => (
                                <div className="feedback-card" key={i}>
                                  <div className="feedback-user-details">
                                    <div className="feedback-user-img-sec">
                                      <Image src={feedback.from_user.picture} />
                                      <div className="status-online"></div>
                                    </div>
                                    <div className="feedback-user-info">
                                      <h4>{feedback.from_user.name}</h4>
                                      <p>Seen 7 min ago</p>
                                      <div className="feedback-liked-user">
                                        <Image
                                          className="coversion-icon"
                                          src={
                                            feedback.feedback_type == 1
                                              ? window.location.origin +
                                                "/assets/img/profile/like-icon-fill.svg"
                                              : window.location.origin +
                                                "/assets/img/profile/dislike.svg"
                                          }
                                        />
                                        {/* <span>
                                            245
                                          </span> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="feedback-user-info">
                                    <p>{feedback.note}</p>
                                  </div>
                                </div>
                              )
                            )}
                            {offerFeedbackList.data.user_feed_backs.length <
                            offerFeedbackList.data.total_user_feed_backs ? (
                              <Row className="d-flex justify-content-center">
                                <Button
                                  className="primary w-auto"
                                  onClick={() => fetchMoreFeedback()}
                                >
                                  Load More
                                </Button>
                              </Row>
                            ) : null}
                          </div>
                        ) : (
                          <NoDataFound />
                        )}
                      </div>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      ) : null}
      {transferPayment &&
      !profile.loading &&
      Object.keys(profile.data).length > 0 ? (
        <TransferPaymentModal
          closeTransferPaymentModal={closeTransferPaymentModal}
          profile={profile.data}
        />
      ) : null}
    </>
  );
};

export default withTranslation(BuyIndex);
