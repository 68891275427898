import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  traderOfferList: getCommonInitialState(),
  traderOfferBuy: getCommonInitialState(),
};

const TraderSlice = createSlice({
  name: "trader",
  initialState,
  reducers: {

    // Trader Offer List
    traderOfferListStart: (state) => { state.traderOfferList = getCommonStart() },
    moreTraderOfferListStart: (state) => { state.traderOfferList = getCommonStart() },
    traderOfferListSuccess: (state, action) => { state.traderOfferList = getCommonSuccess(action.payload) },
    traderOfferListFailure: (state, action) => { state.traderOfferList = getCommonFailure(action.payload) },

    // Trader Offer Buy
    traderOfferBuyStart: (state) => { state.traderOfferBuy = getCommonStart() },
    traderOfferBuySuccess: (state, action) => { state.traderOfferBuy = getCommonSuccess(action.payload) },
    traderOfferBuyFailure: (state, action) => { state.traderOfferBuy = getCommonFailure(action.payload) },

  }
});

export const {
  traderOfferBuyFailure,
  traderOfferBuyStart,
  traderOfferBuySuccess,
  traderOfferListFailure,
  traderOfferListStart,
  traderOfferListSuccess,
  moreTraderOfferListStart,
} = TraderSlice.actions;

export default TraderSlice.reducer;