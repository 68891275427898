import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./List.css";
import Select, { components } from "react-select";
import MgIcon from "./bnb-icon.svg";
import Rupee from "./rupees.svg";
import { Link } from "react-router-dom";

const CustomSelect = (props) => {

  const coins = [
    { value: "BNB", label: "BNB", icon: MgIcon },
    { value: "ETH", label: "ETH", icon: Rupee }
  ];
  
  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <img src={props.data.icon} alt="logo" className="country-logo" />
      {props.data.label}
    </components.Option>
  );

  const [selectedCountry, setSelectedCountry] = useState(coins[0]);

  const handleChange = (value) => {
    setSelectedCountry(value);
  };

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={selectedCountry.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden"
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 120,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: '#F5F5F5!important',
      border:'0!important',
      boxShadow: 'none',
      height:'45px',

    }),
    singleValue: provided => ({ ...provided, color: '#989CA1',  display: "flex",
    alignItems: "center",
    gap:"0.5em", fontSize:"1.1em", fontWeight:"600" }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#4b4b4b!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
    ///.....
  }

  return (
    <>
      <Select
        value={selectedCountry}
        options={coins}
        onChange={handleChange}
        styles={
        customStyles}
        components={{
        Option,
        SingleValue
        }}
        // menuIsOpen={true}
        isSearchable={false}
    />
    </>
  );
};

export default CustomSelect;
