import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { tokenOfferDeleteStart } from "../../../store/slices/OfferSlice";

const DeleteVerificationModal = (props) => {
  const t = useTranslation();

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !props.tokenOfferDelete.buttonDisable && Object.keys(props.tokenOfferDelete.data).length > 0) {
      props.closeModal();
    }
    setSkipRender(false);
  }, [props.tokenOfferDelete]);

  const handleDelete = () => {
    props.dispatch(tokenOfferDeleteStart({
      token_offer_unique_id: props.verification,
    }));
  }

  return (
    <>
      <Modal
        className="modal-dialog-center change-number-modal"
        size="md"
        centered
        show={props.verification}
        onHide={props.closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("delete_offer")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="setting-form" onSubmit={e => {
            e.preventDefault();
            handleDelete();
          }}>
            <div className="change-number-box">
              <div className="change-number-step-1">
                <div className="change-number-info">
                  <h4>{t("verification")}</h4>
                  <p>{t("are_you_sure_to_delete_this_offer")}</p>
                  <p>({props.verification})</p>
                </div>
                <div style={{display:"flex", gap:"1em"}}>
                  <Button
                    type="button"
                    className="default-btn w-100"
                    onClick={()=> props.closeModal()}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className="default-btn w-100"
                    disabled={props.tokenOfferDelete.buttonDisable}
                  >
                    {props.tokenOfferDelete.buttonDisable ?
                      t("loading")
                      : t("confirm")
                    }
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  tokenOfferDelete: state.offer.tokenOfferDelete,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(DeleteVerificationModal));