import React from "react";
import Select, { components } from "react-select";

const CustomSelect = ({ icon, options = [], value, onChange = () => { } }) => {

  const Option = (props) => (
    <components.Option {...props} className="country-option">
      <img src={props.data[icon]} alt="logo" className="country-logo" />
      {props.data.label}
    </components.Option>
  );

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={value ? value[icon] : ""} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden"
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 120,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: '#fff',
      borderColor: '#4b4b4b',
      boxShadow: 'none',
      height: '45px',

    }),
    singleValue: provided => ({
      ...provided, color: '#4b4b4b', display: "flex",
      alignItems: "center",
      gap: "0.5em",
    }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#4b4b4b!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
    ///.....
  }

  return (
    <>
      <Select
        value={value}
        options={options}
        onChange={onChange}
        styles={customStyles}
        components={{
          Option,
          SingleValue
        }}
        // menuIsOpen={true}
        isSearchable={false}
      />
    </>
  );
}

export default CustomSelect;