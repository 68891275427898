import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button, Table } from "react-bootstrap";
import "./Profile.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreReceivedFeedbackListStart, fetchReceivedFeedbackListStart } from "../../../store/slices/FeedbackSlice";
import PageLoader from "../../Common/PageLoader";
import SomethingWentWrong from "../../Common/SomethingWentWrong";
import NoDataFound from "../../Common/NoDataFound";
import { useTranslation, withTranslation } from 'react-multi-lang';

const FeedBackIndex = ({ isInfinite = false, ...props }) => {
  const dispatch = useDispatch();
  const t =useTranslation();
  const receivedFeedbackList = useSelector(state => state.feedback.receivedFeedbackList);


  useEffect(() => {
    dispatch(fetchReceivedFeedbackListStart({
      username: props.username,
      skip: 0,
      take: isInfinite ? 12 : 6
    }));
  }, [props.username]);

  const fetchMoreFeedback = () => {
    dispatch(fetchMoreReceivedFeedbackListStart({
      username: props.username,
      skip: props.activeTokenOffers.data.token_offers.length,
      take: 12,
    }));
  }

  return (
    <>
      <div className="active-offers-sec feedback">
        <Tab.Container id="left-tabs-example" defaultActiveKey="feedback-buy-crypto">
          <div className="active-offers-header-sec">
            <div className="active-offers-left-sec">
              <h3>{t("feedback")}</h3>
              {/* <div className="active-offers-tab-nav-sec">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="feedback-buy-crypto">
                      Positive
                      {/* <span>1</span> *
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="feedback-sell-crypto">Negative
                      {/* <span>1</span> *
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div> */}
            </div>
          </div>
          <div className="active-offers-tab-content-sec ">
            {receivedFeedbackList.loading ?
              <PageLoader />
              : receivedFeedbackList.data.user_feed_backs ?
                receivedFeedbackList.data.user_feed_backs.length > 0 ?
                  <div className="feedback-box">
                    {receivedFeedbackList.data.user_feed_backs.map((feedback) =>
                      <div className="feedback-card">
                        <div className="feedback-user-details">
                          <div className="feedback-user-img-sec">
                            <Image src={feedback.from_user.picture} />
                            <div className="status-online"></div>
                          </div>
                          <div className="feedback-user-info">
                            <h4>{feedback.from_user.name}</h4>
                            <p>{feedback.from_user.last_seen_formatted}</p>
                            <div className="feedback-liked-user">
                              <Image
                                className="coversion-icon"
                                src={feedback.feedback_type == 1 ?
                                  window.location.origin + "/assets/img/profile/like-icon-fill.svg"
                                  : window.location.origin + "/assets/img/profile/dislike.svg"
                                }
                              />
                              {/* <span>
                                245
                              </span> */}
                            </div>
                          </div>
                        </div>
                        <div className="feedback-user-info">
                          <p>{feedback.note}</p>
                        </div>
                      </div>
                    )}
                  </div>
                  : <NoDataFound />
                : <SomethingWentWrong />
            }
          </div>
        </Tab.Container>
      </div>
    </>
  );
};

export default withTranslation(FeedBackIndex);
