import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import {
  kycApplicantStart,
} from "../../store/slices/UserSlice";
import { updateBuyTokenStatusStart } from "../../store/slices/OfferSlice";
import { useTranslation, withTranslation } from 'react-multi-lang';

const ApproveTransactionModal = (props) => {
  const dispatch = useDispatch();
  const [transactionHash, setTransactionHash] = useState();
  const [skipRender, setSkipRender] = useState(true);
  const t = useTranslation();

  const profile = useSelector((state) => state.user.profile);
  const updateBuyTokenStatus = useSelector(state => state.offer.updateBuyTokenStatus);

  const handleSubmit = () => {
    if (props.transaction.token_type == "USDT" || transactionHash) {
      dispatch(
        updateBuyTokenStatusStart({
          transaction_hash: transactionHash,
          offer_transaction_id: props.transaction.offer_transaction_id,
          status: 3,
        })
      );
    }
  };

  useEffect(()=> {
    console.log(props.transaction);
    if(!skipRender && !updateBuyTokenStatus.loading && Object.keys(updateBuyTokenStatus.data).length>0)
      props.closeTransaction();
    setSkipRender(false)
  },[updateBuyTokenStatus]);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.transaction}
        onHide={props.closeTransaction}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("approve_transaction")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="auth-form mt-3">
            <Col sm={12}>
              <div className="aadhar-card-verification-sec">
                <Row>
                  <Col md={12}>
                    {props.transaction.token_type == "USDT" ? (
                      <p>Are you sure to approve this transaction?</p>
                    ) : (
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{}</Form.Label>
                      <Form.Control
                        className="form-control"
                        type="text"
                        name="transaction_hash"
                        placeholder={t("enter_the_hash")}
                        onChange={(e) => setTransactionHash(e.target.value)}
                        required
                      />
                    </Form.Group>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="kyc-btn-sec">
                      <Button
                        className="next-btn"
                        onClick={handleSubmit}
                        disabled={updateBuyTokenStatus.buttonDisable}
                      >
                        {updateBuyTokenStatus.buttonDisable?"Loading":"Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(ApproveTransactionModal);
