import React from 'react';
import { Image } from 'react-bootstrap';

const NoDataFound = () => {
  return (
    <div className='page-loader-container'>
      <div className='something-went-wrong-sec'>
        <Image
          style={{maxWidth:"300px"}}
          src={window.location.origin + "/assets/img/no-data.png"}
          className="mb-3"
        />
        <h3 style={{margin:"auto", marginTop:"1em"}}>No Data Found</h3>
      </div>
    </div>
  );
}

export default NoDataFound;