import UserSlice from "./UserSlice";
import WalletSlice from "./WalletSlice";
import FeedbackSlice from "./FeedbackSlice";
import PageSlice from './PageSlice';
import OfferSlice from "./OfferSlice";
import TraderSlice from "./TraderSlice";

export const reducers = {
    user: UserSlice,
    wallet: WalletSlice,
    feedback: FeedbackSlice,
    page: PageSlice,
    offer: OfferSlice,
    trader: TraderSlice,
};