import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OurClientIndex = (props) => {

    return (
        <>
            <div className="our-client-sec">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={9}>
                            <div className="section-title">
                                <h2>Stories from Our Community</h2>
                                <p>Discover How Our Platform Benefits Traders, Straight from Our Clients' Perspectives</p>
                            </div>
                        </Col>
                    </Row>
                   <Row>
                    <Col md={12}>
                        <div className="your-experience-box">
                            <Row className="justify-content-center">
                                <Col md={8}>
                                    <Image
                                        className="your-experience-img"
                                        src={
                                            window.location.origin + "/assets/img/landing-page/clients-sec.png"
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                   </Row>
                </Container>
            </div>
        </>
    );
};

export default OurClientIndex;
