import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getCommonSaga } from '../sagaUtils';
import store from '../index';
import {
  fetchSinglePageFailure,
  fetchSinglePageSuccess
} from '../slices/PageSlice';

function* fetchSinglePageAPI(action) {
  yield getCommonSaga({
    apiUrl: "static_pages_view",
    payload: action.payload,
    successNotify: false,
    success: fetchSinglePageSuccess,
    failure: fetchSinglePageFailure,
  });
}

export default function* PageSaga() {
  yield all([
    yield takeLatest('page/fetchSinglePageStart', fetchSinglePageAPI),
  ]);
}