import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import "./Buy.css";
import { Link, useNavigate } from "react-router-dom";
import TransferPaymentModal from "./TransferPaymentModal";
import { useDispatch, useSelector } from "react-redux";
import {
  moreOfferFeedbackListStart,
  offerFeedbackListStart,
  tokenOfferViewStart,
  updateBuyData,
} from "../../../store/slices/OfferSlice";
import CenterLoader from "../../Common/CenterLoader";
import { ErrorMsg } from "../../Common/FormUtils";
import PageLoader from "../../Common/PageLoader";
import NoDataFound from "../../Common/NoDataFound";
import { useTranslation, withTranslation } from "react-multi-lang";
import Select from "react-select";
import TraderPaymentModal from "./TraderPaymentModal";

const TraderBuyIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const profile = useSelector((state) => state.user.profile);
  const buyData = useSelector((state) => state.offer.buyData);
  const setBuyData = (data) => dispatch(updateBuyData(data));

  const [error, setError] = useState({});
  const [transferPayment, setTransferPayment] = useState(false);

  const options = [
    { label: "UPI", value: "UPI" },
    { label: "NEFT", value: "NEFT" },
    { label: "IMPS", value: "IMPS" },
    { label: "RTGS", value: "RTGS" },
  ];

  useEffect(() => {
    if (!buyData.trader_unique_id) navigate("/list");
    else {
      setBuyData({
        ...buyData,
        // token_type: "USDT",
        tokens: buyData.spendAmount / buyData.trader_exchange_value,
        order_id: Math.floor(100000 + Math.random() * 900000),
        payment_method: "UPI"
      });
    }
  }, []);

  const closeTransferPaymentModal = () => {
    setTransferPayment(false);
    navigate("/list");
  };

  const onComplete = (e) => {
    e.preventDefault();
    let error = {};
    if (buyData.spendAmount > buyData.offer_amount)
      error = {
        ...error,
        tokens: "Max " + buyData.offer_amount + " INR worth tokens",
      };
    else if(buyData.spendAmount < 1000)
      error = {
        ...error,
        tokens: "Min 1000 INR worth tokens",
      };

    if (Object.keys(error).length === 0) setTransferPayment(true);
    setError(error);
  };

  return (
    <>
      <div className="buy-body-sec">
        <div className="buy-sec">
        <div className="vertical-center">
          <Container>
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="banner-box">
                    <div className="banner-content">
                      <h1>
                        {t("trade_bitcoin_with")}
                        <br></br>
                        {t("nearbuycoins")}
                      </h1>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col md={12}>
                  <div className="how-much-you-want-to-buy-card">
                    <h3>{t("how_much_do_you_want_to_spend")}</h3>
                    <Form className="sell-form">
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>{t("i_want_to_buy")}</Form.Label>
                            <InputGroup className="mb-0">
                              <Form.Control
                                placeholder={t("enter_amount")}
                                value={buyData.spendAmount}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (Number(val) == val) {
                                    setBuyData({
                                      ...buyData,
                                      spendAmount: val,
                                      tokens:
                                        val /
                                        buyData.trader_exchange_value,
                                    });
                                  }
                                  e.preventDefault();
                                }}
                              />
                              <InputGroup.Text id="basic-addon1">
                                {t("inr")}
                              </InputGroup.Text>
                            </InputGroup>

                            {/* <div className="error-sec-1">
                              <Image
                                className="coversion-icon"
                                src={
                                  window.location.origin + "/assets/img/auth/info-icon.svg"
                                }
                              />
                              Enter Amount to get started
                            </div> */}
                          </Form.Group>
                        </Col>
                        <Col md={6} className="resp-marg-btm-xs">
                          <Form.Label>{t("and_recieve")}</Form.Label>
                          <InputGroup className="mb-0">
                            <Form.Control
                              readOnly={true}
                              placeholder={t("you_will_recieve")}
                              value={buyData.tokens}
                            />
                            <InputGroup.Text id="basic-addon1">
                              {buyData.token_type?.token}
                            </InputGroup.Text>
                          </InputGroup>
                          {error.tokens ? (
                            <ErrorMsg msg={error.tokens} />
                          ) : null}
                        </Col>
                      </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Payment Method</Form.Label>
                              <Select
                                placeholder={t("select")}
                                options={options}
                                value={
                                  buyData.payment_method
                                    ? {
                                        label: buyData.payment_method,
                                        value: buyData.payment_method,
                                      }
                                    : {}
                                }
                                onChange={(val) =>
                                  setBuyData({
                                    ...buyData,
                                    payment_method: val.value,
                                  })
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      <Row>
                        <Col md={12}>
                          <div className="buy-btn-sec">
                            <Button
                              className="default-btn w-100"
                              onClick={onComplete}
                            >
                              {t("purchase")}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
          </Container>
          </div>
        </div>
      </div>
      {transferPayment &&
      !profile.loading &&
      Object.keys(profile.data).length > 0 ? (
        <TraderPaymentModal
          closeTransferPaymentModal={closeTransferPaymentModal}
          profile={profile.data}
        />
      ) : null}
    </>
  );
};

export default withTranslation(TraderBuyIndex);
