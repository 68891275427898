import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { resetPasswordStart } from "../../store/slices/UserSlice";
import { Error, PasswordField } from "../Common/FormUtils";
import configuration from 'react-global-configuration';

const ResetPassword = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")),
    password_confirmation: Yup.string().required(t("required")).when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], t("both_password_should_same"))
    }),
  });

  const handleResetPassword = (values) => {
    props.dispatch(resetPasswordStart({
      ...values,
      reset_token: props.verifyForgotPassword.data.token,
    }));
  }

  useEffect(() => {
    if (Object.keys(props.verifyForgotPassword.data).length == 0) {
      navigate("/forgot-password");
    }
  }, [props.verifyForgotPassword]);

  useEffect(() => {
    if (!skipRender && !props.resetPassword.loading && Object.keys(props.resetPassword.data).length > 0) {
      navigate("/login");
    }
    setSkipRender(false);
  }, [props.resetPassword]);


  return (
    <>
      <section className="auth-sec">
      <div className="vertical-center">
        <Container>
            <Row className="align-items-center">
              <Col md={7}>
                <div className="auth-img-sec">
                  <Image
                    className="auth-img"
                    src={
                      window.location.origin + "/assets/img/auth/login-img.png"
                    }
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className="auth-box">
                  <div className="auth-logo-sec">
                    <Image
                      className="auth-logo-img"
                      src={configuration.get("configData.site_dark_logo")}
                    />
                  </div>
                  <h3>{t("new_password")}</h3>
                  <Formik
                    initialValues={{
                      passowrd: "",
                      password_confirmation: "",
                    }}
                    validationSchema={resetPasswordSchema}
                    onSubmit={(values) => handleResetPassword(values)}
                  >
                    {({ touched, errors }) =>
                      <FORM className="auth-form">
                        <Form.Group className="mb-3">
                          <Form.Label>{t("new_password")}</Form.Label>
                          <PasswordField
                            placeholder={t("create_password_placeholder")}
                            name="password"
                          />
                          <Error name="password" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>{t("confirm_password")}</Form.Label>
                          <PasswordField
                            placeholder={t("confirm_password_placeholder")}
                            name="password_confirmation"
                          />
                          <Error name="password_confirmation" />
                        </Form.Group>
                        <div className="auth-btn-sec">
                          <Button
                            type="submit"
                            className="default-btn w-100"
                            disabled={props.resetPassword.buttonDisable}
                          >
                            {props.resetPassword.buttonDisable ?
                              t("loading")
                              : t("verify")
                            }
                          </Button>
                        </div>
                      </FORM>
                    }
                  </Formik>

                  {/* <div className="divider"></div>
                  <div className="auth-footer-sec">
                    <Link to="/login">{t("back_to_login")}</Link>
                  </div> */}
                </div>
              </Col>
            </Row>
        </Container>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  verifyForgotPassword: state.user.verifyForgotPassword,
  resetPassword: state.user.resetPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ResetPassword));
