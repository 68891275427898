import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  login: getCommonInitialState(),
  register: getCommonInitialState(),
  userVerification: getCommonInitialState(),
  resendUserVerification: getCommonInitialState(),
  twoStepAuth: getCommonInitialState(),
  resendTwoStepAuth: getCommonInitialState(),
  forgotPassword: getCommonInitialState(),
  resendForgotPassword: getCommonInitialState(),
  verifyForgotPassword: getCommonInitialState(),
  resetPassword: getCommonInitialState(),
  profile: getCommonInitialState(),
  updateProfile: getCommonInitialState(),
  updateTwoStep: getCommonInitialState(),
  changePassword: getCommonInitialState(),
  usernameValidation: getCommonInitialState(),
  updateProfileCredentials: getCommonInitialState(),
  validateUpdateCredentials: getCommonInitialState(),
  updateProfileUPI: getCommonInitialState(),
  deleteAccount: getCommonInitialState(),
  updateNotification: getCommonInitialState(),
  otherUser: getCommonInitialState(),
  kycApplicant: getCommonInitialState(),
  kycStatusUpdate: getCommonInitialState(),
  becomeASeller: getCommonInitialState(),
  userBillingsList: getCommonInitialState(),
  userBillingsSave: getCommonInitialState(),
  updateExchangeValue: getCommonInitialState(),
};

const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {

    //Login
    userLoginStart: (state) => { state.login = getCommonStart() },
    userLoginSuccess: (state, action) => { state.login = getCommonSuccess(action.payload) },
    userLoginFailure: (state, action) => { state.login = getCommonFailure(action.payload) },

    //Registration
    userRegisterStart: (state, action) => { state.register = getCommonStart() },
    userRegisterSuccess: (state, action) => { state.register = getCommonSuccess(action.payload) },
    userRegisterFailure: (state, action) => { state.register = getCommonFailure(action.payload) },

    //User Verification
    userVerificationStart: (state, action) => { state.userVerification = getCommonStart() },
    userVerificationSuccess: (state, action) => { state.userVerification = getCommonSuccess(action.payload) },
    userVerificationFailure: (state, action) => { state.userVerification = getCommonFailure(action.payload) },

    //User Verification code resend
    resendUserVerificationStart: (state, action) => { state.resendUserVerification = getCommonStart() },
    resendUserVerificationSuccess: (state, action) => { state.resendUserVerification = getCommonSuccess(action.payload) },
    resendUserVerificationFailure: (state, action) => { state.resendUserVerification = getCommonFailure(action.payload) },

    //Two step authendication
    twoStepAuthStart: (state, action) => { state.twoStepAuth = getCommonStart() },
    twoStepAuthSuccess: (state, action) => { state.twoStepAuth = getCommonSuccess(action.payload) },
    twoStepAuthFailure: (state, action) => { state.twoStepAuth = getCommonFailure(action.payload) },

    //Resend Two step code
    resendTwoStepStart: (state, action) => { state.resendTwoStepAuth = getCommonStart() },
    resendTwoStepSuccess: (state, action) => { state.resendTwoStepAuth = getCommonSuccess(action.payload) },
    resendTwoStepFailure: (state, action) => { state.resendTwoStepAuth = getCommonFailure(action.payload) },

    //Forgot password
    forgotPasswordStart: (state, action) => { state.forgotPassword = getCommonStart() },
    forgotPasswordSuccess: (state, action) => { state.forgotPassword = getCommonSuccess(action.payload) },
    forgotPasswordFailure: (state, action) => { state.forgotPassword = getCommonFailure(action.payload) },

    //Resend Forgot password OTP
    resendForgotPasswordStart: (state, action) => { state.resendForgotPassword = getCommonStart() },
    resendForgotPasswordSuccess: (state, action) => { state.resendForgotPassword = getCommonSuccess(action.payload) },
    resendForgotPasswordFailure: (state, action) => { state.resendForgotPassword = getCommonFailure(action.payload) },

    //Verify Forgot password
    verifyForgotPasswordStart: (state, action) => { state.verifyForgotPassword = getCommonStart() },
    verifyForgotPasswordSuccess: (state, action) => { state.verifyForgotPassword = getCommonSuccess(action.payload) },
    verifyForgotPasswordFailure: (state, action) => { state.verifyForgotPassword = getCommonFailure(action.payload) },

    //Reset Password
    resetPasswordStart: (state, action) => { state.resetPassword = getCommonStart() },
    resetPasswordSuccess: (state, action) => { state.resetPassword = getCommonSuccess(action.payload) },
    resetPasswordFailure: (state, action) => { state.resetPassword = getCommonFailure(action.payload) },

    //Profile
    fetchUserDetailsStart: (state, action) => { state.profile = getCommonStart() },
    fetchUserDetailsSuccess: (state, action) => { state.profile = getCommonSuccess(action.payload) },
    fetchUserDetailsFailure: (state, action) => { state.profile = getCommonFailure(action.payload) },

    //Update Profile
    updateUserDetailsStart: (state, action) => { state.updateProfile = getCommonStart() },
    updateUserDetailsSuccess: (state, action) => { state.updateProfile = getCommonSuccess(action.payload) },
    updateUserDetailsFailure: (state, action) => { state.updateProfile = getCommonFailure(action.payload) },

    //Update Twostep Auth
    updateTwoStepAuthStart: (state, action) => { state.updateTwoStep = getCommonStart() },
    updateTwoStepAuthSuccess: (state, action) => { state.updateTwoStep = getCommonSuccess(action.payload) },
    updateTwoStepAuthFailure: (state, action) => { state.updateTwoStep = getCommonFailure(action.payload) },

    //Change Password
    changePasswordStart: (state, action) => { state.changePassword = getCommonStart() },
    changePasswordSuccess: (state, action) => { state.changePassword = getCommonSuccess(action.payload) },
    changePasswordFailure: (state, action) => { state.changePassword = getCommonFailure(action.payload) },

    //Username Validation 
    usernameValidationStart: (state, action) => { state.usernameValidation = getCommonStart() },
    usernameValidationSuccess: (state, action) => { state.usernameValidation = getCommonSuccess(action.payload) },
    usernameValidationFailure: (state, action) => { state.usernameValidation = getCommonFailure(action.payload) },

    //Uppdate Profile Credentials (Email & Mobile)
    updateProfileCredentialsStart: (state, action) => { state.updateProfileCredentials = getCommonStart() },
    updateProfileCredentialsSuccess: (state, action) => { state.updateProfileCredentials = getCommonSuccess(action.payload) },
    updateProfileCredentialsFailure: (state, action) => { state.updateProfileCredentials = getCommonFailure(action.payload) },

    // Validate OTP for profile credentials update (Email & Mobile)
    validateUpdateCredentialsStart: (state, action) => { state.validateUpdateCredentials = getCommonStart() },
    validateUpdateCredentialsSuccess: (state, action) => { state.validateUpdateCredentials = getCommonSuccess(action.payload) },
    validateUpdateCredentialsFailure: (state, action) => { state.validateUpdateCredentials = getCommonFailure(action.payload) },

    //Update Profile UPI
    updateProfileUPIStart: (state, action) => { state.updateProfileUPI = getCommonStart() },
    updateProfileUPISuccess: (state, action) => { state.updateProfileUPI = getCommonSuccess(action.payload) },
    updateProfileUPIFailure: (state, action) => { state.updateProfileUPI = getCommonFailure(action.payload) },

    //Delete user account
    deleteAccountStart: (state, action) => { state.deleteAccount = getCommonStart() },
    deleteAccountSuccess: (state, action) => { state.deleteAccount = getCommonSuccess(action.payload) },
    deleteAccountFailure: (state, action) => { state.deleteAccount = getCommonFailure(action.payload) },

    //Update Notification settings
    updateNotificationStart: (state, action) => { state.updateNotification = getCommonStart() },
    updateNotificationSuccess: (state, action) => { state.updateNotification = getCommonSuccess(action.payload) },
    updateNotificationFailure: (state, action) => { state.updateNotification = getCommonFailure(action.payload) },

    //Other User Profile
    fetchOtherUserDetailsStart: (state, action) => { state.otherUser = getCommonStart() },
    fetchOtherUserDetailsSuccess: (state, action) => { state.otherUser = getCommonSuccess(action.payload) },
    fetchOtherUserDetailsFailure: (state, action) => { state.otherUser = getCommonFailure(action.payload) },

    // Create kyc applicant
    kycApplicantStart: (state, action) => { state.kycApplicant = getCommonStart() },
    kycApplicantSuccess: (state, action) => { state.kycApplicant = getCommonSuccess(action.payload) },
    kycApplicantFailure: (state, action) => { state.kycApplicant = getCommonFailure(action.payload) },

    // Update kyc status
    kycStatusUpdateStart: (state, action) => { state.kycStatusUpdate = getCommonStart() },
    kycStatusUpdateSuccess: (state, action) => { state.kycStatusUpdate = getCommonSuccess(action.payload) },
    kycStatusUpdateFailure: (state, action) => { state.kycStatusUpdate = getCommonFailure(action.payload) },

    // User Billings List
    userBillingsListStart: (state, action) => { state.userBillingsList = getCommonStart() },
    userBillingsListSuccess: (state, action) => { state.userBillingsList = getCommonSuccess(action.payload) },
    userBillingsListFailure: (state, action) => { state.userBillingsList = getCommonFailure(action.payload) },

    // User Billings Save
    userBillingsSaveStart: (state, action) => { state.userBillingsSave = getCommonStart() },
    userBillingsSaveSuccess: (state, action) => { state.userBillingsSave = getCommonSuccess(action.payload) },
    userBillingsSaveFailure: (state, action) => { state.userBillingsSave = getCommonFailure(action.payload) },

    // Update kyc status
    becomeASellerStart: (state, action) => { state.becomeASeller = getCommonStart() },
    becomeASellerSuccess: (state, action) => { state.becomeASeller = getCommonSuccess(action.payload) },
    becomeASellerFailure: (state, action) => { state.becomeASeller = getCommonFailure(action.payload) },

    // Update Exchange Value
    updateExchangeValueStart: (state, action) => { state.updateExchangeValue = getCommonStart() },
    updateExchangeValueSuccess: (state, action) => { state.updateExchangeValue = getCommonSuccess(action.payload) },
    updateExchangeValueFailure: (state, action) => { state.updateExchangeValue = getCommonFailure(action.payload) },

  }
});

export const {
  userLoginStart,
  userLoginSuccess,
  userLoginFailure,
  userRegisterStart,
  userRegisterSuccess,
  userRegisterFailure,
  userVerificationStart,
  userVerificationSuccess,
  userVerificationFailure,
  resendUserVerificationStart,
  resendUserVerificationSuccess,
  resendUserVerificationFailure,
  twoStepAuthStart,
  twoStepAuthSuccess,
  twoStepAuthFailure,
  resendTwoStepStart,
  resendTwoStepSuccess,
  resendTwoStepFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resendForgotPasswordStart,
  resendForgotPasswordSuccess,
  resendForgotPasswordFailure,
  verifyForgotPasswordStart,
  verifyForgotPasswordSuccess,
  verifyForgotPasswordFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  fetchUserDetailsStart,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  updateUserDetailsStart,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  updateTwoStepAuthStart,
  updateTwoStepAuthSuccess,
  updateTwoStepAuthFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  usernameValidationStart,
  usernameValidationSuccess,
  usernameValidationFailure,
  updateProfileCredentialsStart,
  updateProfileCredentialsSuccess,
  updateProfileCredentialsFailure,
  validateUpdateCredentialsStart,
  validateUpdateCredentialsSuccess,
  validateUpdateCredentialsFailure,
  updateProfileUPIStart,
  updateProfileUPISuccess,
  updateProfileUPIFailure,
  deleteAccountStart,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateNotificationStart,
  updateNotificationSuccess,
  updateNotificationFailure,
  fetchOtherUserDetailsStart,
  fetchOtherUserDetailsSuccess,
  fetchOtherUserDetailsFailure,
  kycApplicantFailure,
  kycApplicantStart,
  kycApplicantSuccess,
  kycStatusUpdateFailure,
  kycStatusUpdateStart,
  kycStatusUpdateSuccess,
  becomeASellerFailure,
  becomeASellerStart,
  becomeASellerSuccess,
  userBillingsListFailure,
  userBillingsListStart,
  userBillingsListSuccess,
  userBillingsSaveFailure,
  userBillingsSaveStart,
  userBillingsSaveSuccess,
  updateExchangeValueFailure,
  updateExchangeValueStart,
  updateExchangeValueSuccess,

} = UserSlice.actions;

export default UserSlice.reducer;