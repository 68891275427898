import React, { useState, useEffect } from 'react';
import { Image, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateBuyData } from '../../store/slices/OfferSlice';
import { kycApplicantStart } from '../../store/slices/UserSlice';

const SingleOfferRow = ({ activeOffer, i, setKyc }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);

  const buyData = useSelector(state => state.offer.buyData);
  const profile = useSelector(state => state.user.profile);
  const kycApplicant = useSelector((state) => state.user.kycApplicant);

  const onBuy = e => {
    e.preventDefault();
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.kyc_verified === 0) {
        dispatch(kycApplicantStart());
      }
      else {
        dispatch(updateBuyData({
          ...buyData,
          token_offer_unique_id: activeOffer.token_offer_unique_id,
        }));
        navigate("/buy")
      };
    } else navigate('/login')
  }

  useEffect(() => {
    if (!skipRender && !kycApplicant.loading && Object.keys(kycApplicant.data).length > 0) {
      setKyc(true);
    }
    setSkipRender(false);
  }, [kycApplicant]);

  return (
    <tr key={i}>
      <td>
        <div className="list-user-details">
          <div className="list-user-img-sec">
            {/* <p>P</p> */}
            <Image src={activeOffer.user.picture} />
            <div className="status-online"></div>
          </div>
          <div className="list-user-info">
            <h4>{activeOffer.user.name}</h4>
            <p>{activeOffer.user.last_seen_formatted}</p>
            {/* <div className="list-liked-user">
              <Image
                className="coversion-icon"
                src={
                  window.location.origin + "/assets/img/list/like-icon.svg"
                }
              />
              <span>
                245
              </span>
            </div> */}
          </div>
        </div>
      </td>
      <td>
        <div className="list-price-details">
          <div className="price-amount">
            {activeOffer.total_tokens}
            {/* 20.24k <span>USD</span> */}
          </div>
          <div className="price-convertion-amount">
            <p>1 {activeOffer.token_type} = {activeOffer.single_token_rate} INR</p>
            {/* <p>1 USD = 1.03 USD of BTC</p> */}
          </div>
          {/* <div className="price-convertion-percentage">
            <Image
              className="price-convertion-percentage-icon"
              src={
                window.location.origin + "/assets/img/list/arrow-down-icon.svg"
              }
            />
            <span>2%</span>
            <Image
              className="price-convertion-percentage-icon"
              src={
                window.location.origin + "/assets/img/list/info-icon.svg"
              }
            />
          </div> */}
        </div>
      </td>
      <td>
        <div className="limit-transaction-amount">
          <h5>Available <span>{activeOffer.remaining_tokens}</span></h5>
          <h5>Limit <span>{activeOffer.limit}</span></h5>
        </div>
      </td>
      <td>
        <div className="list-transfer-speed">
          1 Minute
        </div>
      </td>
      {/* <td>
        <div className="payment-type-sec">
          UPI
        </div>
      </td> */}
      <td>
        <div className="list-action-btn-sec">
          {/* <p> Your Offer</p> */}
          {profile.data.user_id != activeOffer.user.user_id ?
            <Button disabled={kycApplicant.buttonDisable} className="buy-btn" onClick={onBuy}>
              BUY
            </Button>
            :
            null
          }
        </div>
      </td>
    </tr>
  );
}

export default SingleOfferRow;