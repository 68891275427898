import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import CustomSelect from "../Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  traderOfferListStart,
  moreTraderOfferListStart,
} from "../../store/slices/TraderSlice";
import NoDataFound from "../Common/NoDataFound";
import CenterLoader from "../Common/CenterLoader";
import PageLoader from "../Common/PageLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import SomethingWentWrong from "../Common/SomethingWentWrong";
import configuration from "react-global-configuration";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import { useTranslation } from "react-multi-lang";

const TraderOffersIndex = () => {
  const dispatch = useDispatch();
  const traderOfferList = useSelector((state) => state.trader.traderOfferList);
  const profile = useSelector((state) => state.user.profile);
  const [kyc, setKyc] = useState(false);

  const closeKycModal = () => {
    setKyc(false);
  };

  useEffect(() => {
    dispatch(traderOfferListStart({reference_id: profile.data.reference_id}));
  }, []);

  const fetchMoreOffers = () => {
    dispatch(
      moreTraderOfferListStart({
        reference_id: profile.data.reference_id,
        skip: traderOfferList.data.trader_offers.length,
        take: 12,
      })
    );
  };

  return (
    <>
      <div className="list-sec">
        <Container>
          <Row>
            <Col md={12}>
              {traderOfferList.loading ? (
                <PageLoader />
              ) : traderOfferList.data.sellers ? (
                traderOfferList.data.sellers.length > 0 ? (
                  <>
                    <div className="list-title-sec">Trader Offers</div>
                    <div className="list-table-sec">
                      <InfiniteScroll
                        dataLength={traderOfferList.data.sellers.length}
                        next={fetchMoreOffers}
                        hasMore={
                          traderOfferList.data.sellers.length <
                          traderOfferList.data.total_sellers
                        }
                        loader={
                          <div className="d-flex justify-content-center mb-5">
                            <CenterLoader />
                          </div>
                        }
                      >
                        <Table>
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Trader ID</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Amount</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {traderOfferList.data.sellers.map(
                              (activeOffer, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{activeOffer.trader_unique_id}</td>
                                  <td>{activeOffer.name}</td>
                                  <td>{activeOffer.email}</td>
                                  <td>{activeOffer.offer_amount_formatted}</td>
                                  <td><button className="default-btn">Buy</button></td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </>
                ) : (
                  <NoDataFound />
                )
              ) : (
                <SomethingWentWrong />
              )}
            </Col>
          </Row>
        </Container>
      </div>
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
    </>
  );
};

export default TraderOffersIndex;
