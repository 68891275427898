import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Dropdown,
  Button,
  Table,
} from "react-bootstrap";
import "../Account/Profile/Profile.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  fetchActiveTokenOffersStart,
  fetchMoreActiveTokenOffersStart,
  updateBuyData,
} from "../../store/slices/OfferSlice";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import PageLoader from "../Common/PageLoader";
import NoDataFound from "../Common/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import CenterLoader from "../Common/CenterLoader";
import { moreAdminPaymentDetailsStart } from "../../store/slices/WalletSlice";
import Skeleton from "react-loading-skeleton";

const WalletPayments = ({ isInfinite = true, ...props }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const buyData = useSelector((state) => state.offer.buyData);
  const profile = useSelector((state) => state.user.profile);
  const adminPaymentDetails = useSelector(
    (state) => state.wallet.adminPaymentDetails
  );

  const fetchMoreData = () => {
    dispatch(
      moreAdminPaymentDetailsStart({
        trader_unique_id: profile.data.reference_unique_id,
        skip: adminPaymentDetails.data.trader_payments.length,
        take: 12,
      })
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-pending";
      case 2:
        return "payment-approved";
      case 3:
        return "payment-rejected";
      default:
        return "payment-pending";
    }
  }

  return (
    <>
      <div className="active-offers-sec">
        <div className="update-wallet-header-sec mt-3">
          <div className="active-offers-left-sec">
            <h3>Wallet Payments</h3>
          </div>
          {/* {isInfinite ? (
            <div className="active-offers-right-sec">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="list-action-btn"
                >
                  <Image
                    className="coversion-icon"
                    src={
                      window.location.origin + "/assets/img/list/sort-icon.svg"
                    }
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    {t("price_lowest_to_highest")}
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    {t("price_highest_to_lowest")}
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    {t("avg_trade_speed_fastest_to_slowest")}
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-3">
                    {t("avg_trade_speed_slowest_to_fastest")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : null} */}
        </div>
        {adminPaymentDetails.loading ? (
          <Skeleton height={70} count={3} style={{margin:"1em 0"}} />
        ) : Object.keys(adminPaymentDetails.data).length > 0 &&
          adminPaymentDetails.data.trader_payments.length > 0 ? (
          <div className="active-offers-tab-content-sec">
            <div className="active-offers-list-table">
              <div className="list-table-sec">
                <InfiniteScroll
                  dataLength={adminPaymentDetails.data.trader_payments.length}
                  next={fetchMoreData}
                  hasMore={
                    adminPaymentDetails.data.trader_payments.length <
                      adminPaymentDetails.data.total_payments && isInfinite
                  }
                  loader={
                    <div className="d-flex justify-content-center mb-5">
                      <Skeleton height={60} count={3} />
                    </div>
                  }>
                  <Table>
                    <tbody>
                      <tr>
                        <td>S. No.</td>
                        <td>Payment ID</td>
                        <td>Transaction Hash</td>
                        <td>Total Tokens</td>
                        <td>Your Amount</td>
                        <td>Total Amount</td>
                        <td>Exchange Value</td>
                        <td>Status</td>
                      </tr>
                      {adminPaymentDetails.data.trader_payments.map(
                        (item, i) => (
                          <tr key={i}>
                            <td>
                              <div className="list-user-details">
                                <div className="list-user-info">
                                  <p>{i + 1}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="list-transfer-speed">
                                {item.transaction_id}
                              </div>
                            </td>
                            <td>
                              <div className="list-transfer-speed">
                                {item.transaction_hash}
                              </div>
                            </td>
                            <td>
                              <div className="list-transfer-speed">
                                {item.total_tokens}
                              </div>
                            </td>
                            <td>
                              <div className="list-transfer-speed">
                                {item.trader_amount}
                              </div>
                            </td>
                            <td>
                              <div className="list-transfer-speed">
                                {item.total_amount}
                              </div>
                            </td>
                            <td>
                              <div className="list-transfer-speed">
                                {item.exchange_value}
                              </div>
                            </td>
                            <td>
                              <div className={getStatusClass(item.status)}>
                                {item.status_formatted}
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        ) : (
          <NoDataFound />
        )}
      </div>
    </>
  );
};

export default WalletPayments;
