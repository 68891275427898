import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import "./Settings.css";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ChangeNumberModal from "./ChangeNumberModal";
import PageLoader from "../Common/PageLoader";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import GeneralSettings from "./GeneralSettings";
import {
  fetchUserDetailsStart,
  updateExchangeValueStart,
  updateProfileUPIStart,
  updateUserDetailsStart,
  usernameValidationStart,
} from "../../store/slices/UserSlice";
import Reload from "../Common/Reload";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { Error } from "../Common/FormUtils";
import NotificationPreferences from "./NotificationPreferences";
import SomethingWentWrong from "../Common/SomethingWentWrong";
import BankAccountsSection from "./BankAccountsSection";

const SettingsIndex = (props) => {
  const t = useTranslation();

  const [isUpdating, setIsUpdating] = useState(false);
  const [lastUsername, setLastUsername] = useState();
  const [skipRender, setSkipRender] = useState(true);
  const [value, setValue] = useState();
  const [changeNumber, setChangeNumber] = useState(false);

  const closeChangeNumberModal = () => {
    setChangeNumber(false);
  };

  useEffect(() => {
    if (!skipRender && !props.updateProfile.loading) {
      setIsUpdating(false);
    }
    setSkipRender(false);
  }, [props.updateProfile]);

  useEffect(() => {
    if (lastUsername) {
      props.dispatch(usernameValidationStart({ username: lastUsername }));
    }
  }, [lastUsername]);

  const validateUsername = (value) => {
    if (!value) {
      return "Required";
    } else {
      if (lastUsername !== value) {
        setLastUsername(value);
      }
    }
    return "";
  };

  const handleUpdateProfile = (values) => {
    props.dispatch(updateUserDetailsStart(values));
  };

  const handleUpdateUPI = (values) => {
    props.dispatch(updateProfileUPIStart(values));
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: isUpdating === "image",
    noDrag: isUpdating === "image",
    onDrop: (files) => {
      if (files.length > 0) {
        console.log(files[0]);
        setIsUpdating("image");
        handleUpdateProfile({ picture: files[0] });
      }
    },
    accept: {
      "image/jpeg": [".jpeg", ".jpg", ".png"],
    },
  });

  return (
    <>
      {props.profile.loading ? (
        <PageLoader />
      ) : props.profile.data.user_id ? (
        <div className="setting-sec">
          <Container>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="account-setting"
            >
              <Row>
                <Col sm={12}>
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="account-setting">
                        {t("profile_settings")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="general-setting">
                        {t("general_settings")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="notification-preferences">
                        {t("notification_preferences")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="account-setting">
                      <div className="account-setting-box setting-form">
                        <h3>{t("profile_settings")}</h3>
                        <Row className="justify-content-md-center margin-top-xl">
                          <Col md={2}>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="user-profile-card">
                                <CustomLazyLoad
                                  className="user-profile-icon"
                                  src={props.profile.data.picture}
                                  placeholderSrc={
                                    window.location.origin +
                                    "/assets/img/profile/user.png"
                                  }
                                />
                                {isUpdating === "image" ? (
                                  <div className="user-profile-reload-container">
                                    <Reload width={30} height={30} />
                                  </div>
                                ) : null}
                                <div className="user-camera-icon-sec">
                                  <Image
                                    className="user-camera-icon"
                                    src={
                                      window.location.origin +
                                      "/assets/img/settings/camera-icon.svg"
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="user-profile-name-card">
                              <Formik
                                initialValues={{
                                  username: props.profile.data.username,
                                }}
                                validationSchema={Yup.object().shape({
                                  username: Yup.string().required("Required"),
                                })}
                                onSubmit={(values) => {
                                  setIsUpdating("username");
                                  handleUpdateProfile(values);
                                }}
                              >
                                {({ errors, touched, values }) => (
                                  <FORM>
                                    <div className="user-name-card">
                                      <Form.Label>User Name</Form.Label>
                                      <InputGroup className="mb-3">
                                        <Field
                                          className="form-control border-right"
                                          placeholder={t("enter_your_username")}
                                          name="username"
                                          validate={validateUsername}
                                          autoComplete="off"
                                        />
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="border-left"
                                        >
                                          <Button
                                            className="edit-btn"
                                            type="submit"
                                            disabled={
                                              props.updateProfile
                                                .buttonDisable ||
                                              !props.usernameValidation.data
                                                .isAvailable ||
                                              values.username.trim() ===
                                                props.profile.data.username
                                            }
                                          >
                                            {props.updateProfile
                                              .buttonDisable &&
                                            isUpdating === "username"
                                              ? t("saving")
                                              : t("save")}
                                          </Button>
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <Error name="username" />
                                      {!errors.username &&
                                      values.username !==
                                        props.profile.data.username ? (
                                        props.usernameValidation.loading ? (
                                          <div className="success-sec mt-2">
                                            <Reload width={20} height={20} />
                                          </div>
                                        ) : props.usernameValidation.data
                                            .isAvailable ? (
                                          <div className="success-sec mt-2">
                                            <Image
                                              className="coversion-icon"
                                              src={
                                                window.location.origin +
                                                "/assets/img/auth/check-tick.svg"
                                              }
                                            />
                                            {"Username is available."}
                                          </div>
                                        ) : (
                                          <div className="error-sec mt-2">
                                            <Image
                                              className="coversion-icon"
                                              src={
                                                window.location.origin +
                                                "/assets/img/auth/info-icon.svg"
                                              }
                                            />
                                            {
                                              props.usernameValidation.data
                                                .message
                                            }
                                          </div>
                                        )
                                      ) : null}
                                      {/* <p>You can change your username <span>only once</span></p> */}
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="user-profile-name-card">
                              <Formik
                                initialValues={{
                                  name: props.profile.data.name,
                                }}
                                validationSchema={Yup.object().shape({
                                  name: Yup.string().required("Required"),
                                })}
                                onSubmit={(values) => {
                                  setIsUpdating("name");
                                  handleUpdateProfile(values);
                                }}
                              >
                                {({ errors, touched, values }) => (
                                  <FORM>
                                    <div className="user-name-card">
                                      <Form.Label>Name</Form.Label>
                                      <InputGroup className="mb-3">
                                        <Field
                                          className="form-control border-right"
                                          placeholder={t("enter_your_name")}
                                          name="name"
                                        />
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="border-left"
                                        >
                                          <Button
                                            className="edit-btn"
                                            type="submit"
                                            disabled={
                                              props.updateProfile
                                                .buttonDisable ||
                                              values.name.trim() ===
                                                props.profile.data.name
                                            }
                                          >
                                            {props.updateProfile
                                              .buttonDisable &&
                                            isUpdating === "name"
                                              ? t("saving")
                                              : t("save")}
                                          </Button>
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <Error name="username" />
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </div>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center margin-top-row">
                          <Col md={2}></Col>
                          <Col md={5}>
                            <Form.Group className="mb-3">
                              {props.profile.data.email ? (
                                <>
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control
                                    placeholder={t("enter_your_email_id")}
                                    disabled={true}
                                    value={props.profile.data.email}
                                  />
                                </>
                              ) : null}
                              <Button
                                className="change-number-btn"
                                onClick={() => setChangeNumber("Email")}
                              >
                                {props.profile.data.email
                                  ? "Change Email"
                                  : "Add Email"}
                              </Button>
                            </Form.Group>
                          </Col>
                          <Col md={5}>
                            <Form.Group className="mb-3">
                              {props.profile.data.mobile ? (
                                <>
                                  <Form.Label>Phone Number</Form.Label>
                                  <PhoneInput
                                    placeholder={t("enter_phone_number")}
                                    value={
                                      props.profile.data.country_code +
                                      props.profile.data.mobile
                                    }
                                    onChange={() => {}}
                                    disabled={true}
                                    country={"in"}
                                  />
                                </>
                              ) : null}
                              <Button
                                className="change-number-btn"
                                onClick={() => setChangeNumber("Number")}
                              >
                                {props.profile.data.mobile
                                  ? t("change_number")
                                  : t("add_number")}
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="justify-content-md-center margin-top-row">
                          <Col md={2}></Col>
                          <Col md={5}>
                            <div className="user-profile-name-card">
                              <Formik
                                initialValues={{
                                  language: props.profile.data.language,
                                }}
                                validationSchema={Yup.object().shape({
                                  language: Yup.string().required("Required"),
                                })}
                                onSubmit={(values) => {
                                  setIsUpdating("language");
                                  handleUpdateProfile(values);
                                }}
                              >
                                {({ errors, touched, values }) => (
                                  <FORM>
                                    <div className="user-name-card">
                                      <Form.Label>Language</Form.Label>
                                      <InputGroup className="mb-3">
                                        <Field
                                          className="form-control border-right"
                                          placeholder={t("enter_your_language")}
                                          name="language"
                                        />
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="border-left"
                                        >
                                          <Button
                                            className="edit-btn"
                                            type="submit"
                                            disabled={
                                              props.updateProfile
                                                .buttonDisable ||
                                              values.language.trim() ===
                                                props.profile.data.language
                                            }
                                          >
                                            {props.updateProfile
                                              .buttonDisable &&
                                            isUpdating === "language"
                                              ? t("saving")
                                              : t("save")}
                                          </Button>
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <Error name="language" />
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="user-profile-name-card">
                              <Formik
                                initialValues={{
                                  location: "",
                                }}
                                validationSchema={Yup.object().shape({
                                  location: Yup.string().required("Required"),
                                })}
                                onSubmit={(values) => {
                                  setIsUpdating("location");
                                  handleUpdateProfile(values);
                                }}
                              >
                                {({ errors, touched, values }) => (
                                  <FORM>
                                    <div className="user-name-card">
                                      <Form.Label>Location</Form.Label>
                                      <InputGroup className="mb-3">
                                        <Field
                                          className="form-control border-right"
                                          placeholder={t("enter_your_location")}
                                          name="location"
                                        />
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="border-left"
                                        >
                                          <Button
                                            className="edit-btn"
                                            type="submit"
                                            disabled={
                                              props.updateProfile
                                                .buttonDisable ||
                                              values.location.trim() ===
                                                props.profile.data.location
                                            }
                                          >
                                            {props.updateProfile
                                              .buttonDisable &&
                                            isUpdating === "location"
                                              ? t("saving")
                                              : t("save")}
                                          </Button>
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <Error name="location" />
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="upi-details-box">
                        <h4>{t("upi_information")}</h4>
                        <Row className="justify-content-md-start margin-top-xl">
                          <Col md={2}>
                            <div className="user-upi-img-sec">
                              <Image
                                className="user-upi-img"
                                src={
                                  window.location.origin +
                                  "/assets/img/settings/upi-img.png"
                                }
                              />
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="user-profile-name-card user-profile-upi">
                              <Formik
                                initialValues={{
                                  upi_id: props.profile.data.upi_id
                                    ? props.profile.data.upi_id
                                    : "",
                                }}
                                validationSchema={Yup.object().shape({
                                  upi_id: Yup.string().required("Required"),
                                })}
                                onSubmit={(values) => {
                                  handleUpdateUPI(values);
                                }}
                              >
                                {({ errors, touched, values }) => (
                                  <FORM>
                                    <div className="user-name-card">
                                      <div className="flex-label">
                                        <Form.Label>
                                          {t("upi_label")}
                                        </Form.Label>
                                        <Form.Label className="text-blue">
                                          <a
                                            href="/page/how-to-find-upi-id"
                                            target="_blank"
                                          >
                                            {t("how_to_find_upi_id")}
                                          </a>
                                        </Form.Label>
                                      </div>
                                      <InputGroup className="mb-3">
                                        <InputGroup.Text
                                          id="basic-addon1"
                                          className="border-right"
                                        >
                                          <Image
                                            className="coversion-icon"
                                            src={
                                              window.location.origin +
                                              "/assets/img/landing-page/icons/bhim.png"
                                            }
                                          />
                                        </InputGroup.Text>
                                        <Field
                                          className="form-control"
                                          placeholder={t("upi_placeholders")}
                                          name="upi_id"
                                        />
                                        <InputGroup.Text id="basic-addon1">
                                          <Button
                                            className="edit-btn"
                                            type="submit"
                                            disabled={
                                              props.updateProfileUPI
                                                .buttonDisable ||
                                              values.upi_id.trim() ===
                                                props.profile.data.upi_id ||
                                              values.upi_id.trim() === ""
                                            }
                                          >
                                            {props.updateProfileUPI
                                              .buttonDisable
                                              ? t("saving")
                                              : t("save")}
                                          </Button>
                                        </InputGroup.Text>
                                      </InputGroup>
                                      <Error name="upi_id" />
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </div>
                          </Col>
                          <Col md={5}>
                            <BankAccountsSection />
                          </Col>
                          {/* <Col md={5}>
                              <div className="user-profile-name-card">
                                <Formik
                                  initialValues={{
                                    upi_id: props.profile.data.upi_id ? props.profile.data.upi_id : "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    upi_id: Yup.string().required("Required"),
                                  })}
                                  onSubmit={(values) => {
                                    handleUpdateUPI(values);
                                  }}
                                >
                                  {({ errors, touched, values }) =>
                                    <FORM>
                                      <div className="user-name-card">
                                        <div className="flex-label">
                                          <Form.Label>Enter UPI ID</Form.Label>
                                          <Form.Label className="text-blue"><Link to="#">How to find UPI ID ?</Link></Form.Label>
                                        </div>
                                        <InputGroup className="mb-3">
                                          <Field
                                            className="form-control"
                                            placeholder="Vidya5678"
                                            name="upi_id"
                                          />
                                          <InputGroup.Text id="basic-addon1">
                                            <Button
                                              className="edit-btn"
                                              type="submit"
                                              disabled={
                                                props.updateProfileUPI.buttonDisable ||
                                                values.upi_id.trim() === props.profile.data.upi_id ||
                                                values.upi_id.trim() === ""
                                              }
                                            >
                                              {props.updateProfileUPI.buttonDisable ?
                                                t("saving")
                                                : t("save")
                                              }
                                            </Button>
                                          </InputGroup.Text>
                                        </InputGroup>
                                        <Error name="upi_id" />
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                          <div className="error-sec">
                                            <Image
                                              className="coversion-icon"
                                              src={
                                                window.location.origin + "/assets/img/auth/info-icon.svg"
                                              }
                                            />
                                            Your UPI is incorrect
                                          </div>
                                        </Form.Group> 
                                      </div>
                                    </FORM>
                                  }
                                </Formik>
                              </div>
                            </Col> */}
                        </Row>
                      </div>
                      <div className="upi-details-box setting-form">
                        <h4>{t("exchange_value")}</h4>
                        <Row className="justify-content-md-start margin-top-xl">
                          <Col md={8}>
                            <div className="user-profile-name-card mb-3">
                              <Formik
                                initialValues={{
                                  exchange_value:
                                    props.profile.data.exchange_value,
                                }}
                                validationSchema={Yup.object().shape({
                                  exchange_value: Yup.string()
                                    .required("Required")
                                })}
                                onSubmit={(values) => props.dispatch(updateExchangeValueStart(values))}
                              >
                                {({ errors, touched, values }) => (
                                  <FORM>
                                    <div className="user-name-card">
                                      <InputGroup className="mb-3" style={{gap:'1em'}}>
                                        <Field
                                          className="form-control"
                                          placeholder={t(
                                            "enter_exchange_value"
                                          )}
                                          name="exchange_value"
                                          type="number"
                                        />
                                        <Button
                                          type="submit"
                                          className="default-btn"
                                          disabled={props.updateExchangeValue.buttonDisable}
                                        >
                                          {props.updateExchangeValue.buttonDisable ? "Saving..." : "Save"}
                                        </Button>
                                      </InputGroup>
                                      <Error name="exchange_value" />
                                    </div>
                                  </FORM>
                                )}
                              </Formik>
                            </div>
                            <div className="exchange-value-note">
                                <p>Note :</p>
                                <ol>
                                  <li>
                                    This exchange value will be used to sell
                                    your USDT (Tether) to INR (Indian Rupees).
                                  </li>
                                  <li>
                                    1 USDT = 85 INR is the current market value.
                                  </li>
                                  <li>
                                    Please note that you cannot set your
                                    exchange value to be more than 5% above the
                                    current market value of USDT to INR.
                                  </li>
                                  <li>
                                    This restriction ensures fairness and
                                    prevents overpricing.
                                  </li>
                                </ol>
                              </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="bio-box">
                        <Formik
                          initialValues={{
                            about: props.profile.data.about,
                          }}
                          validationSchema={Yup.object().shape({
                            about: Yup.string()
                              .required("Required")
                              .test(
                                "len",
                                t("exceed_characters"),
                                (val) => val.length <= 180
                              ),
                          })}
                          onSubmit={(values) => {
                            setIsUpdating("about");
                            handleUpdateProfile(values);
                          }}
                        >
                          {({ errors, touched, values }) => (
                            <FORM>
                              <h4>Bio</h4>
                              <Row>
                                <Col md={12}>
                                  <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Field
                                      as="textarea"
                                      rows={5}
                                      className="form-control"
                                      name="about"
                                    />
                                    <Form.Text className="text-muted">
                                      Maximum 3 lines and 180 characters
                                    </Form.Text>
                                  </Form.Group>
                                  <Error name="about" />
                                </Col>
                              </Row>
                              <div className="setting-btn-sec">
                                <Button
                                  type="submit"
                                  className="default-btn"
                                  disabled={
                                    props.updateProfile.buttonDisable ||
                                    values.about?.trim() ==
                                      props.profile.data.about
                                  }
                                >
                                  {props.updateProfile.buttonDisable &&
                                  isUpdating === "about"
                                    ? t("saving")
                                    : t("save")}
                                </Button>
                              </div>
                            </FORM>
                          )}
                        </Formik>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="general-setting">
                      <GeneralSettings />
                    </Tab.Pane>
                    <Tab.Pane eventKey="notification-preferences">
                      <NotificationPreferences />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      ) : (
        <SomethingWentWrong
          showReload={true}
          reloadText="Reload"
          onReload={() => props.dispatch(fetchUserDetailsStart())}
        />
      )}
      {changeNumber ? (
        <ChangeNumberModal
          type={changeNumber}
          closeChangeNumberModal={closeChangeNumberModal}
          setChangeNumber={setChangeNumber}
        />
      ) : null}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateProfile: state.user.updateProfile,
  usernameValidation: state.user.usernameValidation,
  updateProfileUPI: state.user.updateProfileUPI,
  updateExchangeValue: state.user.updateExchangeValue,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SettingsIndex));
