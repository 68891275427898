import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchUserDetailsStart } from "../../store/slices/UserSlice";
import { connect } from "react-redux";

const EmptyLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { redirect = true } = props;

  useEffect(() => {
    if (localStorage.getItem("userId") &&
      localStorage.getItem("accessToken") &&
      localStorage.getItem("userLoginStatus") &&
      redirect) {
      if (!props.profile.buttonDisable &&
        Object.keys(props.profile.data).length == 0) {
        props.dispatch(fetchUserDetailsStart());
      }
      navigate("/");
    }
  }, [location.pathname]);

  return (
    <>
      {props.children}
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(EmptyLayout);
