import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { checkPaymentMethodStart, transactionInitiateStart, initiateUSDTPaymetStart } from "../../../store/slices/WalletSlice";
import { useDropzone } from "react-dropzone";
import { useTranslation, withTranslation } from "react-multi-lang";
import { apiUrl } from "../../../Environment";

const TraderPaymentModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const buyData = useSelector((state) => state.offer.buyData);
  const buyToken = useSelector((state) => state.offer.buyToken);
  const checkPaymentMethod = useSelector(
    (state) => state.wallet.checkPaymentMethod
  );
  const transactionInitiate = useSelector(
    (state) => state.wallet.transactionInitiate
  );

  const [step, setStep] = useState(1);
  const [skipRender, setSkipRender] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState({
    wallet_address: "",
    payment_id: "",
  });

  useEffect(() => {
    dispatch(
      checkPaymentMethodStart({
        amount: buyData.spendAmount,
        order_id: buyData.order_id,
        payment_method: buyData.payment_method,
        reference_id: buyData.trader_id,
        callback_url: apiUrl + 'trader_status_callback'
      })
    );
  }, []);

  const onUSDTComplete = (e) => {
    e.preventDefault();
    let  data = buyData;
    
    if(buyData.payment_method == 'UPI')
      data = { ...data, utr_number: paymentDetails.payment_id}
    else
      data = { ...data, payment_id: paymentDetails.payment_id}

    if(files.length > 0)
      data = { ...data, payment_picture: files[0]}

    dispatch(
      transactionInitiateStart({
        ...data,
      })
    );
    
  };

  useEffect(() => {
    if (!skipRender && !buyToken.loading) {
      setStep(4);
    }
  }, [buyToken]);

  useEffect(() => {
    if (!skipRender && !transactionInitiate.loading && Object.keys(transactionInitiate.data).length > 0) {
      let { token_type, ...newData } = buyData;

      if(files.length > 0)
        newData = { ...newData, payment_picture: files[0]}

      dispatch(
        initiateUSDTPaymetStart({
          amount: newData.spendAmount,
          tokens: newData.tokens,
          receiver_wallet_address: paymentDetails.wallet_address,
          payment_id: buyData.order_id,
        })
      );

      setStep(4);
    }
    setSkipRender(false);
  }, [transactionInitiate]);

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const images = files.map((file) => (
    <div className="preview-added-image">
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => setFiles([])}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <Image
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  return (
    <>
      <Modal
        className="modal-dialog-center transfer-payment-modal"
        size="md"
        centered
        show={true}
      >
        <Modal.Header closeButton onHide={props.closeTransferPaymentModal}>
          <Modal.Title>{t("transfer_payment")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {checkPaymentMethod.loading ? (
              "Loading"
            ) : Object.keys(checkPaymentMethod.data).length > 0 ? (
              <>
                {step == 1 && (
                  <div className="transfer-payment-box">
                    <div className="step-1">
                      <div className="transfer-payment-info">
                        <h4>{t("step")}-1</h4>
                        <p>
                          {t("transfer_amount")} :{" "}
                          <span>{buyData.spendAmount} ₹</span>
                        </p>
                      </div>
                      <div className="pricing-sell-sec">
                        <div className="pricing-sell-box text-center">
                          {checkPaymentMethod.data.payment_method == "UPI" ? (
                            <>
                            <img
                              src={
                                checkPaymentMethod.data.payment_details.qr_code
                              }
                            />
                            <div className="card-content-wrapper">
                                <span className="check-icon"></span>
                                <div className="card-content">
                                  <h5 className="mt-3">{t("upi_payment")}</h5>
                                  <p>{checkPaymentMethod.data.payment_details.upi_id}</p>
                                </div>
                              </div>
                              </>
                          ) : (
                            <div className="card-content-wrapper">
                              <span className="check-icon"></span>
                              <div className="card-content">
                                <h5>{t("bank_account")}</h5>
                                <p>
                                  {t("account_holder_name")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .account_holder_name
                                  }
                                </p>
                                <p>
                                  {t("account_number")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .account_number
                                  }
                                </p>
                                <p>
                                  {t("bank_name")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .bank_name
                                  }
                                </p>
                                <p>
                                  {t("bank_type")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .bank_type
                                  }
                                </p>
                                <p>
                                  {t("ifsc_code")} :{" "}
                                  {
                                    checkPaymentMethod.data.payment_details
                                      .ifsc_code
                                  }
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <Form.Group className="mb-3">
                          <Form.Label>{checkPaymentMethod.data.payment_method == "UPI" ? "UTR ID" : "Payment ID"}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            placeholder={`Enter your ${checkPaymentMethod.data.payment_method == "UPI" ? "UTR ID" : "Payment ID"}`}
                            name="payment_id"
                            value={paymentDetails.payment_id}
                            onChange={(e) => setPaymentDetails({
                              ...paymentDetails,
                              payment_id: e.target.value
                            })}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("wallet_address")}</Form.Label>
                          <Form.Control
                            className="form-control"
                            type="text"
                            placeholder={t("enter_your_wallet_address")}
                            name="wallet_address"
                            value={paymentDetails.wallet_address}
                            onChange={(e) => setPaymentDetails({
                              ...paymentDetails,
                              wallet_address: e.target.value
                            })}
                          />
                        </Form.Group>
                        <Button
                          disabled={paymentDetails.wallet_address == "" || paymentDetails.payment_id == ""}
                          className="default-btn"
                          onClick={() => setStep(2)}
                        >
                          {t("next")}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {step == 2 && (
                  <div>
                    <Button onClick={() => setStep(1)}>Back</Button>
                    <Form onSubmit={onUSDTComplete}>
                      <div className="transfer-payment-box">
                        <div className="step-2">
                          <div className="transfer-payment-info">
                            <h4>{t("step")}-2</h4>
                          </div>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            {files.length <= 0 && (
                              <div className="add-product-upload-box">
                                <Image
                                  src={
                                    window.location.origin +
                                    "/assets/img/settings/camera-icon.svg"
                                  }
                                  className="upload-icon"
                                />
                                <p>
                                  {t("upload_your_payment_screenshot_here")}
                                  <br /> {t("accept_only_jpeg_jpg_png")}
                                </p>
                              </div>
                            )}
                            <div>{images}</div>
                          </div>
                        </div>
                      </div>
                      <div className="buy-btn-sec">
                        <Button
                          className="default-btn w-100"
                          type="submit"
                          disabled={transactionInitiate.buttonDisable}
                        >
                          {transactionInitiate.buttonDisable ? "Loading" : "PROCEED"}
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
                {step == 4 && (
                <div className="step-3">
                  <div className="payment-waiting-img-sec">
                    <Image
                      className="payment-waiting-img"
                      src={window.location.origin +
                        "/assets/img/buy/payment-success.gif"}
                    />
                  </div>
                  {Object.keys(transactionInitiate.data).length > 0 ? (
                    <div className="transfer-payment-box waiting-payment-box">
                      <h4>{t("transaction_successful")}</h4>
                      <p>{t("wow_it_just_took_less_than_min")}</p>
                      <div className="buy-btn-sec mt-3">
                        <Button
                          className="default-btn w-100"
                          onClick={() => navigate("/list")}
                        >
                          {t("ok")}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="transfer-payment-box waiting-payment-box">
                      <h4>{t("transaction_failed")}</h4>
                      <div className="buy-btn-sec mt-3">
                        <Button
                          className="default-btn w-100"
                          onClick={() => navigate("/list")}
                        >
                          {t("ok")}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              </>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  src={window.location.origin + "/assets/img/error.png"}
                  alt="no-data"
                  className="mb-3"
                />
                <p>Sorry, Payment Details Not Found</p>
              </div>
            )}  
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(TraderPaymentModal);
