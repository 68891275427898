import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Dropdown, Button, Table } from "react-bootstrap";
import "./Profile.css";
import { Link, useNavigate } from "react-router-dom";
import Select from 'react-select'
import { fetchActiveTokenOffersStart, fetchMoreActiveTokenOffersStart, updateBuyData } from "../../../store/slices/OfferSlice";
import { connect, useSelector } from "react-redux";
import { useTranslation, withTranslation } from 'react-multi-lang';
import PageLoader from "../../Common/PageLoader";
import NoDataFound from '../../Common/NoDataFound';
import InfiniteScroll from "react-infinite-scroll-component";
import CenterLoader from "../../Common/CenterLoader";

const ActiveOffersSec = ({ isInfinite = false, ...props }) => {

  const navigate = useNavigate();
  const t = useTranslation();
  const buyData = useSelector(state => state.offer.buyData);

  useEffect(() => {
    props.dispatch(fetchActiveTokenOffersStart({
      username: props.username,
      skip: 0,
      take: isInfinite ? 12 : 6
    }));
  }, [props.username]);

  const fetchMoreOffers = () => {
    props.dispatch(fetchMoreActiveTokenOffersStart({
      username: props.username,
      skip: props.activeTokenOffers.data.token_offers.length,
      take: 12,
    }));
  }

  const onBuy = offerUniqueId => {
    props.dispatch(updateBuyData({
      ...buyData,
      token_offer_unique_id: offerUniqueId,
    }));
    navigate("/buy");
  }

  return (
    <>
      <div className="active-offers-sec">
        <div className="active-offers-header-sec choose-your-cryptocurrency-box">
          <div className="active-offers-left-sec">
            <h3>{t("active_offers")}</h3>
            {isInfinite ?
              <div className="active-offers-tab-nav-sec">
                <div className="sell-choose-your-currency-box">
                  <div className="sell-choose-your-currency-card">
                    <label for="radio-card-1" className="radio-card">
                      <input type="radio" name="radio-card-1" id="radio-card-1" checked />
                      <div className="card-content-wrapper">
                        <div className="card-content">
                          <Image
                            className="sell-bnb-icon"
                            src={
                              window.location.origin + "/assets/img/create-offers/bnb-icon.svg"
                            }
                          />
                          <span>{t("bitcoin")}</span>
                        </div>
                      </div>
                    </label>

                    <label for="radio-card-2" className="radio-card">
                      <input type="radio" name="radio-card-2" id="radio-card-2" />
                      <div className="card-content-wrapper">
                        <div className="card-content">
                          <Image
                            className="sell-bnb-icon"
                            src={
                              window.location.origin + "/assets/img/create-offers/bnb-icon.svg"
                            }
                          />
                          <span>{t("tether")}</span>
                        </div>
                      </div>
                    </label>

                    <label for="radio-card-3" className="radio-card">
                      <input type="radio" name="radio-card-3" id="radio-card-3" />
                      <div className="card-content-wrapper">
                        <div className="card-content">
                          <Image
                            className="sell-bnb-icon"
                            src={
                              window.location.origin + "/assets/img/create-offers/bnb-icon.svg"
                            }
                          />
                          <span>{t("etherium")}</span>
                        </div>
                      </div>
                    </label>

                  </div>
                </div>
              </div>
              : null
            }
          </div>
          {isInfinite ?
            <div className="active-offers-right-sec">
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className="list-action-btn">
                  <Image
                    className="coversion-icon"
                    src={
                      window.location.origin + "/assets/img/list/sort-icon.svg"
                    }
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">{t("price_lowest_to_highest")}</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">{t("price_highest_to_lowest")}</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">{t("avg_trade_speed_fastest_to_slowest")}</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">{t("avg_trade_speed_slowest_to_fastest")}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            : null
          }
        </div>
        {props.activeTokenOffers.loading ?
          <PageLoader />
          : props.activeTokenOffers.data.token_offers &&
            props.activeTokenOffers.data.token_offers.length > 0 ?
            <div className="active-offers-tab-content-sec">
              <div className="active-offers-draft">
                {/*<div className="list-table-sec">
              <Table>
                <tbody>
                  <tr>
                    <td>
                      <div className="list-user-details">
                        <div className="list-user-img-sec">
                          <p>P</p>
                          <div className="status-online"></div>
                        </div>
                        <div className="list-user-info">
                          <h4>User Name</h4>
                          <p>Seen 7 min ago</p>
                          <div className="list-liked-user">
                            <Image
                              className="coversion-icon"
                              src={
                                window.location.origin + "/assets/img/list/like-icon.svg"
                              }
                            />
                            <span>
                              245
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="list-price-details">
                        <div className="price-amount">
                          20.24k <span>USD</span>
                        </div>
                        <div className="price-convertion-amount">
                          <p>1 USD = 1.03 USD of BTC</p>
                        </div>
                        <div className="price-convertion-percentage">
                          <Image
                            className="price-convertion-percentage-icon"
                            src={
                              window.location.origin + "/assets/img/list/arrow-down-icon.svg"
                            }
                          />
                          <span>2%</span>
                          <Image
                            className="price-convertion-percentage-icon"
                            src={
                              window.location.origin + "/assets/img/list/info-icon.svg"
                            }
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="limit-transaction-amount">
                        <h5>Available <span>70000 USDT</span></h5>
                        <h5>Limit <span>1000.00 - 7,100.00 INR</span></h5>
                      </div>
                    </td>
                    <td>
                      <div className="list-transfer-speed">
                        1 Minute
                      </div>
                    </td>
                    <td>
                      <div className="payment-type-sec">
                        UPI
                      </div>
                    </td>
                    <div className="draft-icon-sec">
                      <Image
                        className="draft-icon"
                        src={
                          window.location.origin + "/assets/img/profile/draft-icon.svg"
                        }
                      />
                    </div>
                    <td>
                      <div className="list-action-btn-sec">
                        <Button className="default-btn">
                          Continue
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>*/}
              </div>
              <div className="active-offers-list-table">
                <div className="list-table-sec">
                  <InfiniteScroll
                    dataLength={props.activeTokenOffers.data.token_offers.length}
                    next={fetchMoreOffers}
                    hasMore={
                      props.activeTokenOffers.data.token_offers.length < props.activeTokenOffers.data.total_token_offers &&
                      isInfinite
                    }
                    loader={<div className="d-flex justify-content-center mb-5">
                      <CenterLoader />
                    </div>}
                  >
                    <Table>
                      <tbody>
                        {props.activeTokenOffers.data.token_offers.map((activeOffer, i) =>
                          <tr key={i}>
                            <td>
                              <div className="list-user-details">
                                <div className="list-user-img-sec">
                                  {/* <p>P</p> */}
                                  <Image src={activeOffer.user.picture} />
                                  <div className="status-online"></div>
                                </div>
                                <div className="list-user-info">
                                  <h4>{activeOffer.user.name}</h4>
                                  <p>Seen 7 min ago</p>
                                  <div className="list-liked-user">
                                    <Image
                                      className="coversion-icon"
                                      src={
                                        window.location.origin + "/assets/img/list/like-icon.svg"
                                      }
                                    />
                                    <span>
                                      245
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="list-price-details">
                                <div className="price-amount">
                                  {activeOffer.total_tokens}
                                  {/* 20.24k <span>USD</span> */}
                                </div>
                                <div className="price-convertion-amount">
                                  <p>1 {t("usd")} = {activeOffer.single_token_rate}</p>
                                  {/* <p>1 USD = 1.03 USD of BTC</p> */}
                                </div>
                                <div className="price-convertion-percentage">
                                  <Image
                                    className="price-convertion-percentage-icon"
                                    src={
                                      window.location.origin + "/assets/img/list/arrow-down-icon.svg"
                                    }
                                  />
                                  <span>2%</span>
                                  <Image
                                    className="price-convertion-percentage-icon"
                                    src={
                                      window.location.origin + "/assets/img/list/info-icon.svg"
                                    }
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="limit-transaction-amount">
                                <h5>{t("available")} <span>{activeOffer.remaining_tokens}</span></h5>
                                <h5>{t("limit")} <span>{activeOffer.limit}</span></h5>
                              </div>
                            </td>
                            <td>
                              <div className="list-transfer-speed">
                                1 Minute
                              </div>
                            </td>
                            <td>
                              <div className="payment-type-sec">
                              {activeOffer.payment_mode}
                              </div>
                            </td>
                            {activeOffer.user.user_id !== props.profile.data.user_id ?
                              <td>
                                <div className="list-action-btn-sec">
                                  <Button className="buy-btn" onClick={() => onBuy(activeOffer.token_offer_unique_id)}>
                                    {t("buy")}
                                  </Button>
                                </div>
                              </td>
                              : null
                            }
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                  {!isInfinite && props.activeTokenOffers.data.token_offers.length < props.activeTokenOffers.data.total_token_offers ?
                    <div className="d-flex justify-content-center list-action-btn-sec mb-3">
                      <Button className="buy-btn" onClick={() => navigate(`/active-offer/${props.username}`)}>
                        {t("load_more")}
                      </Button>
                    </div>
                    : null
                  }
                </div>
              </div>
            </div>
            : <NoDataFound />
        }
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  activeTokenOffers: state.offer.activeTokenOffers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ActiveOffersSec);
