import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OtpInput from "react-otp-input";
import CounterTimer from "../helper/CounterTimer";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from 'react-redux';

const ChangeNumberVerificationCode = (props) => {
  const t = useTranslation();
  const {
    resendEnable,
    setResendEnable,
    handleConfirmChangeCredentials,
    handleSendOTP,
    setStep
  } = props;

  const [otp, setOTP] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    handleConfirmChangeCredentials({
      verification_code: otp,
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit} >
        <div className="change-number-step-1">
          <div className="change-number-info">
            <div className="d-flex justify-content-start flex-column gap-2">
              <Image
                width={25}
                src={window.location.origin + "/assets/img/settings/back.svg"}
                className="cursor-pointer"
                onClick={() => setStep("1")}
              />
              <h4>Step - 2</h4>
            </div>
            <div className="flex-label">
              <Form.Label>Enter OTP for Verification</Form.Label>
              <Form.Label>
                {resendEnable ?
                  <Link to="#" onClick={handleSendOTP} className="text-blue">{t("resend")}</Link>
                  : <CounterTimer
                    time={60000}
                    render={({ minutes, seconds }) =>
                      <span>{(minutes < 10 ? "0" : "") + minutes}:{(seconds < 10 ? "0" : "") + seconds} Mins</span>
                    }
                    onComplete={() => { setResendEnable(true) }}
                  />
                }
              </Form.Label>
            </div>
            <div className="verification-item">
              <OtpInput
                value={otp}
                onChange={setOTP}
                numInputs={6}
                isInputNum={true}
                separator={<span>-</span>}
                shouldAutoFocus={true}
              />
            </div>
          </div>
          <div className="send-otp-btn-sec">
            <Button
              type="submit"
              className="default-btn w-100"
              disabled={props.validateUpdateCredentials.buttonDisable || otp.length < 6}
            >
              {props.validateUpdateCredentials.buttonDisable ?
                t("loading")
                : t("proceed")
              }
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

const mapStateToPros = (state) => ({
  validateUpdateCredentials: state.user.validateUpdateCredentials,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ChangeNumberVerificationCode));