import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { Error } from "../Common/FormUtils";
import { useTranslation, withTranslation } from "react-multi-lang";
import Select from "react-select";
import { userBillingsSaveStart } from "../../store/slices/UserSlice";

const AddAccountModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation();
  const userBillingsSave = useSelector((state)=> state.user.userBillingsSave);
  const userBillingsList = useSelector((state)=> state.user.userBillingsList);
  const [skipRender, setSkipRender] = useState(true);

  const options = [
    {label: "Current", value: "checking"},
    {label: "Savings", value: "savings"},
  ]

  const accountSchema = Yup.object().shape({
    account_holder_name: Yup.string()
      .required(t("required")),
    account_number: Yup.string()
      .required(t("required")),
    bank_name: Yup.string()
      .required(t("required")),
    bank_type: Yup.string()
      .required(t("required")),
    ifsc_code: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")),
  });

  const handleBankAccount = (values) => {
    let newValues = values;
    if(Object.keys(userBillingsList.data).length > 0 && Object.keys(userBillingsList.data.user_billing_accounts).length > 0)
      newValues = { ...newValues, user_billing_account_id: userBillingsList.data.user_billing_accounts.user_billing_account_id }
    dispatch(userBillingsSaveStart(newValues));
  }

  useEffect(()=> {
    if(!skipRender && !userBillingsSave.loading && Object.keys(userBillingsSave.data).length > 0)
    {
      props.closeAddAccountModal();
    }
    setSkipRender(false);
  },[userBillingsSave])


  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.addAccount}
        onHide={props.closeAddAccountModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Bank Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={Object.keys(userBillingsList.data).length > 0 && Object.keys(userBillingsList.data.user_billing_accounts).length > 0 ? 
                {
                  account_holder_name: userBillingsList.data.user_billing_accounts.account_holder_name,
                  account_number: userBillingsList.data.user_billing_accounts.account_number,
                  bank_name: userBillingsList.data.user_billing_accounts.bank_name,
                  bank_type: userBillingsList.data.user_billing_accounts.bank_type,
                  ifsc_code: userBillingsList.data.user_billing_accounts.ifsc_code,
                } : {
                account_holder_name: "",
                account_number: "",
                bank_name: "",
                bank_type: "",
                ifsc_code: "",
              }}
              validationSchema={accountSchema}
              onSubmit={(values) => handleBankAccount(values)}
            >
              {({ touched, errors, values, setFieldValue }) => (
                <FORM className="auth-form">
                  <div className="auth-btn-sec">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account Holder Name</Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="account_holder_name"
                        placeholder={t("account_holder_name")}
                      />
                      <Error name="account_holder_name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account Number</Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="account_number"
                        placeholder={t("account_number")}
                      />
                      <Error name="account_number" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bank Name</Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="bank_name"
                        placeholder={t("bank_name")}
                      />
                      <Error name="bank_name" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bank Type</Form.Label>
                      <Select
                        placeholder={t("select")}
                        options={options}
                        value={values.bank_type ? {label:values.bank_type, value:values.bank_type} : {}}
                        onChange={(val) => setFieldValue("bank_type", val.value)}
                      />
                      <Error name="bank_type" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>IFSC Code</Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        name="ifsc_code"
                        placeholder={t("ifsc_code")}
                      />
                      <Error name="ifsc_code" />
                    </Form.Group>
                    <Button
                      type="submit"
                      className="default-btn w-100"
                      disabled={userBillingsSave.buttonDisable}
                    >
                      {userBillingsSave.buttonDisable
                      ? t("loading")
                      : t("add_account")}
                    </Button>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddAccountModal;
