import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./CreateOffer.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import PaymentMethodIndex from "./PaymentMethodIndex";
import PricingSellIndex from "./PricingSellIndex";
import TradeInstructions from "./TradeInstructions";
import Wizard from "../Common/Wizard";
import { useTranslation, withTranslation } from "react-multi-lang";
import PageLoader from "../Common/PageLoader";
import { connect, useDispatch, useSelector } from "react-redux";
import configuration from "react-global-configuration";
import { tokenOfferViewStart } from "../../store/slices/OfferSlice";

const SellIndex = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);
  const tokenOfferView = useSelector(state => state.offer.tokenOfferView)


  let d1 = new Date();
  d1.setMinutes(d1.getMinutes() + 10);

  const [step, setStep] = useState(1);
  const [offerData, setOfferData] = useState({
    token_type: configuration.get("configData.project_tokens_formatted").length > 0 ? configuration.get("configData.project_tokens_formatted")[0] : null,
    total_tokens: "",
    token_rate_type: "INR",

    is_market_rate: 1,
    min_tokens: "",
    max_tokens: "",
    single_token_rate: "",
    offer_start_at: d1,
    offer_time_limit: "",

    offer_lable: "",
    offer_terms: "",
    trade_instructions: "",
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [step]);

  const wizardSteps = [
    { name: t("make_offer.wizard.payment_method") },
    { name: t("make_offer.wizard.pricing") },
    { name: t("make_offer.wizard.other_settings") }
  ];

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0) {
      if(profile.data.status !== 3)
        navigate(-1);
    }
  }, [profile]);

  useEffect(() => {
    if(params.token_offer_unique_id)
    {
      dispatch(tokenOfferViewStart({
        token_offer_unique_id: params.token_offer_unique_id,
      }))
    }
    else {
      setOfferData({
        token_type: configuration.get("configData.project_tokens_formatted").length > 0 ? configuration.get("configData.project_tokens_formatted")[0] : null,
        total_tokens: "",
        token_rate_type: "INR",
        is_market_rate: 1,
        min_tokens: "",
        max_tokens: "",
        single_token_rate: "",
        offer_start_at: d1,
        offer_time_limit: "",
        offer_lable: "",
        offer_terms: "",
        trade_instructions: "",
      })
      setStep(1);
    }
  }, [params.token_offer_unique_id])

  useEffect(() => {
    if(!tokenOfferView.loading && Object.keys(tokenOfferView.data).length > 0 && params.token_offer_unique_id)
    {
      setOfferData({
        token_offer_id: tokenOfferView.data.token_offer_id,
        token_type: tokenOfferView.data.project_token,
        total_tokens: tokenOfferView.data.total_tokens,
        token_rate_type: tokenOfferView.data.token_rate_type,
        is_market_rate: tokenOfferView.data.is_market_rate,
        min_tokens: tokenOfferView.data.min_tokens,
        max_tokens: tokenOfferView.data.max_tokens,
        single_token_rate: tokenOfferView.data.single_token_rate,
        offer_start_at: tokenOfferView.data.offer_start_at,
        offer_time_limit: tokenOfferView.data.offer_time_limit.toString(),
        offer_lable: tokenOfferView.data.offer_lable ? tokenOfferView.data.offer_lable : "",
        offer_terms: tokenOfferView.data.offer_terms ? tokenOfferView.data.offer_terms : "" ,
        trade_instructions: tokenOfferView.data.trade_instructions ? tokenOfferView.data.offer_terms : "",
      })
    }
  }, [tokenOfferView])

  return (
    <>
      {props.profile.loading ?
        <PageLoader />
        : <div className="sell-sec">
          <Container>
            <h2>{t("make_offer.heading")}</h2>
            <Wizard
              currentStep={step}
              steps={wizardSteps}
            />
            {step == 1 && (
              <PaymentMethodIndex setStep={setStep} offerData={offerData} setOfferData={setOfferData} />
            )}
            {step == 2 && (
              <PricingSellIndex setStep={setStep} offerData={offerData} setOfferData={setOfferData} />
            )}
            {step == 3 && (
              <TradeInstructions setStep={setStep} offerData={offerData} setOfferData={setOfferData} create={params.token_offer_unique_id ? 0 : 1} />
            )}
          </Container>
        </div>
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(SellIndex));
