import React from 'react';

const CenterLoader = () => {

  return (
    <svg width="50px" height="50px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx="50" cy="50" r="41" stroke-width="8" stroke="#41c3ec" stroke-dasharray="64.40264939859075 64.40264939859075" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" dur="1.0526315789473684s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
      </circle>
      <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#3ade99" stroke-dasharray="50.26548245743669 50.26548245743669" stroke-dashoffset="50.26548245743669" fill="none" stroke-linecap="round">
        <animateTransform attributeName="transform" type="rotate" dur="1.0526315789473684s" repeatCount="indefinite" keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
      </circle>
    </svg>
  );
}

export default CenterLoader;