import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { saveFeedbackStart } from "../../store/slices/FeedbackSlice";
import { useTranslation, withTranslation } from "react-multi-lang";

const SubmitFeedbackModal = (props) => {
  const dispatch = useDispatch();
  const saveFeedback = useSelector(state => state.feedback.saveFeedback);

  const [skipRender, setSkipRender] = useState(true);
  const [errorMsg, setErrorMsg] = useState({});
  const t =useTranslation();
  const [feedBack, setFeedBack] = useState({
    offer_transaction_id: props.offerTransactionId,
    feedback_type: null,
    note: "",
    is_trusted: 1,
  });

  const onSubmit = e => {
    e.preventDefault();
    let error = {};
    if (!feedBack.feedback_type) error = { ...error, feedback_type: "Required" };
    if (!feedBack.note) error = { ...error, note: "Required" };
    if (Object.keys(error).length === 0) dispatch(saveFeedbackStart(feedBack));
    setErrorMsg(error);
  }

  useEffect(() => {
    if (!skipRender && !saveFeedback.loading && Object.keys(saveFeedback.data).length > 0) {
      props.closeSubmitFeedbackModal();
    }
    setSkipRender(false);
  }, [saveFeedback]);

  return (
    <>
      <Modal
        className="modal-dialog-center change-number-modal"
        size="md"
        centered
        show={true}
      >
        <Modal.Header closeButton onHide={props.closeSubmitFeedbackModal}>
          <Modal.Title>{t("submit_feedback")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="submit-feedback-form setting-form" onSubmit={onSubmit}>
            <p>{t("in_publishing_and_graphic_para")}</p>
            <Image
              src={feedBack.feedback_type === 1 ?
                window.location.origin + "/assets/img/profile/like-icon-fill.svg"
                : window.location.origin + "/assets/img/profile/like-icon.svg"
              }
              onClick={() => setFeedBack({ ...feedBack, feedback_type: 1 })}
            />
            <Image
              src={feedBack.feedback_type === 2 ?
                window.location.origin + "/assets/img/profile/dislike-fill.svg"
                : window.location.origin + "/assets/img/profile/dislike.svg"
              }
              onClick={() => setFeedBack({ ...feedBack, feedback_type: 2 })}
            />
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label>{t("message")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={feedBack.note}
                onChange={e => setFeedBack({ ...feedBack, note: e.target.value })}
              />
            </Form.Group>

            <div className="confirm-payment-btn-sec">
              <Button
                className="default-btn w-100"
                type="submit"
                disabled={saveFeedback.buttonDisable}>
                {saveFeedback.buttonDisable ?
                  t("loading")
                  : t("send_feedback")
                }
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(SubmitFeedbackModal);