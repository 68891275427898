import React from 'react';
import { Button, Image } from 'react-bootstrap';

const SomethingWentWrong = ({ showReload = false, reloadText = "", onReload = () => { } }) => {
  return (
    <div className='page-loader-container'>
      <div className='something-went-wrong-sec'>
        <Image
          src={window.location.origin + "/assets/img/something_went_wrong.svg"}
          className="mb-3"
        />
        {showReload ?
          <Button
            type="button"
            className="default-btn w-100"
            onClick={() => onReload()}
          >
            {reloadText}
          </Button>
          : null
        }
      </div>
    </div>
  );
}

export default SomethingWentWrong;