import React, { useEffect } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "../Common/NoDataFound";
import PageLoader from "../Common/PageLoader";
import {
  paymentMethodsStart,
  paymentMethodsUpdateStart,
} from "../../store/slices/WalletSlice";

const PaymentMethodsIndex = () => {
  const t = useTranslation();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);
  const paymentMethods = useSelector((state) => state.wallet.paymentMethods);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0)
      dispatch(
        paymentMethodsStart({
          trader_unique_id: profile.data.reference_unique_id,
        })
      );
  }, [profile]);

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-rejected";
      case 1:
        return "payment-approved";
      default:
        return "payment-pending";
    }
  };

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="update-wallet-header-sec">
                <h3>Payment Methods</h3>
                {/* <div className="find-offer-btn-sec">
                  <Button
                    onClick={() => setAddUPI(true)}
                    className="default-btn"
                  >
                    Add UPI
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {paymentMethods.loading ? (
                <PageLoader />
              ) : paymentMethods.data.payment_methods &&
                paymentMethods.data.payment_methods.length > 0 ? (
                <div className="active-offers-tab-content-sec">
                  <div className="active-offers-list-table">
                    <div className="list-table-sec">
                      <Table>
                        <tbody>
                          <tr>
                            <td>S. No.</td>
                            <td>Name</td>
                            <td>Status</td>
                            <td>Actions</td>
                          </tr>
                          {paymentMethods.data.payment_methods.map(
                            (item, i) => (
                              <tr key={i}>
                                <td>
                                  <div className="list-user-details">
                                    <div className="list-user-info">
                                      <p>{i + 1}</p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="list-transfer-speed">
                                    {item.type}
                                  </div>
                                </td>
                                <td>
                                  <div className={getStatusClass(item.status)}>
                                    {item.status_formatted}
                                  </div>
                                </td>
                                <td>
                                  <div className={`two-step-auth-toggle-switch-sec`}>
                                    <Form.Check
                                      type="switch"
                                      id="custom-switch"
                                      label=""
                                      checked={item.status}
                                      onClick={() =>
                                        dispatch(
                                          paymentMethodsUpdateStart({
                                            type: item.type,
                                            status: item.status
                                              ? "disable"
                                              : "enable",
                                            trader_unique_id:
                                              profile.data.reference_unique_id,
                                          })
                                        )
                                      }
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaymentMethodsIndex;
