import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./LandingPage.css";
import { Link, useNavigate } from "react-router-dom";
import BuySellFeaturesIndex from "./BuySellFeatureIndex";
import MediaVideoIndex from "./MediaVideoIndex";
import YourExperienceIndex from "./YourExperienceIndex";
import TestimonialIndex from "./TestimonialIndex";
import OurClientIndex from "./OurClientIndex";
import FinancialSystemIndex from "./FinancialSystemIndex";
import CustomSelect from "../Common/CustomSelect";
import configuration from "react-global-configuration";
import { useDispatch, useSelector } from "react-redux";
import { amountConversionStart, updateBuyData } from "../../store/slices/OfferSlice";
import { ErrorMsg } from "../Common/FormUtils";
import { useTranslation, withTranslation } from "react-multi-lang";

const LandingPageIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();

  const dispatch = useDispatch();
  const amountConversion = useSelector(state => state.offer.amountConversion);
  const buyData = useSelector(state => state.offer.buyData);
  const setBuyData = data => dispatch(updateBuyData(data));

  const [errorMsg, setErrorMsg] = useState({
    token_type: "",
    spendAmount: "",
  })

  useEffect(() => {
    if (!buyData.token_type) setBuyData({
      ...buyData,
      token_type: configuration.get("configData.project_tokens_formatted") && configuration.get("configData.project_tokens_formatted").length > 0 ?
        configuration.get("configData.project_tokens_formatted")[0] : null
    })
  }, []);

  useEffect(() => {
    if (buyData.token_type) {
      dispatch(amountConversionStart({
        convertFrom: buyData.token_type.api_id,
        convertTo: "inr",
        isNetwork: true,
      }));
    }
  }, [buyData.token_type]);

  const onComplete = e => {
    e.preventDefault();
    let error = {};
    if (!buyData.token_type) error = { ...error, token_type: "Required" };
    if (!buyData.spendAmount) error = { ...error, spendAmount: "Required" };
    else if (buyData.spendAmount < 100) error = { ...error, spendAmount: "Min 100 rupees" };

    if (Object.keys(error).length === 0)
    navigate("/list")
    else setErrorMsg(error);
  }

  // useEffect(() => {
  //   if (!skipRender && !amountConversion.loading && Object.keys(amountConversion.data).length > 0) {
  //     const exchangeValue = Math.round((amountConversion.data.amount * buyData.token) * Math.pow(10, 8)) / Math.pow(10, 8)
  //     setBuyData({
  //       ...buyData,
  //       exchangeValue: exchangeValue,
  //       amountConversion: amountConversion.data.amount,
  //     });
  //     if (exchangeValue < 1)
  //       setErrorMsg("Min 1 USD");
  //     else
  //       setErrorMsg("");
  //   }
  //   setSkipRender(false);
  // }, [amountConversion]);

  return (
    <>
      <div className="landing-page-body">
        <section className="home-banner-sec">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={9}>
                <div className="banner-box">
                  <div className="banner-content">
                    <h1>
                    {t("unlock_the_power")}
                    </h1>
                    <p>{t("join_thousands_of_traders_para")}</p>
                  </div>
                  <div className="buy-sell-box">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="buy">
                      <Row>
                        {/* <Col sm={12}>
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey="buy">BUY</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="sell">SELL</Nav.Link>
                          </Nav.Item>
                          </Nav>
                        </Col> */}
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="buy">
                              <Form className="buy-sell-form" onSubmit={onComplete}>
                                <Row>
                                  <Col md={6}>
                                    <Form.Group className="mb-3">
                                      <div className="coversion-currency-sec">
                                        <p>1{buyData.token_type?.token} =
                                          <span>
                                            {amountConversion.data.amount ? amountConversion.data.amount : 0} INR
                                            <Image
                                              className="coversion-icon"
                                              src={
                                                window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                              }
                                            />
                                          </span>
                                        </p>
                                      </div>
                                      <CustomSelect
                                        icon="token_icon"
                                        value={buyData.token_type}
                                        options={configuration.get("configData.project_tokens_formatted")}
                                        onChange={val => setBuyData({
                                          ...buyData,
                                          token_type: val,
                                        })}
                                      />
                                      {errorMsg.token_type ? <ErrorMsg msg={errorMsg.token_type} /> : null}
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                  <Form.Group className="mb-3">
                                      <Form.Label>{t("i_want_to_spend")} (₹)</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder=""
                                        value={buyData.spendAmount}
                                        onChange={e => {
                                          const val = e.target.value;
                                          if (Number(val) == val) {
                                            setBuyData({ ...buyData, spendAmount: val })
                                          }
                                        }}
                                      />
                                      {errorMsg.spendAmount ? <ErrorMsg msg={errorMsg.spendAmount} /> : null}
                                      <div className="coversion-currency-sec mt-3">
                                        <p>{t("min")} :
                                          <span>
                                            100
                                            {/* <Image
                                                className="coversion-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                                }
                                            /> */}
                                            ₹
                                          </span>
                                        </p>
                                      </div>
                                    </Form.Group>
                                    {/* <Form.Label>UPI/BHIM</Form.Label>
                                    <InputGroup className="mb-3">
                                      <InputGroup.Text id="basic-addon1" className="border-right">
                                        <Image
                                          className="coversion-icon"
                                          src={
                                            window.location.origin + "/assets/img/landing-page/icons/bhim.png"
                                          }
                                        />
                                      </InputGroup.Text>
                                      <Form.Control
                                        placeholder=""
                                        className="border-left"
                                      />
                                    </InputGroup>
                                    <div className="flex-label">
                                      <Form.Label>Enter UPI ID</Form.Label>
                                      <Form.Label className="text-blue"><Link to="#">How to find UPI ID ?</Link></Form.Label>
                                    </div>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        placeholder="Vidya5678"
                                      />
                                      <InputGroup.Text id="basic-addon1">
                                        @upi
                                      </InputGroup.Text>
                                    </InputGroup> */}
                                    <div className="find-offer-btn-sec">
                                      <Button className="default-btn w-100" type="submit">
                                        {t("find_offers")}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="sell">
                              <Form className="buy-sell-form">
                                <Row>
                                  <Col md={6}>
                                    <Form.Group className="mb-3">
                                      <div className="coversion-currency-sec">
                                        <p>1BTC =
                                          <span>
                                            20.764 USD
                                            <Image
                                              className="coversion-icon"
                                              src={
                                                window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                              }
                                            />
                                          </span>
                                        </p>
                                      </div>
                                      <Select
                                        value={selectedCountry}
                                        options={countries}
                                        onChange={handleChange}
                                        styles={
                                          customStyles}
                                        components={{
                                          Option,
                                          SingleValue
                                        }}
                                        // menuIsOpen={true}
                                        isSearchable={false}
                                      />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>I Want To Spend</Form.Label>
                                      <Form.Control type="text" placeholder="" />
                                      <div className="coversion-currency-sec mt-3">
                                        <p>Min :
                                          <span>
                                            10,000
                                            {/* <Image
                                                className="coversion-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                                }
                                            /> *
                                            ₹
                                          </span>
                                        </p>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col md={6}>
                                    <Form.Label>UPI/BHIM</Form.Label>
                                    <InputGroup className="mb-3">
                                      <InputGroup.Text id="basic-addon1" className="border-right">
                                        <Image
                                          className="coversion-icon"
                                          src={
                                            window.location.origin + "/assets/img/landing-page/icons/bhim.png"
                                          }
                                        />
                                      </InputGroup.Text>
                                      <Form.Control
                                        placeholder=""
                                        className="border-left"
                                      />
                                    </InputGroup>
                                    <div className="flex-label">
                                      <Form.Label>Enter UPI ID</Form.Label>
                                      <Form.Label className="text-blue"><Link to="#">How to find UPI ID ?</Link></Form.Label>
                                    </div>
                                    <InputGroup className="mb-3">
                                      <Form.Control
                                        placeholder="Vidya5678"
                                      />
                                      <InputGroup.Text id="basic-addon1">
                                        @upi
                                      </InputGroup.Text>
                                    </InputGroup>
                                    <div className="find-offer-btn-sec">
                                      <Button className="default-btn w-100">
                                        CREATE OFFERS
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            </Tab.Pane> */}
                          </Tab.Content>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <BuySellFeaturesIndex />
        {/* <MediaVideoIndex /> */}
        <FinancialSystemIndex />
        <YourExperienceIndex />
        <TestimonialIndex />
        <OurClientIndex />
      </div>
    </>
  );
};

export default withTranslation(LandingPageIndex);
