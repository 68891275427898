import React, { useState, useEffect, useRef } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import { userRegisterStart } from "../../store/slices/UserSlice";
import VerificationModal from "./VerificationModal";
import { Error, PasswordField } from "../Common/FormUtils";
import configuration from 'react-global-configuration';

const RegisterIndex = (props) => {
  const navigate = useNavigate();
  const t = useTranslation();

  const formRef = useRef(null);

  const [verification, setVerification] = useState(false);
  const [username, setUsername] = useState("");
  const [verificationType, setVerificationType] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const emailRegisterSchema = Yup.object().shape({
    email: Yup.string().email(t("invalid_email")).required(t("required")),
    name: Yup.string().required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")),
    password_confirmation: Yup.string().required(t("required")).when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], t("both_password_should_same"))
    }),
  });

  const mobileRegisterSchema = Yup.object().shape({
    name: Yup.string().required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")),
    password_confirmation: Yup.string().required(t("required")).when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], t("both_password_should_same"))
    }),
  });

  useEffect(() => {
    if (!skipRender && !props.register.loading && Object.keys(props.register.data).length > 0) {
      // On register success
      if (props.register.data.is_email_verified == 0 && props.register.data.is_mobile_verified == 0) {
        setVerification(true);
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.register]);

  const closeVerificationModal = () => {
    setVerificationType(false);
    setVerification(false);
  }

  const handleEmailRegister = (values) => {
    setUsername(values.email);
    setVerificationType("email");
    props.dispatch(userRegisterStart(values));
  }

  const validateMobileNumber = (value) => {
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Not a Phone number");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };


  const handleMobileRegister = (values) => {
    if (validateMobileNumber("+" + values.mobile)) {
      const intlNo = formatPhoneNumberIntl("+" + values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      setUsername(mobile);
      setVerificationType("mobile");
      props.dispatch(
        userRegisterStart({
          ...values,
          mobile: mobile,
          country_code: countryCode,
        })
      );
    }
  }

  return (
    <>
      <section className="auth-sec">
        <div className="vertical-center">
          <Container>
              <Row className="align-items-center">
                <Col md={7}>
                  <div className="auth-img-sec">
                    <Image
                      className="auth-img"
                      src={
                        window.location.origin + "/assets/img/auth/login-img.png"
                      }
                    />
                  </div>
                </Col>
                <Col md={5}>
                  <div className="auth-box">
                    <div className="auth-logo-sec">
                      <Image
                        className="auth-logo-img"
                        src={configuration.get("configData.site_dark_logo")}
                      />
                    </div>
                    <h3>Create Your Account</h3>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="phone-number">
                      <Row>
                        <Col sm={12}>
                          <Nav variant="pills">
                            <Nav.Item>
                              <Nav.Link eventKey="phone-number">Phone Number</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="email">Email</Nav.Link>
                            </Nav.Item>
                          </Nav>
                        </Col>
                        <Col sm={12}>
                          <Tab.Content>
                            <Tab.Pane eventKey="phone-number">
                              <Formik
                                initialValues={{
                                  name: "",
                                  mobile: "",
                                  password: "",
                                  password_confirmation: "",
                                }}
                                validationSchema={mobileRegisterSchema}
                                onSubmit={(values) => handleMobileRegister(values)}
                                innerRef={formRef}
                              >
                                {({ touched, errors, values, setFieldValue }) =>
                                  <FORM className="auth-form">
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("name")}</Form.Label>
                                      <Field
                                        className="form-control"
                                        placeholder={t("name_placeholder")}
                                        name="name"
                                      />
                                      <Error name="name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("phone_number")}</Form.Label>
                                      <PhoneInput
                                        placeholder={t("phone_number_placeholder")}
                                        value={values.mobile}
                                        onChange={val => setFieldValue("mobile", val)}
                                        country={'in'}
                                      />
                                      <Error name="mobile" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("create_password")}</Form.Label>
                                      <PasswordField
                                        placeholder={t("create_password_placeholder")}
                                        name="password"
                                      />
                                      <Error name="password" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("confirm_password")}</Form.Label>
                                      <PasswordField
                                        placeholder={t("confirm_password_placeholder")}
                                        name="password_confirmation"
                                      />
                                      <Error name="password_confirmation" />
                                    </Form.Group>
                                    <div className="auth-btn-sec">
                                      <Button
                                        type="submit"
                                        className="default-btn w-100"
                                        disabled={props.register.buttonDisable}
                                      >
                                        {props.register.buttonDisable ?
                                          t("loading") : t("create_account")
                                        }
                                      </Button>
                                    </div>
                                  </FORM>
                                }
                              </Formik>
                            </Tab.Pane>
                            <Tab.Pane eventKey="email">
                              <Formik
                                initialValues={{
                                  name: "",
                                  email: "",
                                  password: "",
                                  password_confirmation: "",
                                }}
                                validationSchema={emailRegisterSchema}
                                onSubmit={(values) => handleEmailRegister(values)}
                              >
                                {({ touched, errors, values, setFieldValue }) =>
                                  <FORM className="auth-form">
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("name")}</Form.Label>
                                      <Field
                                        className="form-control"
                                        placeholder={t("name_placeholder")}
                                        name="name"
                                      />
                                      <Error name="name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                      <Form.Label>{t("email_address")}</Form.Label>
                                      <Field
                                        className="form-control"
                                        placeholder={t("email_address_placeholder")}
                                        name="email"
                                      />
                                      <Error name="email" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("create_password")}</Form.Label>
                                      <PasswordField
                                        placeholder={t("create_password_placeholder")}
                                        name="password"
                                      />
                                      <Error name="password" />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                      <Form.Label>{t("confirm_password")}</Form.Label>
                                      <PasswordField
                                        placeholder={t("confirm_password_placeholder")}
                                        name="password_confirmation"
                                      />
                                      <Error name="password_confirmation" />
                                    </Form.Group>
                                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                                      <Link to="#" className="referral-link">
                                        I have a refferral code
                                      </Link>
                                    </Form.Group> */}
                                    <div className="auth-btn-sec">
                                      <Button
                                        type="submit"
                                        className="default-btn w-100"
                                        disabled={props.register.buttonDisable}
                                      >
                                        {props.register.buttonDisable ?
                                          t("loading") : t("create_account")
                                        }
                                      </Button>
                                    </div>
                                  </FORM>
                                }
                              </Formik>
                            </Tab.Pane>
                          </Tab.Content>
                          <div className="divider"></div>
                          <div className="auth-footer-sec">
                            <h5>{t("already_have_account")}</h5>
                            <Link to="/login">{t("login")}</Link>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </Col>
              </Row>
          </Container>
        </div>
      </section>
      {verification ?
        <VerificationModal
          closeModal={closeVerificationModal}
          username={username}
          verificationType={verificationType}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  register: state.user.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(RegisterIndex));
