import React from 'react';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import ActiveOffersSec from './ActiveOffersSec';
import { useTranslation, withTranslation } from 'react-multi-lang';
import SomethingWentWrong from '../../Common/SomethingWentWrong';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';


const ActiveOffersIndex = (props) => {
  const t = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  return (
    <Container>
      {params.username || (!props.profile.loading && props.profile.data.username) ?
        <ActiveOffersSec
          username={params.username ? params.username : props.profile.data.username}
          isInfinite={true}
        />
        : !props.profile.loading ?
          <SomethingWentWrong
            showReload={true}
            reloadText={t("go_to_home")}
            onReload={() => navigate("/")}
          />
          : null
      }
    </Container>
  );

}

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  otherUser: state.user.otherUser,
  activeTokenOffers: state.offer.activeTokenOffers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ActiveOffersIndex));