import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Page404.scss';

const Page404 = () => {

  return (
    <div className="glitch-container">
      <div className="container-404">
        <div className="glitch" data-text="404">404</div>
        <div className="glow">404</div>
        <p className="subtitle-404">Page Not Found</p>
      </div>
      <div className="scanlines-404"></div>
      <div className="m-5 header-404">
        <Link className="navbar-band" to="/">
          <Image
            className="logo"
            src={window.location.origin + "/assets/img/p2p-logo.png"}
          />
        </Link>
      </div>
    </div>
  );
}

export default Page404;