import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { getCommonSaga, getTraderCommonSaga, getTraderGetSaga } from '../sagaUtils';
import store from '../index';
import {
  fetchSingleWalletTransactionFailure,
  fetchSingleWalletTransactionSuccess,
  fetchWalletDetailsFailure,
  fetchWalletDetailsSuccess,
  fetchWalletTransactionListFailure,
  fetchWalletTransactionListSuccess,
  transferTokenFailure,
  transferTokenSuccess,
  initiateUSDTPaymetFailure,
  initiateUSDTPaymetSuccess,
  receiveTokenFailure,
  receiveTokenSuccess,
  fetchWalletPaymentListSuccess,
  fetchWalletPaymentListFailure,
  sendAmountSuccess,
  sendAmountFailure,
  adminWalletDetailsSuccess,
  adminWalletDetailsFailure,
  adminPaymentDetailsSuccess,
  adminPaymentDetailsFailure,
  upiListSuccess,
  upiListFailure,
  upiSaveSuccess,
  upiSaveFailure,
  upiDeleteSuccess,
  upiDeleteFailure,
  bankAccountListSuccess,
  bankAccountListFailure,
  bankAccountSaveSuccess,
  bankAccountSaveFailure,
  bankAccountDeleteSuccess,
  bankAccountDeleteFailure,
  paymentMethodsSuccess,
  paymentMethodsFailure,
  paymentMethodsUpdateSuccess,
  paymentMethodsUpdateFailure,
  checkPaymentMethodFailure,
  checkPaymentMethodSuccess,
  transactionInitiateFailure,
  transactionInitiateSuccess,
  fetchUserTransactionListFailure,
  fetchUserTransactionListSuccess,
} from '../slices/WalletSlice';

function* fetchWalletDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_view",
    payload: action.payload,
    successNotify: false,
    success: fetchWalletDetailsSuccess,
    failure: fetchWalletDetailsFailure,
  });
}

function* fetchSingleWalletTransactionAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_transactions_view",
    payload: action.payload,
    successNotify: false,
    success: fetchSingleWalletTransactionSuccess,
    failure: fetchSingleWalletTransactionFailure,
  });
}

function* transferTokenAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_transfer_tokens",
    payload: action.payload,
    successNotify: true,
    success: transferTokenSuccess,
    failure: transferTokenFailure,
  });
}

function* initiateUSDTPaymetAPI(action) {
  yield getCommonSaga({
    apiUrl: "initiate_usdt_payment",
    payload: action.payload,
    successNotify: false,
    success: initiateUSDTPaymetSuccess,
    failure: initiateUSDTPaymetFailure,
  });
}

function* receiveTokenAPI(action) {
  yield getCommonSaga({
    apiUrl: "receive_tokens",
    payload: action.payload,
    successNotify: true,
    success: receiveTokenSuccess,
    failure: receiveTokenFailure,
  });
}

function* fetchMoreWalletTransactionAPI(action) {
  const walletTransactionListData =  yield select(state=>state.wallet.walletTransactionList.data)
  yield getCommonSaga({
    apiUrl: "wallet_transactions_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => store.dispatch(fetchWalletTransactionListSuccess({
        wallet_transactions:[...walletTransactionListData.wallet_transactions, ...data.wallet_transactions],
        total_wallet_transactions:data.total_wallet_transactions,
      })),
    failure: fetchWalletTransactionListFailure,
  });
}

function* fetchWalletPaymentListAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_payments_list",
    payload: action.payload,
    successNotify: false,
    success: fetchWalletPaymentListSuccess,
    failure: fetchWalletPaymentListFailure,
  });
}

function* sendAmountAPI(action) {
  const adminPaymentDetailsData =  yield select(state=>state.wallet.adminPaymentDetails.data)
  yield getTraderCommonSaga({
    apiUrl: "traders/update_wallet",
    payload: action.payload,
    successNotify: false,
    // success: sendAmountSuccess,
    successCallback: true,
    success: data => {
      store.dispatch(sendAmountSuccess(data));
      if(Object.keys(adminPaymentDetailsData).length > 0) {
        store.dispatch(adminPaymentDetailsSuccess({
          trader_payments: [data.trader_wallet_payment, ...adminPaymentDetailsData.trader_payments, ],
          total_payments: adminPaymentDetailsData.total_payments+1,
          trader_wallet: adminPaymentDetailsData.trader_wallet
        }))
      }
    },
    failure: sendAmountFailure,
  });
}

function* adminWalletDetailsAPI(action) {
  yield getTraderGetSaga({
    apiUrl: "admin_wallet_details",
    payload: action.payload,
    successNotify: false,
    success: adminWalletDetailsSuccess,
    failure: adminWalletDetailsFailure,
  });
}

function* adminPaymentDetailsAPI(action) {
  const adminPaymentDetailsData =  yield select(state=>state.wallet.adminPaymentDetails.data)
  yield getTraderCommonSaga({
    apiUrl: "traders/payments",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      if (Object.keys(adminPaymentDetailsData).length > 0)
      {
        store.dispatch(adminPaymentDetailsSuccess({
          ...adminPaymentDetailsData,
          trader_payments: [...adminPaymentDetailsData.trader_payments, ...data.trader_payments],
          total_payments: data.total_payments,
        }));
      }
      else
      store.dispatch(adminPaymentDetailsSuccess(data));
    },
    failure: adminPaymentDetailsFailure,
  });
}

function* upiListAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "upis",
    payload: action.payload,
    successNotify: false,
    success: upiListSuccess,
    failure: upiListFailure,
  });
}

function* upiSaveAPI(action) {
  const upiListData =  yield select(state=>state.wallet.upiList.data)
  yield getTraderCommonSaga({
    apiUrl: "upis/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(upiSaveSuccess(data))
      console.log(Object.keys(upiListData).length > 0, upiListData)
      if(Object.keys(upiListData).length > 0) {
        store.dispatch(upiListSuccess({
          upis: action.payload.trader_upi_id ? upiListData.upis.map(upi => upi.trader_upi_id === action.payload.trader_upi_id ? data.upi : upi) : [data.upi, ...upiListData.upis, ],
          total_upis: action.payload.trader_upi_id ? upiListData.total_upis : upiListData.total_upis+1,
        }))
      }
      else
      {
        store.dispatch(upiListSuccess({
          upis: [data.upi],
          total_upis: 1,
        }))
      }
    },
    failure: upiSaveFailure,
  });
}

function* upiDelete(action) {
  const upiListData =  yield select(state => state.wallet.upiList.data)
  yield getTraderCommonSaga({
    apiUrl: "upis/destroy",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(upiDeleteSuccess(data))
      if(Object.keys(upiListData).length > 0){
        store.dispatch(upiListSuccess({
          upis: upiListData.upis.filter(upi=> upi.trader_upi_id != action.payload.trader_upi_id),
          total_upis: upiListData.total_upis-1,
        }))
      }
    },
    failure: upiDeleteFailure,
  });
}

function* bankAccountListAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "bank_accounts",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(bankAccountListSuccess(data))
    },
    failure: bankAccountListFailure,
  });
}

function* bankAccountSaveAPI(action) {
  const bankAccountListData =  yield select(state=>state.wallet.bankAccountList.data)
  yield getTraderCommonSaga({
    apiUrl: "bank_accounts/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(bankAccountSaveSuccess(data))
      if(Object.keys(bankAccountListData).length > 0){
        store.dispatch(bankAccountListSuccess({
          bank_accounts: action.payload.trader_bank_account_id ? bankAccountListData.bank_accounts.map((account)=> account.trader_bank_account_id === action.payload.trader_bank_account_id ? data.trader_bank_account : account ) : [ data.trader_bank_account, ...bankAccountListData.bank_accounts],
          total_bank_accounts: action.payload.trader_bank_account_id ? bankAccountListData.total_bank_accounts : bankAccountListData.total_bank_accounts+1,
        }))
      }
      else
      store.dispatch(bankAccountListSuccess(data))
    },
    failure: bankAccountSaveFailure,
  });
}

function* bankAccountDeleteAPI(action) {
  const bankAccountListData =  yield select(state => state.wallet.bankAccountList.data)
  yield getTraderCommonSaga({
    apiUrl: "bank_accounts/destroy",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(bankAccountDeleteSuccess(data))
      if(Object.keys(bankAccountListData).length > 0){
        store.dispatch(bankAccountListSuccess(data))
      }
    },
    failure: bankAccountDeleteFailure,
  });
}

function* paymentMethodsAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "payment_methods",
    payload: action.payload,
    successNotify: false,
    success: paymentMethodsSuccess,
    failure: paymentMethodsFailure,
  });
}

function* paymentMethodsUpdateAPI(action) {
  const paymentMethodsData =  yield select(state=>state.wallet.paymentMethods.data)
  yield getTraderCommonSaga({
    apiUrl: "payment_methods/store",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(paymentMethodsUpdateSuccess(data))
      if(Object.keys(paymentMethodsData).length > 0){
        store.dispatch(paymentMethodsSuccess({
          payment_methods: paymentMethodsData.payment_methods.map((paymentMethod)=> paymentMethod.type == action.payload.type ? data.payment_method : paymentMethod ),
        }))
      }
    },
    failure: paymentMethodsUpdateFailure,
  });
}

function* checkPaymentMethodAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "check_payment_method",
    payload: action.payload,
    successNotify: false,
    success: checkPaymentMethodSuccess,
    failure: checkPaymentMethodFailure,
  });
}

function* transactionInitiateAPI(action) {
  yield getTraderCommonSaga({
    apiUrl: "transactions/initiate",
    payload: action.payload,
    successNotify: false,
    success: transactionInitiateSuccess,
    failure: transactionInitiateFailure,
  });
}

function* fetchMoreUserTransactionAPI(action) {
  const userTransactionListData =  yield select(state=>state.wallet.userTransactionList.data)
  yield getTraderCommonSaga({
    apiUrl: "user_transactions",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: (data) => store.dispatch(fetchUserTransactionListSuccess({
        transactions: [...userTransactionListData.transactions, ...data.transactions],
        total_transactions: data.total_transactions,
      })),
    failure: fetchUserTransactionListFailure,
  });
}

export default function* WalletSaga() {
  yield all([
    yield takeLatest('wallet/fetchWalletDetailsStart', fetchWalletDetailsAPI),
    yield takeLatest('wallet/fetchWalletTransactionListStart', fetchMoreWalletTransactionAPI),
    yield takeLatest('wallet/fetchMoreWalletTransaction', fetchMoreWalletTransactionAPI),
    yield takeLatest('wallet/fetchSingleWalletTransactionStart', fetchSingleWalletTransactionAPI),
    yield takeLatest('wallet/transferTokenStart', transferTokenAPI),
    yield takeLatest('wallet/initiateUSDTPaymetStart', initiateUSDTPaymetAPI),
    yield takeLatest('wallet/receiveTokenStart', receiveTokenAPI),
    yield takeLatest('wallet/fetchWalletPaymentListStart', fetchWalletPaymentListAPI),
    yield takeLatest('wallet/sendAmountStart', sendAmountAPI),
    yield takeLatest('wallet/adminWalletDetailsStart', adminWalletDetailsAPI),
    yield takeLatest('wallet/adminPaymentDetailsStart', adminPaymentDetailsAPI),
    yield takeLatest('wallet/moreAdminPaymentDetailsStart', adminPaymentDetailsAPI),
    yield takeLatest('wallet/upiListStart', upiListAPI),
    yield takeLatest('wallet/upiSaveStart', upiSaveAPI),
    yield takeLatest('wallet/upiDeleteStart', upiDelete),
    yield takeLatest('wallet/bankAccountListStart', bankAccountListAPI),
    yield takeLatest('wallet/bankAccountSaveStart', bankAccountSaveAPI),
    yield takeLatest('wallet/bankAccountDeleteStart', bankAccountDeleteAPI),
    yield takeLatest('wallet/paymentMethodsStart', paymentMethodsAPI),
    yield takeLatest('wallet/paymentMethodsUpdateStart', paymentMethodsUpdateAPI),
    yield takeLatest("wallet/checkPaymentMethodStart", checkPaymentMethodAPI),
    yield takeLatest("wallet/transactionInitiateStart", transactionInitiateAPI),
    yield takeLatest('wallet/fetchUserTransactionListStart', fetchMoreUserTransactionAPI),
    yield takeLatest('wallet/fetchMoreUserTransaction', fetchMoreUserTransactionAPI),
  ]);
}