import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Table,
  Button,
  Tab,
} from "react-bootstrap";
import "./Transaction.css";
import { Link } from "react-router-dom";
import SubmitFeedbackModal from "./SubmitFeedbackModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchMoreOfferTransactionListStart,
  fetchOfferTransactionListStart,
  updateBuyTokenStatusStart,
  updateUsdtTokenStatusStart
} from "../../store/slices/OfferSlice";
import {
  fetchUserTransactionListStart,
  fetchMoreUserTransaction
} from '../../store/slices/WalletSlice';
import NoDataFound from "../Common/NoDataFound";
import PageLoader from "../Common/PageLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import CenterLoader from "../Common/CenterLoader";
import SomethingWentWrong from "../Common/SomethingWentWrong";
import ApproveTransactionModal from "./ApproveTransactionModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import { t } from "react-multi-lang";
import ApproveUsdtTransactionModal from "./ApproveUsdtTransactionModal";
import DeclineUsdtTransactionModal from "./DeclineUsdtTransactionModal";

const TransactionIndex = (props) => {
  const dispatch = useDispatch();
  const offerTransactionList = useSelector(
    (state) => state.offer.offerTransactionList
  );
  const updateBuyTokenStatus = useSelector(
    (state) => state.offer.updateBuyTokenStatus
  );
  const profile = useSelector(
    (state) => state.user.profile
  );
  const userTransactions = useSelector(
    (state) => state.wallet.userTransactionList
  );

  const [type, setType] = useState("sent");

  const [transactionDetail, setTransactionDetail] = useState(false);
  const [usdtTransaction, setUsdtTransaction] = useState(false);
  const [usdtDeclineTransaction, setUsdtDeclineTransaction] = useState(false);

  const closeTransactionModal = () => {
    setTransactionDetail(false);
  };
  
  const closeUsdtTransactionModal = () => {
    setUsdtTransaction(false);
  };

  const closeUsdtDeclineTransactionModal = () => {
    setUsdtDeclineTransaction(false);
  };

  useEffect(() => {
    let data = { skip: 0, take: 12 };
    if(type == 'usdt') {
      data = { ...data, trader_unique_id: profile.data.reference_unique_id };
      dispatch(fetchUserTransactionListStart(data));
    }else if (type) {
      data = { ...data, type };
      dispatch(fetchOfferTransactionListStart(data));
    }
  }, [type]);

  const fetchMoreTransactions = () => {
    if (type == "usdt") {
      dispatch(
        fetchMoreUserTransaction({
          trader_unique_id: profile.data.reference_unique_id,
          skip: userTransactions.data.transactions.length,
          take: 12,
        })
      );
    } else {
      dispatch(
        fetchMoreOfferTransactionListStart({
          skip: offerTransactionList.data.offer_transactions.length,
          take: 12,
        })
      );
    }
  };

  const [submitFeedback, setSubmitFeedback] = useState(null);

  const closeSubmitFeedbackModal = () => {
    setSubmitFeedback(null);
  };

  return (
    <>
      <div className="trade-sec setting-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="list-table-sec transaction-table-sec">
                <Tab.Container id="left-tabs-example" defaultActiveKey={type}>
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="sent"
                            onClick={() =>
                              setType(type === "sent" ? "" : "sent")
                            }
                          >
                           {t("sent")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="received"
                            onClick={() =>
                              setType(type === "received" ? "" : "received")
                            }
                          >
                           {t("received")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="usdt"
                            onClick={() =>
                              setType("usdt")
                            }
                          >
                           {t("usdt_received")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    {type == "usdt" ? (
                      userTransactions.loading ? (
                        <PageLoader />
                      ) : userTransactions.data.transactions ? (
                        userTransactions.data.transactions.length >
                        0 ? (
                          <InfiniteScroll
                            dataLength={
                              userTransactions.data.transactions.length
                            }
                            next={fetchMoreTransactions}
                            hasMore={
                              userTransactions.data.transactions
                                .length <
                              userTransactions.data.total_transactions
                            }
                            loader={
                              <div className="d-flex justify-content-center mb-5">
                                <CenterLoader />
                              </div>
                            }
                          >
                            <Col sm={12}>
                              <Table>
                                <thead>
                                  <tr>
                                    <th>{t("transaction_id")}</th>
                                    <th>{t("price")}</th>
                                    <th>{t("transaction_details")}</th>
                                    <th>{t("Payment")}</th>
                                    <th>{t("action")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userTransactions.data.transactions.map(
                                    (transaction, i) => (
                                      <tr key={i}>
                                        <td>
                                          <div className="list-user-details">
                                            {/* <div className="list-user-img-sec">
                                              <Image
                                                src={
                                                  transaction.type === "sent"
                                                    ? transaction.from_user
                                                        .picture
                                                    : transaction.buy_user.picture
                                                }
                                              />
                                              <div className="status-online"></div>
                                            </div> */}
                                            <div className="list-user-info">
                                              <h4>
                                                {transaction.user_transaction_unique_id}
                                              </h4>
                                              {/* <p>Seen 7 min ago</p> */}
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="list-price-details">
                                            <div className="price-amount">
                                              {transaction.amount_formatted}{" "}
                                              {/* <span>
                                                ({transaction.amount_formatted})
                                              </span> */}
                                            </div>
                                            <div className="price-convertion-amount">
                                              <p>
                                                <span>{t("paid")}</span>{" "}
                                                {transaction.created_at}
                                              </p>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="transaction-upi-status">
                                            {transaction.payment_method === "UPI" ? (
                                            <h5>
                                             {t("upi_id")}:{" "}
                                              <span>{transaction.payment_details?.upi_id}</span>
                                            </h5>
                                            ) : (
                                              <h5>
                                                {t("account_number")}:{" "}
                                                <span>{transaction.payment_details?.account_number}</span>
                                              </h5>
                                            )}
                                            <h5>
                                              {t("status")}{" "}
                                              <span
                                                className={
                                                  transaction.status === 2
                                                    ? "status-completed"
                                                    : transaction.status === 0
                                                    ? "status-initated"
                                                    : transaction.status === 1 ? "status-paid" : "status-rejected"
                                                }
                                              >
                                                {transaction.status_formatted}
                                              </span>
                                            </h5>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="transaction-upi-status">
                                          {transaction.payment_method === "UPI" ? (
                                            <h5>
                                              {t("utr_number")}:{" "}
                                              <span>
                                                {transaction.payment_details?.utr_number}
                                              </span>
                                            </h5>
                                          ) : (
                                            <h5>
                                              {t("payment_id")}:{" "}
                                              <span>
                                                {transaction.payment_details?.payment_id}
                                              </span>
                                            </h5>
                                          )}
                                            <div className="payment-type-sec">
                                              {transaction.payment_method}
                                            </div>
                                            {type === "received" &&
                                            transaction.payment_picture ? (
                                              <a
                                                className="view-transaction-sec"
                                                href={transaction.payment_picture}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                {t("view_screenshot")}
                                              </a>
                                            ) : null}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="list-action-btn-sec">
                                            {transaction.status === 1 ? (
                                              <>
                                                <Button
                                                  className="buy-btn"
                                                  disabled={
                                                    updateBuyTokenStatus.buttonDisable
                                                  }
                                                  onClick={() => {
                                                    setUsdtTransaction(transaction);
                                                  }}
                                                >
                                                  {t("approve")}
                                                </Button>
                                                <Button
                                                  className="decline-btn"
                                                  disabled={
                                                    updateBuyTokenStatus.buttonDisable
                                                  }
                                                  onClick={() => {
                                                    setUsdtDeclineTransaction(transaction);
                                                  }}
                                                  // onClick={() =>
                                                  //   dispatch(
                                                  //     updateUsdtTokenStatusStart(
                                                  //       {
                                                  //         payment_id: transaction.order_id,
                                                  //         status: 5,
                                                  //       }
                                                  //     )
                                                  //   )
                                                  // }
                                                >
                                                  {updateBuyTokenStatus.buttonDisable &&
                                                  updateBuyTokenStatus.status ===
                                                    5 &&
                                                  updateBuyTokenStatus.data
                                                    .offer_transaction_id ===
                                                    transaction.offer_transaction_id
                                                    ? "Loading"
                                                    : "Decline"}
                                                </Button>
                                              </>
                                            ) : null}
                                            {transaction.wallet_address && (
                                              <CopyToClipboard
                                                text={
                                                  transaction.wallet_address
                                                }
                                                onCopy={() =>
                                                  getSuccessNotificationMessage(
                                                  t("wallet_address_copied")
                                                  )
                                                }
                                              >
                                                <Button className="copy-hash-btn">
                                                  {t("copy_wallet_address")}
                                                </Button>
                                              </CopyToClipboard>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          </InfiniteScroll>
                        ) : (
                          <NoDataFound />
                        )
                      ) : (
                        <SomethingWentWrong />
                      )
                    ) : (
                    offerTransactionList.loading ? (
                      <PageLoader />
                    ) : offerTransactionList.data.offer_transactions ? (
                      offerTransactionList.data.offer_transactions.length >
                      0 ? (
                        <InfiniteScroll
                          dataLength={
                            offerTransactionList.data.offer_transactions.length
                          }
                          next={fetchMoreTransactions}
                          hasMore={
                            offerTransactionList.data.offer_transactions
                              .length <
                            offerTransactionList.data.total_offer_transactions
                          }
                          loader={
                            <div className="d-flex justify-content-center mb-5">
                              <CenterLoader />
                            </div>
                          }
                        >
                          <Col sm={12}>
                            <Table>
                              <thead>
                                <tr>
                                  <th>{t("offer_user")}</th>
                                  <th>{t("price")}</th>
                                  <th>{t("transaction_details")}</th>
                                  <th>{t("Payment")}</th>
                                  <th>{t("action")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {offerTransactionList.data.offer_transactions.map(
                                  (transaction, i) => (
                                    <tr>
                                      <td>
                                        <div className="list-user-details">
                                          <div className="list-user-img-sec">
                                            <Image
                                              src={
                                                transaction.type === "sent"
                                                  ? transaction.from_user
                                                      .picture
                                                  : transaction.buy_user.picture
                                              }
                                            />
                                            <div className="status-online"></div>
                                          </div>
                                          <div className="list-user-info">
                                            <h4>
                                              {transaction.type === "sent"
                                                ? transaction.from_user.name
                                                : transaction.buy_user.name}
                                            </h4>
                                            <p>Seen 7 min ago</p>
                                            {/* <div className="list-liked-user">
                                              <Image
                                                className="coversion-icon"
                                                src={
                                                  window.location.origin +
                                                  "/assets/img/list/like-icon.svg"
                                                }
                                              />
                                              <span>245</span>
                                            </div> */}
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="list-price-details">
                                          <div className="price-amount">
                                            {transaction.token_formatted}{" "}
                                            <span>
                                              ({transaction.amount_formatted})
                                            </span>
                                          </div>
                                          <div className="price-convertion-amount">
                                            <p>
                                              <span>{t("paid")}</span>{" "}
                                              {transaction.paid_date}
                                            </p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="transaction-upi-status">
                                          {transaction.payment_mode === "UPI" ? (
                                          <h5>
                                           {t("upi_id")}:{" "}
                                            <span>{transaction.upi_id}</span>
                                          </h5>
                                          ) : (
                                            <h5>
                                              {t("account_number")}:{" "}
                                              <span>{transaction.user_billing?.account_number}</span>
                                            </h5>
                                          )}
                                          <h5>
                                            {t("status")}{" "}
                                            <span
                                              className={
                                                transaction.status_formatted ===
                                                "Success"
                                                  ? "status-completed"
                                                  : transaction.status_formatted ===
                                                    "Initated"
                                                  ? "status-initated"
                                                  : "status-rejected"
                                              }
                                            >
                                              {transaction.status_formatted}
                                            </span>
                                          </h5>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="transaction-upi-status">
                                          <h5>
                                            {t("payment_id")}{" "}
                                            <span>
                                              {transaction.payment_id}
                                            </span>
                                          </h5>
                                          <div className="payment-type-sec">
                                            {transaction.payment_mode}
                                          </div>
                                          {type === "received" &&
                                          transaction.picture ? (
                                            <a
                                              className="view-transaction-sec"
                                              href={transaction.picture}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {t("view_screenshot")}
                                            </a>
                                          ) : null}
                                          {type === "received" &&
                                          transaction.wallet_address ? (
                                            <span>
                                              {transaction.wallet_address.slice(
                                                0,
                                                3
                                              ) +
                                                "..." +
                                                transaction.wallet_address.slice(
                                                  -3
                                                )}
                                            </span>
                                          ) : null}
                                          {type === "sent" &&
                                          transaction.transaction_hash ? (
                                            <h5>
                                              <span>
                                                {transaction.transaction_hash.slice(
                                                  0,
                                                  10
                                                ) +
                                                  "..." +
                                                  transaction.transaction_hash.slice(
                                                    -8
                                                  )}
                                              </span>
                                            </h5>
                                          ) : null}
                                        </div>
                                      </td>
                                      <td>
                                        {transaction.type === "sent" ? (
                                          <div className="list-action-btn-sec">
                                            <Button
                                              className="buy-btn"
                                              onClick={() =>
                                                setSubmitFeedback(
                                                  transaction.offer_transaction_id
                                                )
                                              }
                                            >
                                              {t("write_feedback")}
                                            </Button>
                                            {transaction.transaction_hash && (
                                              <CopyToClipboard
                                                text={
                                                  transaction.transaction_hash
                                                }
                                                onCopy={() =>
                                                  getSuccessNotificationMessage(
                                                    t("transaction_hash_copied")
                                                  )
                                                }
                                              >
                                                <Button className="copy-hash-btn">
                                                  {t("copy_txt_hash")}
                                                </Button>
                                              </CopyToClipboard>
                                            )}
                                          </div>
                                        ) : (
                                          <div className="list-action-btn-sec">
                                            {transaction.status_formatted ===
                                            "Initated" ? (
                                              <>
                                                <Button
                                                  className="buy-btn"
                                                  disabled={
                                                    updateBuyTokenStatus.buttonDisable
                                                  }
                                                  // onClick={() => dispatch(updateBuyTokenStatusStart({
                                                  //   offer_transaction_id: transaction.offer_transaction_id,
                                                  //   status: 3
                                                  // }))}
                                                  onClick={() => {
                                                    setTransactionDetail(transaction);
                                                  }}
                                                >
                                                  {/* {updateBuyTokenStatus.buttonDisable &&
                                                  updateBuyTokenStatus.status === 3 &&
                                                  updateBuyTokenStatus.data.offer_transaction_id === transaction.offer_transaction_id ?
                                                  "Loading"
                                                  : "Approve"
                                                } */}
                                                  {t("approve")}
                                                </Button>
                                                <Button
                                                  className="decline-btn"
                                                  disabled={
                                                    updateBuyTokenStatus.buttonDisable
                                                  }
                                                  onClick={() =>
                                                    dispatch(
                                                      updateBuyTokenStatusStart(
                                                        {
                                                          offer_transaction_id:
                                                            transaction.offer_transaction_id,
                                                          status: 5,
                                                        }
                                                      )
                                                    )
                                                  }
                                                >
                                                  {updateBuyTokenStatus.buttonDisable &&
                                                  updateBuyTokenStatus.status ===
                                                    5 &&
                                                  updateBuyTokenStatus.data
                                                    .offer_transaction_id ===
                                                    transaction.offer_transaction_id
                                                    ? "Loading"
                                                    : "Decline"}
                                                </Button>
                                              </>
                                            ) : null}
                                            {transaction.wallet_address && (
                                              <CopyToClipboard
                                                text={
                                                  transaction.wallet_address
                                                }
                                                onCopy={() =>
                                                  getSuccessNotificationMessage(
                                                 t("wallet_address_copied")
                                                  )
                                                }
                                              >
                                                <Button className="copy-hash-btn">
                                                  {t("copy_wallet_address")}
                                                </Button>
                                              </CopyToClipboard>
                                            )}
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </InfiniteScroll>
                      ) : (
                        <NoDataFound />
                      )
                    ) : (
                      <SomethingWentWrong />
                    )
                    )}
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {transactionDetail && (
        <ApproveTransactionModal
          transaction={transactionDetail}
          closeTransaction={closeTransactionModal}
        />
      )}
      {usdtTransaction && (
        <ApproveUsdtTransactionModal
          transaction={usdtTransaction}
          closeTransaction={closeUsdtTransactionModal}
        />
      )}
      {usdtDeclineTransaction && (
        <DeclineUsdtTransactionModal
          transaction={usdtDeclineTransaction}
          closeTransaction={closeUsdtDeclineTransactionModal}
        />
      )}
      {submitFeedback ? (
        <SubmitFeedbackModal
          offerTransactionId={submitFeedback}
          closeSubmitFeedbackModal={closeSubmitFeedbackModal}
        />
      ) : null}
    </>
  );
};

export default TransactionIndex;
