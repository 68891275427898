import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  Button,
} from "react-bootstrap";
import "./Wallet.css";
import "../Lists/List.css";
import CustomSelect from "../Common/CustomSelect";
import { withTranslation, useTranslation, t } from "react-multi-lang";
import { connect } from "react-redux";
import {
  fetchWalletTransactionListStart,
  fetchMoreWalletTransaction,
} from "../../store/slices/WalletSlice";
import NoDataFound from "../Common/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";

const WalletTransaction = (props) => {
  const [tokenData, setTokenData] = useState("");

  useEffect(() => {
    setTokenData({ value: props.token_type });
    console.log("token_type", props.token_type);
    props.dispatch(
      fetchWalletTransactionListStart({
        token_type: props.token_type,
        take: 12,
      })
    );
  }, []);

  const fetchMoreTransactions = () => {
    props.dispatch(
      fetchMoreWalletTransaction({
        token_type: props.token_type,
        skip: props.walletTransactionList.data.wallet_transactions.length,
        take: 12,
      })
    );
  };

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} was copied to clipboard!`);
  };

  const chainFilterTransactions = (val, type) => {
    if (type == "token_type") {
      setTokenData(val);
      props.dispatch(
        fetchWalletTransactionListStart({
          token_type: val.value,
          take: 12,
        })
      );
    } else {
      props.dispatch(
        fetchWalletTransactionListStart({
          order_by: val,
          token_type: Object.keys(tokenData).length > 0 ? tokenData?.value : "",
          take: 12,
        })
      );
    }
  };

  return (
    <>
      <div className="list-sec">
        <Container>
          <Row>
            <Col md={12}>
              <Form className="list-form">
                <div className="list-header-sec">
                  <div className="list-header-card">
                    {/* <div className="list-header-item">
                      <div className="select-coin">
                        <CustomSelect
                          icon="token_icon"
                          options={configuration.get("configData.project_tokens_formatted")}
                          value={tokenData}
                          onChange={val => chainFilterTransactions(val, "token_type")}
                        />
                      </div>
                    </div> */}
                    <div className="list-header-item">
                      <div className="list-action-btn-sec">
                        <Button
                          className="list-action-btn"
                          onClick={() =>
                            props.dispatch(
                              fetchWalletTransactionListStart({
                                token_type: props.token_type,
                                take: 12,
                              })
                            )
                          }
                        >
                          <Image
                            className="coversion-icon"
                            src={
                              window.location.origin +
                              "/assets/img/list/refresh-icon.svg"
                            }
                          />
                        </Button>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                            className="list-action-btn"
                          >
                            <Image
                              className="coversion-icon"
                              src={
                                window.location.origin +
                                "/assets/img/list/sort-icon.svg"
                              }
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={(val) =>
                                chainFilterTransactions("price_lh", "order_by")
                              }
                            >
                              Price : Lowest to Highest
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(val) =>
                                chainFilterTransactions("price_hl", "order_by")
                              }
                            >
                              Price : Highest to Lowest
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(val) =>
                                chainFilterTransactions("newest", "order_by")
                              }
                            >
                              Date: Newest
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={(val) =>
                                chainFilterTransactions("oldest", "order_by")
                              }
                            >
                              Date: Oldest
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                {props.walletTransactionList.loading ? (
                  <Skeleton count={4} height={100} />
                ) : props.walletTransactionList.data.wallet_transactions &&
                  props.walletTransactionList.data.wallet_transactions.length >
                    0 ? (
                  <>
                    <InfiniteScroll
                      dataLength={
                        props.walletTransactionList.data.wallet_transactions
                          .length
                      }
                      next={fetchMoreTransactions}
                      hasMore={
                        props.walletTransactionList.data.wallet_transactions
                          .length <
                        props.walletTransactionList.data
                          .total_wallet_transactions
                      }
                      loader={<Skeleton count={4} height={100} />}
                    >
                      <div className="list-table-sec">
                        <Table>
                          <thead>
                            <tr>
                              <th>{t("payment_id")}</th>
                              <th>{t("price")}</th>
                              <th>{t("receiver_wallet")}</th>
                              <th>{t("txt_hash")}</th>
                              <th>{t("paid_at")}</th>
                              <th>{t("status")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {props.walletTransactionList.data.wallet_transactions.map(
                              (transaction) => (
                                <tr>
                                  <td>
                                    <div className="list-user-details">
                                      <div className="list-user-info">
                                        <p>{transaction.payment_id}</p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="list-price-details">
                                      Total Tokens:
                                      <div className="price-convertion-amount">
                                        <strong>
                                          {Number(
                                            transaction.tokens
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 3,
                                          })}{" "}
                                          USDT
                                        </strong>
                                      </div>
                                      User Received:
                                      <div className="price-convertion-amount">
                                        <strong>
                                          {Number(
                                            transaction.user_tokens
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 3,
                                          })}{" "}
                                          USDT
                                        </strong>
                                      </div>
                                      Gas Fee:
                                      <div className="price-convertion-amount">
                                        <strong>
                                          {Number(
                                            transaction.gas_fee
                                          ).toLocaleString(undefined, {
                                            maximumFractionDigits: 3,
                                          })}{" "}
                                          USDT
                                        </strong>
                                      </div>
                                      Amount:
                                      <div className="price-convertion-amount">
                                        <strong>
                                          {transaction.amount_formatted}
                                        </strong>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="limit-transaction-amount">
                                      <h5>
                                        <span>
                                          {transaction.receiver_wallet_address
                                            ? `${transaction.receiver_wallet_address.substr(
                                                0,
                                                5
                                              )}...${transaction.receiver_wallet_address.substr(
                                                transaction
                                                  .receiver_wallet_address
                                                  .length - 4
                                              )}`
                                            : null}
                                          <CopyToClipboard
                                            text={
                                              transaction.receiver_wallet_address
                                            }
                                            onCopy={(e) =>
                                              onCopy(
                                                e,
                                                "Receiver Wallet Address"
                                              )
                                            }
                                          >
                                            <Image
                                              className="copy-icon"
                                              src={
                                                window.location.origin +
                                                "/assets/img/icons/copy-solid.svg"
                                              }
                                            />
                                          </CopyToClipboard>
                                        </span>
                                      </h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="limit-transaction-amount">
                                      <h5>
                                        <span>
                                          {transaction.transaction_hash
                                            ? `${transaction.transaction_hash.substr(
                                                0,
                                                5
                                              )}...${transaction.transaction_hash.substr(
                                                transaction.transaction_hash
                                                  .length - 4
                                              )}`
                                            : "N/A"}
                                          {transaction.transaction_hash && (
                                            <CopyToClipboard
                                              text={
                                                transaction.transaction_hash
                                              }
                                              onCopy={(e) =>
                                                onCopy(e, "Transaction hash")
                                              }
                                            >
                                              <Image
                                                className="copy-icon"
                                                src={
                                                  window.location.origin +
                                                  "/assets/img/icons/copy-solid.svg"
                                                }
                                              />
                                            </CopyToClipboard>
                                          )}
                                        </span>
                                      </h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="limit-transaction-amount">
                                      <h5>
                                        {transaction.paid_date
                                          ? transaction.paid_date
                                          : "N/A"}
                                      </h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="payment-type-sec">
                                      {transaction.trader_status_formatted}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </InfiniteScroll>
                  </>
                ) : (
                  <NoDataFound />
                )}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  walletTransactionList: state.wallet.walletTransactionList,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(WalletTransaction));
