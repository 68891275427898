import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Table,
  Form,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";
import "./List.css";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import CustomSelect from "../Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveTokenOffersStart,
  fetchMoreActiveTokenOffersStart,
  updateBuyData,
} from "../../store/slices/OfferSlice";
import {
  traderOfferListStart,
  moreTraderOfferListStart,
} from "../../store/slices/TraderSlice";
import NoDataFound from "../Common/NoDataFound";
import CenterLoader from "../Common/CenterLoader";
import PageLoader from "../Common/PageLoader";
import SingleOfferRow from "./SingleOfferRow";
import InfiniteScroll from "react-infinite-scroll-component";
import SomethingWentWrong from "../Common/SomethingWentWrong";
import configuration from "react-global-configuration";
import KYCUpdateModal from "../KYC/KYCUpdateModal";
import { useTranslation } from "react-multi-lang";
import TraderSingleOfferRow from "./TraderSingleOfferRow";

const ListIndex = (props) => {
  const dispatch = useDispatch();
  const activeTokenOffers = useSelector(
    (state) => state.offer.activeTokenOffers
  );
  const buyData = useSelector((state) => state.offer.buyData);
  const kycStatusUpdate = useSelector((state) => state.user.kycStatusUpdate);
  const profile = useSelector((state) => state.user.profile);
  const traderOfferList = useSelector((state) => state.trader.traderOfferList);

  const [skipRender, setSkipRender] = useState(true);
  const [error, setError] = useState(false);
  const [showOffers, setShowOffers] = useState(false);
  const t = useTranslation();
  const [tokenTypes, setTokenTypes] = useState([
    {
      api_id: "",
      label: "All",
      token: "",
      token_icon:
        window.location.origin + "/assets/img/create-offers/all-tokens.png",
      value: "",
    },
    ...configuration.get("configData.project_tokens_formatted"),
  ]);

  const [search, setSearch] = useState({
    min: "",
    max: "",
  });
  const [filter, setFilter] = useState({
    min_tokens: "",
    max_tokens: "",
    order_by: "newest",
  });

  const [kyc, setKyc] = useState(false);

  const closeKycModal = () => {
    setKyc(false);
  };

  useEffect(() => {
    console.log("buyData", buyData, buyData?.token_type?.value)
    fetchData();
    if (buyData?.token_type?.value == "" || buyData?.token_type?.value == "USDT" ) {
      setShowOffers(false)
      dispatch(traderOfferListStart({ reference_id: profile.data.reference_id }));
    } else {
      setShowOffers(true)
    }
  }, [buyData, filter]);

  useEffect(() => {
    return () => {
      dispatch(
        updateBuyData({
          ...buyData,
          token_type:
            configuration.get("configData.project_tokens_formatted") &&
            configuration.get("configData.project_tokens_formatted").length > 0
              ? configuration.get("configData.project_tokens_formatted")[0]
              : null,
        })
      );
    };
  }, []);

  const fetchData = () => {
    if (buyData.token_type) {
      let data = { token_type: buyData.token_type.token, ...filter };
      dispatch(fetchActiveTokenOffersStart(data));
    } else {
      dispatch(
        updateBuyData({
          ...buyData,
          token_type: {
            api_id: "",
            label: "All",
            token: "",
            token_icon:
              "https://cms-nearbuycoins.blockstall.com/storage/uploads/sites/d7aa0993fec06453b72cced967c88c5df1f22c01.png",
            value: "",
          },
        })
      );
    }
  };

  const fetchMoreOffers = () => {
    dispatch(
      fetchMoreActiveTokenOffersStart({
        skip: activeTokenOffers.data.token_offers.length,
        take: 12,
      })
    );
  };

  const fetchMoreTraderOffers = () => {
    dispatch(
      moreTraderOfferListStart({
        reference_id: profile.data.reference_id,
        skip: traderOfferList.data.trader_offers.length,
        take: 12,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !kycStatusUpdate.loading &&
      Object.keys(kycStatusUpdate.data).length > 0
    ) {
      closeKycModal();
    }
    // setSkipRender(false);
  }, [kycStatusUpdate]);

  const handleEndDateChange = (e) => {
    setSearch({
      ...search,
      max: e.target.value,
    });
    if (search.min > e.target.value) {
      setError(true);
    } else {
      setError(false);
    }
  };

  useEffect(() => {
    if (!skipRender && !traderOfferList.loading) {
      if (Object.keys(traderOfferList.data).length > 0) {
        if (
          traderOfferList.data.total_sellers == 0 ||
          traderOfferList.data.total_sellers ==
            Object.keys(traderOfferList.data.sellers).length
        )
          setShowOffers(true);
      } else {
        setShowOffers(true);
      }
    }
    setSkipRender(false);
  }, [traderOfferList]);

  return (
    <>
      <div className="list-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="list-header-sec">
                <div className="list-header-card">
                  <div className="list-header-item">
                    {/* <Button className="buy-btn">
                        BUY
                      </Button>
                      <Button className="sell-btn">
                        SELL
                      </Button> */}
                    <div className="select-coin">
                      <CustomSelect
                        icon="token_icon"
                        options={tokenTypes}
                        value={buyData.token_type}
                        onChange={(val) =>
                          dispatch(
                            updateBuyData({ ...buyData, token_type: val })
                          )
                        }
                      />
                    </div>
                    <div className="enter-amount-select-currency">
                      <Form.Group>
                        <InputGroup>
                          <Form.Control
                            placeholder={t("min_token")}
                            value={search.min}
                            onChange={(e) => {
                              setSearch({ ...search, min: e.target.value });
                            }}
                          />
                          <Form.Control
                            placeholder={t("max_token")}
                            value={search.max}
                            onChange={handleEndDateChange}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Button
                        disabled={
                          (search.min && search.min < 1) ||
                          search.min > search.max
                        }
                        onClick={() =>
                          setFilter({
                            ...filter,
                            min_tokens: search.min,
                            max_tokens: search.max,
                          })
                        }
                      >
                        Search
                      </Button>
                    </div>

                    {/* <div className="coversion-currency-sec">
                      <p>1BTC =
                        <span>
                          20.764 USD
                          {/* <Image
                                  className="coversion-icon"
                                  src={
                                      window.location.origin + "/assets/img/landing-page/icons/simple-line-icon.svg"
                                  }
                              /> *
                        </span>
                      </p>
                    </div> */}
                  </div>
                  <div className="list-header-item">
                    <div className="list-action-btn-sec">
                      <Button
                        className="list-action-btn"
                        onClick={() => fetchData()}
                      >
                        <Image
                          className="coversion-icon"
                          src={
                            window.location.origin +
                            "/assets/img/list/refresh-icon.svg"
                          }
                        />
                      </Button>
                      {/* <Button className="list-action-btn">
                            <Image
                                className="coversion-icon"
                                src={
                                    window.location.origin + "/assets/img/list/sort-icon.svg"
                                }
                            />
                          </Button> */}
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="list-action-btn"
                        >
                          <Image
                            className="coversion-icon"
                            src={
                              window.location.origin +
                              "/assets/img/list/sort-icon.svg"
                            }
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              setFilter({ ...filter, order_by: "price_lh" })
                            }
                          >
                            Price : Lowest to Highest
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setFilter({ ...filter, order_by: "price_hl" })
                            }
                          >
                            Price : Highest to Lowest
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setFilter({ ...filter, order_by: "newest" })
                            }
                          >
                            Date : Newest
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() =>
                              setFilter({ ...filter, order_by: "oldest" })
                            }
                          >
                            Date : Oldest
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                {search.min && search.min < 1 ? (
                  <span className="error-msg text-danger select-date">
                    {t("minimum_count_error")}{" "}
                  </span>
                ) : (
                  error && (
                    <span className="error-msg text-danger select-date">
                      {t("minimum_token_error")}
                    </span>
                  )
                )}
              </div>
              {buyData?.token_type?.value == "" || buyData?.token_type?.value == "USDT" ? (traderOfferList.loading ? (
                <PageLoader />
              ) : traderOfferList.data.sellers ? (
                traderOfferList.data.sellers.length > 0 ? (
                  <>
                    <div className="list-title-sec">Trader Offers</div>
                    <div className="list-table-sec">
                      <InfiniteScroll
                        dataLength={traderOfferList.data.sellers.length}
                        next={fetchMoreTraderOffers}
                        hasMore={
                          traderOfferList.data.sellers.length <
                          traderOfferList.data.total_sellers
                        }
                        loader={
                          <div className="d-flex justify-content-center mb-5">
                            <CenterLoader />
                          </div>
                        }
                      >
                        <Table>
                          <tbody>
                            {traderOfferList.data.sellers.map(
                              (activeOffer, i) => (
                                <TraderSingleOfferRow
                                    activeOffer={activeOffer}
                                    i={i}
                                    key={i}
                                    setKyc={setKyc}
                                  />
                                // <tr key={i}>
                                //   {/* <td>{i + 1}</td> */}
                                //   <td>{activeOffer.trader_unique_id}</td>
                                //   <td>{activeOffer.name}</td>
                                //   <td>{activeOffer.email}</td>
                                //   <td>{activeOffer.offer_amount_formatted}</td>
                                //   <td>
                                //     <button className="default-btn">Buy</button>
                                //   </td>
                                // </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </>
                ) : (
                  <NoDataFound />
                )
              ) : (
                <SomethingWentWrong />
              )) : null}
              {showOffers &&
                (activeTokenOffers.loading ? (
                  <PageLoader />
                ) : activeTokenOffers.data.token_offers ? (
                  activeTokenOffers.data.token_offers.length > 0 ? (
                    <>
                      <div className="list-title-sec">Promoted Offers</div>
                      <div className="list-table-sec">
                        <InfiniteScroll
                          dataLength={
                            activeTokenOffers.data.token_offers.length
                          }
                          next={fetchMoreOffers}
                          hasMore={
                            activeTokenOffers.data.token_offers.length <
                            activeTokenOffers.data.total_token_offers
                          }
                          loader={
                            <div className="d-flex justify-content-center mb-5">
                              <CenterLoader />
                            </div>
                          }
                        >
                          <Table>
                            <thead>
                              <tr>
                                <th>Buy From</th>
                                <th>Price</th>
                                <th>Limit</th>
                                <th>Transfer Speed</th>
                                {/* <th>Payment</th> */}
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {activeTokenOffers.data.token_offers.map(
                                (activeOffer, i) => (
                                  <SingleOfferRow
                                    activeOffer={activeOffer}
                                    i={i}
                                    key={i}
                                    setKyc={setKyc}
                                  />
                                )
                              )}
                              {/* <tr>
                      <td>
                        <div className="list-user-details">
                          <div className="list-user-img-sec">
                            <p>P</p>
                            <div className="status-online"></div>
                          </div>
                          <div className="list-user-info">
                            <h4>User Name</h4>
                            <p>Seen 7 min ago</p>
                            <div className="list-liked-user">
                              <Image
                                className="coversion-icon"
                                src={
                                  window.location.origin + "/assets/img/list/like-icon.svg"
                                }
                              />
                              <span>
                                245
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="list-price-details">
                          <div className="price-amount">
                            20.24k <span>USD</span>
                          </div>
                          <div className="price-convertion-amount">
                            <p>1 USD = 1.03 USD of BTC</p>
                          </div>
                          <div className="price-convertion-percentage">
                            <Image
                              className="price-convertion-percentage-icon"
                              src={
                                window.location.origin + "/assets/img/list/arrow-down-icon.svg"
                              }
                            />
                            <span>2%</span>
                            <Image
                              className="price-convertion-percentage-icon"
                              src={
                                window.location.origin + "/assets/img/list/info-icon.svg"
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="limit-transaction-amount">
                          <h5>Available <span>70000 USDT</span></h5>
                          <h5>Limit <span>1000.00 - 7,100.00 INR</span></h5>
                        </div>
                      </td>
                      <td>
                        <div className="list-transfer-speed">
                          1 Minute
                        </div>
                      </td>
                      <td>
                        <div className="payment-type-sec">
                          UPI
                        </div>
                      </td>
                      <td>
                        <div className="list-action-btn-sec">
                          <Button className="buy-btn">
                            BUY
                          </Button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="list-user-details">
                          <div className="list-user-img-sec">
                            <p>P</p>
                            <div className="status-online"></div>
                          </div>
                          <div className="list-user-info">
                            <h4>User Name</h4>
                            <p>Seen 7 min ago</p>
                            <div className="list-liked-user">
                              <Image
                                className="coversion-icon"
                                src={
                                  window.location.origin + "/assets/img/list/like-icon.svg"
                                }
                              />
                              <span>
                                245
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="list-price-details">
                          <div className="price-amount">
                            20.24k <span>USD</span>
                          </div>
                          <div className="price-convertion-amount">
                            <p>1 USD = 1.03 USD of BTC</p>
                          </div>
                          <div className="price-convertion-percentage">
                            <Image
                              className="price-convertion-percentage-icon"
                              src={
                                window.location.origin + "/assets/img/list/arrow-down-icon.svg"
                              }
                            />
                            <span>2%</span>
                            <Image
                              className="price-convertion-percentage-icon"
                              src={
                                window.location.origin + "/assets/img/list/info-icon.svg"
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="limit-transaction-amount">
                          <h5>Available <span>70000 USDT</span></h5>
                          <h5>Limit <span>1000.00 - 7,100.00 INR</span></h5>
                        </div>
                      </td>
                      <td>
                        <div className="list-transfer-speed">
                          1 Minute
                        </div>
                      </td>
                      <td>
                        <div className="payment-type-sec">
                          UPI
                        </div>
                      </td>
                      <td>
                        <div className="list-action-btn-sec">
                          <Button className="buy-btn">
                            BUY
                          </Button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="list-user-details">
                          <div className="list-user-img-sec">
                            <p>P</p>
                            <div className="status-online"></div>
                          </div>
                          <div className="list-user-info">
                            <h4>User Name</h4>
                            <p>Seen 7 min ago</p>
                            <div className="list-liked-user">
                              <Image
                                className="coversion-icon"
                                src={
                                  window.location.origin + "/assets/img/list/like-icon.svg"
                                }
                              />
                              <span>
                                245
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="list-price-details">
                          <div className="price-amount">
                            20.24k <span>USD</span>
                          </div>
                          <div className="price-convertion-amount">
                            <p>1 USD = 1.03 USD of BTC</p>
                          </div>
                          <div className="price-convertion-percentage">
                            <Image
                              className="price-convertion-percentage-icon"
                              src={
                                window.location.origin + "/assets/img/list/arrow-down-icon.svg"
                              }
                            />
                            <span>2%</span>
                            <Image
                              className="price-convertion-percentage-icon"
                              src={
                                window.location.origin + "/assets/img/list/info-icon.svg"
                              }
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="limit-transaction-amount">
                          <h5>Available <span>70000 USDT</span></h5>
                          <h5>Limit <span>1000.00 - 7,100.00 INR</span></h5>
                        </div>
                      </td>
                      <td>
                        <div className="list-transfer-speed">
                          1 Minute
                        </div>
                      </td>
                      <td>
                        <div className="payment-type-sec">
                          UPI
                        </div>
                      </td>
                      <td>
                        <div className="list-action-btn-sec">
                          <Button className="buy-btn">
                            BUY
                          </Button>
                        </div>
                      </td>
                    </tr> */}
                            </tbody>
                          </Table>
                        </InfiniteScroll>
                      </div>
                    </>
                  ) : (
                    <NoDataFound />
                  )
                ) : (
                  <SomethingWentWrong />
                ))}
            </Col>
          </Row>
        </Container>
      </div>
      {kyc && <KYCUpdateModal kyc={kyc} closeKyc={closeKycModal} />}
    </>
  );
};

export default ListIndex;
