import React, { useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import { Image, InputGroup } from 'react-bootstrap';

export const Error = ({ name }) => {
  return (
    <ErrorMessage name={name}>
      {msg => <ErrorMsg msg={msg} />}
    </ErrorMessage>
  );
}

export const ErrorMsg = ({ msg }) => <div className="error-sec mt-2">
  <Image className="coversion-icon"
    src={window.location.origin + "/assets/img/auth/info-icon.svg"}
  />
  {msg}
</div>

export const PasswordField = ({ name, placeholder = "" }) => {

  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputGroup>
      <Field
        type={showPassword ? "text" : "password"}
        className="form-control border-right"
        placeholder={placeholder}
        name={name}
      />
      <InputGroup.Text id="basic-addon1" className="border-left">
        <Image
          className="coversion-icon"
          onClick={() => setShowPassword(!showPassword)}
          src={
            !showPassword ?
              window.location.origin + "/assets/img/auth/eye-hide-icon.svg"
              : window.location.origin + "/assets/img/auth/eye-show-icon.svg"
          }
        />
      </InputGroup.Text>
    </InputGroup>
  );
}