import React from 'react';

const Reload = ({ height = "200px", width = "200px" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stop-color="#41C3EC" />
          <stop offset="100%" stop-color="#3ADE99" />
        </linearGradient>
      </defs>
      <g>
        <path d="M50 10A40 40 0 1 0 85.64026096753472 31.840380010418134" fill="none" stroke="url(#linear)" stroke-width="8"></path>
        <path d="M49 -2L49 22L61 10L49 -2" fill="url(#linear)"></path>
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
      </g>
    </svg>
  );
}

export default Reload;