import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialIndex = (props) => {

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        adaptiveHeight: true,
        slidesToShow: 3.5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
              prevArrow: false,
                nextArrow: false
            },
          },
    
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              prevArrow: false,
            nextArrow: false
            },
          },
        ],
      };


    return (
        <>
            <div className="your-experience-sec">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={9}>
                            <div className="section-title">
                                <h2>In Their Own Words</h2>
                                <p>Discover What Our Valued Users Have to Say About Their Journey with Us.</p>
                            </div>
                        </Col>
                    </Row>
                   <Row>
                    <Col md={12}>
                        <div className="testimonial-slider-sec">
                            <Slider {...settings}>
                                <div className="testimonial-slider-item">
                                    <div className="testimonial-slider-card">
                                        <div className="testimonial-slider-header">
                                            <Image
                                                className="testimonial-social-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/twitter-icon.svg"
                                                }
                                            />
                                            <p>I've been an avid user of this platform for a while now, and it's been a complete game-changer for my cryptocurrency trading journey. The ability to buy and sell directly with other users is nothing short of fantastic. The entire process is remarkably smooth, and my interactions with the community here have been consistently positive.</p>
                                        </div>
                                        <div className="testimonial-slider-user-info">
                                            <div className="testimonial-slider-user-img-sec">
                                                <Image
                                                    className="testimonial-social-icon"
                                                    src={
                                                        window.location.origin + "/assets/img/landing-page/user-img.png"
                                                    }
                                                />
                                            </div>
                                            <div className="testimonial-slider-user-content">
                                                <h4>John Davidson</h4>
                                                {/* <p>Marketing Head</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-slider-item">
                                    <div className="testimonial-slider-card">
                                        <div className="testimonial-slider-header">
                                            <Image
                                                className="testimonial-social-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/twitter-icon.svg"
                                                }
                                            />
                                            <p>As a passionate cryptocurrency enthusiast, I greatly value transparency and simplicity in my trades. This platform effortlessly delivers precisely that. The option to buy and sell cryptocurrencies directly with fellow users is a breath of fresh air. I deeply appreciate the intuitive and user-friendly interface and the strong sense of community it fosters among traders.</p>
                                        </div>
                                        <div className="testimonial-slider-user-info">
                                            <div className="testimonial-slider-user-img-sec">
                                                <Image
                                                    className="testimonial-social-icon"
                                                    src={
                                                        window.location.origin + "/assets/img/landing-page/user-img.png"
                                                    }
                                                />
                                            </div>
                                            <div className="testimonial-slider-user-content">
                                                <h4>Sarah Peterson</h4>
                                                {/* <p>Marketing Head</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-slider-item">
                                    <div className="testimonial-slider-card">
                                        <div className="testimonial-slider-header">
                                            <Image
                                                className="testimonial-social-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/twitter-icon.svg"
                                                }
                                            />
                                            <p>My experience with this platform has been transformational for my cryptocurrency trading endeavors. It's fast, secure, and the best part is the autonomy it offers in setting my own trading terms. I've had the pleasure of closing some remarkable deals here and found the community to be not only reliable but also highly responsive.</p>
                                        </div>
                                        <div className="testimonial-slider-user-info">
                                            <div className="testimonial-slider-user-img-sec">
                                                <Image
                                                    className="testimonial-social-icon"
                                                    src={
                                                        window.location.origin + "/assets/img/landing-page/user-img.png"
                                                    }
                                                />
                                            </div>
                                            <div className="testimonial-slider-user-content">
                                                <h4>Mark Lewis</h4>
                                                {/* <p>Marketing Head</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-slider-item">
                                    <div className="testimonial-slider-card">
                                        <div className="testimonial-slider-header">
                                            <Image
                                                className="testimonial-social-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/twitter-icon.svg"
                                                }
                                            />
                                            <p>I'm relatively new to the world of cryptocurrency, but this platform made it incredibly approachable. I've had the opportunity to learn from more experienced traders while actively participating in trading myself. The availability of user reviews and feedback greatly contributes to building trust, making this platform an excellent place to start for newcomers</p>
                                        </div>
                                        <div className="testimonial-slider-user-info">
                                            <div className="testimonial-slider-user-img-sec">
                                                <Image
                                                    className="testimonial-social-icon"
                                                    src={
                                                        window.location.origin + "/assets/img/landing-page/user-img.png"
                                                    }
                                                />
                                            </div>
                                            <div className="testimonial-slider-user-content">
                                                <h4>Lisa Martin</h4>
                                                {/* <p>Marketing Head</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-slider-item">
                                    <div className="testimonial-slider-card">
                                        <div className="testimonial-slider-header">
                                            <Image
                                                className="testimonial-social-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/twitter-icon.svg"
                                                }
                                            />
                                            <p>Having explored various cryptocurrency platforms, I can confidently say that this one stands out for its simplicity and the direct peer-to-peer trading experience it offers. It's truly remarkable how I can easily exchange cryptocurrencies with other users here. The escrow system provides invaluable peace of mind, and the responsive support team ensures that my experience is top-notch</p>
                                        </div>
                                        <div className="testimonial-slider-user-info">
                                            <div className="testimonial-slider-user-img-sec">
                                                <Image
                                                    className="testimonial-social-icon"
                                                    src={
                                                        window.location.origin + "/assets/img/landing-page/user-img.png"
                                                    }
                                                />
                                            </div>
                                            <div className="testimonial-slider-user-content">
                                                <h4>Michael Reynolds</h4>
                                                {/* <p>Marketing Head</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="testimonial-slider-item">
                                    <div className="testimonial-slider-card">
                                        <div className="testimonial-slider-header">
                                            <Image
                                                className="testimonial-social-icon"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/twitter-icon.svg"
                                                }
                                            />
                                            <p>Paxful is a global peer-to-peer platform building a financial system for the 100% — one #Bitcoin at a time. Check out our coverage in renown</p>
                                        </div>
                                        <div className="testimonial-slider-user-info">
                                            <div className="testimonial-slider-user-img-sec">
                                                <Image
                                                    className="testimonial-social-icon"
                                                    src={
                                                        window.location.origin + "/assets/img/landing-page/user-img.png"
                                                    }
                                                />
                                            </div>
                                            <div className="testimonial-slider-user-content">
                                                <h4>Pritam Reddy</h4>
                                                <p>Marketing Head</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </Slider>
                        </div>
                    </Col>
                   </Row>
                </Container>
            </div>
        </>
    );
};

export default TestimonialIndex;
