import React, { useState } from "react";
import { Navbar, Container, Image, Nav, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import configuration from 'react-global-configuration';
import { useTranslation, withTranslation } from "react-multi-lang";

const FooterIndex = (props) => {

  const t =useTranslation();

  return (
    <>
      <footer className="footer-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="footer-bg-img-sec">
                <h3>{t("ready_to_simplify_your_future")}
                  <br></br>
                  <span>
                    {t("lets_start_now")}
                  </span>
                </h3>
                <div className="find-offer-btn-sec">
                  <Link to="/my-offers" className="default-btn">
                    {t("find_offers")}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <div className="footer-sub-sec">
            <Row className="justify-content-around">
              <Col md={4} xl={3} lg={3} className="resp-marg-btm-md">
                <div className="footer-logo-sec">
                  <Image
                    className="footer-logo"
                    src={configuration.get("configData.site_logo")}
                  />
                </div>
              </Col>
              <Col md={8} xl={9} lg={9}>
                <Row className="justify-content-around">
                  {configuration.get("configData.footer_pages1").length > 0 ?
                    <Col md={2} xl={2} lg={2} className="resp-marg-btm-md">
                      <div className="footer-widget">
                        <h4>{t("help")}</h4>
                        <ul className="list-unstyled footer-list">
                          {configuration.get("configData.footer_pages1").map((link, i) =>
                            <li key={i}>
                              <Link to={`/page/${link.static_page_unique_id}`}>
                                {link.title}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </Col>
                    : null}
                  {configuration.get("configData.footer_pages2").length > 0 ?
                    <Col md={3} xl={2} lg={2} className="resp-marg-btm-md">
                      <div className="footer-widget">
                        <h4>{t("about")}</h4>
                        <ul className="list-unstyled footer-list">
                          {configuration.get("configData.footer_pages2").map((link, i) =>
                            <li key={i}>
                              <Link to={`/page/${link.static_page_unique_id}`}>
                                {link.title}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </div>
                    </Col>
                    : null
                  }
                  {/* <Col md={3} xl={2} lg={2} className="resp-marg-btm-md">
                    <div className="footer-widget">
                      <h4>Legal</h4>
                      <ul className="list-unstyled footer-list">
                        <li>
                          <Link to="#">
                            Privacy Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            Terms And Conditions
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col> */}
                  {configuration.get("configData.twitter_link") ||
                    configuration.get("configData.facebook_link") ||
                    configuration.get("configData.youtube_link") ||
                    configuration.get("configData.discord_link") ?
                    <Col md={6} xl={3} lg={3}>
                      <div className="footer-social-widget">
                        <h4>{t("join_our_community")}</h4>
                      </div>
                      <ul className="list-unstyled social-link">
                        {configuration.get("configData.twitter_link") ?
                          <li>
                            <a href={configuration.get("configData.twitter_link")} target="_blank">
                              <Image
                                className="social-link-icon"
                                src={
                                  window.location.origin + "/assets/img/landing-page/icons/twitter-icon.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                        {configuration.get("configData.facebook_link") ?
                          <li>
                            <a href={configuration.get("configData.facebook_link")} target="_blank">
                              <Image
                                className="social-link-icon"
                                src={
                                  window.location.origin + "/assets/img/landing-page/icons/facebook-icon.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                        {configuration.get("configData.youtube_link") ?
                          <li>
                            <a href={configuration.get("configData.youtube_link")} target="_blank">
                              <Image
                                className="social-link-icon"
                                src={
                                  window.location.origin + "/assets/img/landing-page/icons/youtube-icon.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                        {configuration.get("configData.discord_link") ?
                          <li>
                            <a href={configuration.get("configData.discord_link")} target="_blank">
                              <Image
                                className="social-link-icon"
                                src={
                                  window.location.origin + "/assets/img/landing-page/icons/discord-icon.svg"
                                }
                              />
                            </a>
                          </li> : null
                        }
                      </ul>
                    </Col>
                    : null
                  }
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default withTranslation(FooterIndex);
