import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import {
  forgotPasswordStart,
  resendForgotPasswordStart,
  verifyForgotPasswordFailure,
  verifyForgotPasswordStart
} from "../../store/slices/UserSlice";
import CounterTimer from "../helper/CounterTimer";
import OtpInput from "react-otp-input";
import { Error } from '../Common/FormUtils';
import configuration from 'react-global-configuration';

const ForgotPasswordIndex = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [resendEnable, setResendEnable] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [email, setEmail] = useState("");

  const forgotSchema = Yup.object().shape({
    email: Yup.string().required(t("required")),
  });

  const verificationSchema = Yup.object().shape({
    verification_code: Yup.string().required(t("required")),
  });

  const handleForgotPassword = (values) => {
    setEmail(values.email);
    props.dispatch(forgotPasswordStart({
      username: values.email,
    }));
  }

  const hanldeResendOtp = e => {
    e.preventDefault();
    props.dispatch(resendForgotPasswordStart({
      username: email,
    }));
    setResendEnable(false);
  }

  const handleVerifyForgotPassword = (values) => {
    props.dispatch(verifyForgotPasswordStart({
      ...values,
      username: email,
    }));
  }

  useEffect(() => {
    props.dispatch(verifyForgotPasswordFailure());
  }, []);

  useEffect(() => {
    if (!skipRender && !props.forgotPassword.loading && Object.keys(props.forgotPassword.data).length > 0) {
      setStep(2);
      setResendEnable(false);
    }
  }, [props.forgotPassword]);

  useEffect(() => {
    if (!skipRender && !props.resendForgotPassword.loading) {
      if (Object.keys(props.resendForgotPassword.data).length == 0)
        setResendEnable(true);
    }
  }, [props.resendForgotPassword]);

  useEffect(() => {
    if (!skipRender && !props.verifyForgotPassword.loading && Object.keys(props.verifyForgotPassword.data).length > 0) {
      navigate("/reset-password")
    }
    setSkipRender(false);
  }, [props.verifyForgotPassword]);


  return (
    <>
      <section className="auth-sec">
      <div className="vertical-center">
        <Container>  
            <Row className="align-items-center">
              <Col md={7}>
                <div className="auth-img-sec">
                  <Image
                    className="auth-img"
                    src={
                      window.location.origin + "/assets/img/auth/login-img.png"
                    }
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className="auth-box">
                  <div className="auth-logo-sec">
                    <Image
                      className="auth-logo-img"
                      src={configuration.get("configData.site_dark_logo")}
                    />
                  </div>
                  <h3>{t("reset_password")}</h3>
                  {step === 1 ?
                    <Formik
                      initialValues={{
                        email: "",
                      }}
                      validationSchema={forgotSchema}
                      onSubmit={(values) => handleForgotPassword(values)}
                    >
                      {({ touched, errors }) =>
                        <FORM className="auth-form">
                          <Form.Group className="mb-3">
                            <Form.Label>{t("email_or_phone_number")}</Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder={t("email_or_phone_number_placeholder")}
                              name="email"
                            />
                            <Error name="email" />
                          </Form.Group>
                          <div className="auth-btn-sec">
                            <Button
                              type="submit"
                              className="default-btn w-100"
                              disabled={props.forgotPassword.buttonDisable}
                            >
                              {props.forgotPassword.buttonDisable ?
                                t("loading")
                                : t("send_otp")
                              }
                            </Button>
                          </div>
                        </FORM>
                      }
                    </Formik>

                    : <Formik
                      initialValues={{
                        verification_code: "",
                      }}
                      validationSchema={verificationSchema}
                      onSubmit={(values) => handleVerifyForgotPassword(values)}
                    >
                      {({ touched, errors, values, setFieldValue }) =>
                        <FORM className="auth-form">
                          <Form.Group className="mb-3">
                            <Form.Label>{t("email_or_phone_number")}</Form.Label>
                            <Form.Control type="text" disabled={true} value={email} />
                          </Form.Group>
                          <div className="verification-item justify-content-start">
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>{t("verification_code")}</Form.Label>
                              <OtpInput
                                shouldAutoFocus={true}
                                value={values.verification_code}
                                onChange={value => setFieldValue("verification_code", value)}
                                numInputs={6}
                                isInputNum={true}
                                separator={<span>-</span>}
                              />
                              <Error name="verification_code" />
                            </Form.Group>
                          </div>
                          <div className="validate-time-count">
                            {resendEnable ?
                              <h5>{t("didnt_receive_verification_code")}{" "}
                                <Link
                                  to="#"
                                  className="resend-link"
                                  onClick={hanldeResendOtp}
                                >
                                  {t("resend")}
                                </Link>
                              </h5>
                              : <h5>{t("resend_verification_code_in")}{" "}
                                <CounterTimer
                                  time={60000}
                                  render={({ minutes, seconds }) =>
                                    <span>{(minutes < 10 ? "0" : "") + minutes}:{(seconds < 10 ? "0" : "") + seconds} Mins</span>
                                  }
                                  onComplete={() => { setResendEnable(true) }}
                                />
                              </h5>
                            }
                          </div>
                          <div className="auth-btn-sec">
                            <Button
                              type="submit"
                              className="default-btn w-100"
                              disabled={props.verifyForgotPassword.buttonDisable}
                            >
                              {props.verifyForgotPassword.buttonDisable ?
                                t("loading")
                                : t("verify")
                              }
                            </Button>
                          </div>
                        </FORM>
                      }
                    </Formik>
                  }

                  <div className="divider"></div>
                  <div className="auth-footer-sec">
                    <Link to="/login">{t("back_to_login")}</Link>
                  </div>
                </div>
              </Col>
            </Row>
        </Container>
        </div>
      </section>
    </>
  );
};

const mapStateToPros = (state) => ({
  forgotPassword: state.user.forgotPassword,
  resendForgotPassword: state.user.resendForgotPassword,
  verifyForgotPassword: state.user.verifyForgotPassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ForgotPasswordIndex));
