import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./LandingPage.css";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const FinancialSystemIndex = (props) => {

    return (
        <>
            <div className="financial-system-sec">
                <Container>
                    <div className="financial-system-relative">
                        <Row className="align-items-center justify-content-center">
                            <Col md={7} xl={4} lg={4} className="resp-marg-btm-md">
                                <div className="financial-system-title-sec">
                                    <h3>Getting Started?</h3>
                                    <p>Your Roadmap to Navigating Our Platform</p>
                                    <div className="your-experience-btn-sec">
                                        <Link to="/list" className="default-btn">
                                            GET STARTED
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} xl={8} lg={8}>
                                <div className="financial-system-box">
                                    <div className="financial-system-card">
                                        <div className="financial-system-img-sec">
                                            <Image
                                                className="financial-system-img"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/bitcoin-icon.svg"
                                                }
                                            />
                                        </div>
                                        <div className="financial-system-info">
                                            <h4>Buy Crypto</h4>
                                            <p>Purchase Crypto directly from individuals in our peer-to-peer marketplace.</p>
                                        </div>
                                    </div>
                                    <div className="financial-system-card">
                                        <div className="financial-system-img-sec">
                                            <Image
                                                className="financial-system-img"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/bitcoin-icon.svg"
                                                }
                                            />
                                        </div>
                                        <div className="financial-system-info">
                                            <h4>Sell Crypto</h4>
                                            <p>Establish your preferred rates and select your preferred payment method.</p>
                                        </div>
                                    </div>
                                    <div className="financial-system-card">
                                        <div className="financial-system-img-sec">
                                            <Image
                                                className="financial-system-img"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/bitcoin-icon.svg"
                                                }
                                            />
                                        </div>
                                        <div className="financial-system-info">
                                            <h4>Protected Trading</h4>
                                            <p>Your crypto is kept safe within escrow until the trade is successfully executed.</p>
                                        </div>
                                    </div>
                                    <div className="financial-system-card">
                                        <div className="financial-system-img-sec">
                                            <Image
                                                className="financial-system-img"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/bitcoin-icon.svg"
                                                }
                                            />
                                        </div>
                                        <div className="financial-system-info">
                                            <h4> USDL Wallet</h4>
                                            <p>Get access to your free USDL wallet to send, receive, and securely store your crypto.</p>
                                        </div>
                                    </div>
                                    {/* <div className="financial-system-card">
                                        <div className="financial-system-img-sec">
                                            <Image
                                                className="financial-system-img"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/bitcoin-icon.svg"
                                                }
                                            />
                                        </div>
                                        <div className="financial-system-info">
                                            <h4>Buy Bitcoin</h4>
                                            <p>Our guided bank transfer trades quick and easy to buy Bitcoin.</p>
                                        </div>
                                    </div>
                                    <div className="financial-system-card">
                                        <div className="financial-system-img-sec">
                                            <Image
                                                className="financial-system-img"
                                                src={
                                                    window.location.origin + "/assets/img/landing-page/bitcoin-icon.svg"
                                                }
                                            />
                                        </div>
                                        <div className="financial-system-info">
                                            <h4>Buy Bitcoin</h4>
                                            <p>Our guided bank transfer trades quick and easy to buy Bitcoin.</p>
                                        </div>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                   </div>
                </Container>
            </div>
        </>
    );
};

export default FinancialSystemIndex;
