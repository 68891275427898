import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./Trade.css";
import { Link } from "react-router-dom";
import CustomSelect from "../Lists/CustomSelect";
import Select from 'react-select';
import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon";
import Toolbar from "react-multi-date-picker/plugins/toolbar"
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreOfferTransactionListStart, fetchOfferTransactionListStart } from "../../store/slices/OfferSlice";
import PageLoader from "../Common/PageLoader";
import NoDataFound from "../Common/NoDataFound";
import InfiniteScroll from "react-infinite-scroll-component";
import CenterLoader from "../Common/CenterLoader";
import { t } from "react-multi-lang";

const TradeHistoryIndex = (props) => {
  const dispatch = useDispatch();
  const offerTransactionList = useSelector(state => state.offer.offerTransactionList);

  useEffect(() => {
    dispatch(fetchOfferTransactionListStart({ skip: 0, take: 12 }));
  }, []);

  const fetchMoreTransactions = () => {
    dispatch(fetchMoreOfferTransactionListStart({
      skip: offerTransactionList.data.offer_transactions.length,
      take: 12,
    }))
  }

  const options = [
    { value: 'completed', label: 'Completed' },
    { value: 'disputed', label: 'Disputed' },
    { value: 'expired', label: 'Expired' },
    { value: 'cancelled', label: 'Cancelled' }
  ]

  const customStyles = {
    ///.....
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({
      ...provided, zIndex: 9999,
      left: '0px',
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: provided => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      }
    }),
    container: provided => ({ ...provided, width: '100%' }),
    control: provided =>
    ({
      ...provided,
      backgroundColor: '#F5F5F5!important',
      border: '0!important',
      boxShadow: 'none',
      height: '45px',
      minWidth: "250px",

    }),
    singleValue: provided => ({
      ...provided, color: '#989CA1', display: "flex",
      alignItems: "center",
      gap: "0.5em", fontSize: "1.1em", fontWeight: "600"
    }),
    indicatorContainer: provided => ({ ...provided, color: '#4b4b4b!important' }),
    indicatorContainer: provided => ({ ...provided, fill: '#4b4b4b!important' }),
    indicatorSeparator: base => ({
      ...base,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "svg": {
        fill: "#4b4b4b"
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#f7f7f7" : "#fff",
        color: "#000"
      };
    }
  }


  return (
    <>
      <div className="trade-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="trade-history-header-sec">
                <h3>{t("trade_history")}</h3>
                <p>{t("start_creating_your_offer_para")}</p>
              </div>
              <div className="trade-header-box">
                <div className="select-coin">
                  <CustomSelect />
                </div>
                <div className="trade-outcome">
                  <Select
                    options={options}
                    styles={
                      customStyles}
                    // menuIsOpen={true}
                    isSearchable={false}
                  />
                </div>
                <div className="trade-calendar">
                  <DatePicker
                    render={<InputIcon />}
                    numberOfMonths={2}
                    disableMonthPicker
                    disableYearPicker
                    placeholder={t("select_date")}
                    plugins={[
                      <Toolbar position="bottom" />
                    ]}
                  />
                </div>
                <div className="trade-search">
                  <InputGroup className="mb-0">
                    <Form.Control
                      placeholder={t("search_item")}
                    />
                    <InputGroup.Text id="basic-addon2">
                      <Image
                        className="coversion-icon"
                        src={
                          window.location.origin + "/assets/img/trade/search-icon.svg"
                        }
                      />
                    </InputGroup.Text>
                  </InputGroup>

                </div>
              </div>
              {offerTransactionList.loading ?
                <PageLoader />
                : offerTransactionList.data.offer_transactions.length > 0 ?
                  <InfiniteScroll
                    dataLength={offerTransactionList.data.offer_transactions.length}
                    next={fetchMoreTransactions}
                    hasMore={offerTransactionList.data.offer_transactions.length < offerTransactionList.data.total_offer_transactions}
                    loader={<div className="d-flex justify-content-center mb-5">
                      <CenterLoader />
                    </div>}
                  >
                    <div className="trade-history-box">
                      {offerTransactionList.data.offer_transactions.map((transaction, i) =>
                        <>
                          <div className="trade-history-card">
                            <div className="trade-user-details">
                              <div className="trade-user-img-sec">
                                <Image className="trade-user-img" src={transaction.type === "send" ?
                                  transaction.buyUser.picture : transaction.fromUser.picture
                                } />
                                <div className="status-online"></div>
                              </div>
                              <div className="trade-user-info">
                                <h4>{t("domestic_wire_transfer")}</h4>
                                <p>{t("buy_bitcoin")}</p>
                                <p>{transaction.paid_date}</p>
                              </div>
                            </div>
                            <div className="trade-transaction-amount-sec">
                              <div className="trade-transaction-amount">
                                <h5>68 USD <span>0.002565358 BTC</span></h5>
                                <h5>{t("rate")} <span>25,660.68 USD / BTC</span></h5>
                              </div>
                              <div className="trade-transaction-id">
                                <h5>{t("trasaction_id")}<span>ierbvkejr8946549841veoi</span></h5>
                              </div>
                            </div>
                            <div className="trade-transaction-status-card">
                              <div className="trade-transaction-success-status">
                                {t("successfull")}
                              </div>
                              <div className="trade-transaction-expired-status">
                              {t("expired")}
                              </div>
                              <div className="trade-transaction-canceled-status">
                               {t("canceled")}
                              </div>
                              <div className="trade-transaction-status-btn-sec">
                                <Button className="trade-transaction-status-btn">
                                  <Image
                                    className="trade-transaction-status-icon"
                                    src={
                                      window.location.origin + "/assets/img/trade/export-icon.svg"
                                    }
                                  />
                                  <span>{t("export")}</span>
                                </Button>
                                <Button className="trade-transaction-status-btn">
                                  <Image
                                    className="trade-transaction-status-icon"
                                    src={
                                      window.location.origin + "/assets/img/trade/copy-icon.svg"
                                    }
                                  />
                                  <span>{t("copy")}</span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </InfiniteScroll>
                  : <NoDataFound />
              }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TradeHistoryIndex;
