import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import { kycApplicantStart } from "../../store/slices/UserSlice";
import { updateUsdtTokenStatusStart } from "../../store/slices/OfferSlice";
import { useTranslation, withTranslation } from "react-multi-lang";
import { fetchUserTransactionListStart } from "../../store/slices/WalletSlice";
import { getErrorNotificationMessage } from "../helper/ToastNotification";

const DeclineUsdtTransactionModal = (props) => {
  const dispatch = useDispatch();
  const [skipRender, setSkipRender] = useState(true);
  const [cancelReason, setCancelReason] = useState("");
  const t = useTranslation();

  const profile = useSelector((state) => state.user.profile);
  const updateUsdtTokenStatus = useSelector(
    (state) => state.offer.updateUsdtTokenStatus
  );

  const handleSubmit = () => {
    if (cancelReason.trim().length <= 0) {
      getErrorNotificationMessage("Cancel Reason is required")
      return
    }
    dispatch(
      updateUsdtTokenStatusStart({
        payment_id: props.transaction.order_id,
        status: 5,
        cancel_reason: cancelReason
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateUsdtTokenStatus.loading &&
      Object.keys(updateUsdtTokenStatus.data).length > 0
    ) {
      dispatch(
        fetchUserTransactionListStart({
          trader_unique_id: profile.data.reference_unique_id,
          skip: 0,
          take: 12,
        })
      );
      props.closeTransaction();
    }
    setSkipRender(false);
  }, [updateUsdtTokenStatus]);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.transaction}
        onHide={props.closeTransaction}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("decline_transaction")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="auth-form mt-3">
            <Col sm={12}>
              <div className="aadhar-card-verification-sec">
                <Row>
                  <Col md={12}>
                    <p>Cancel Reason</p>
                    <textarea
                      rows={5}
                      name="cancel_reason"
                      onChange={(e) => setCancelReason(e.target.value)}
                      style={{width:"100%"}}
                    >
                      {cancelReason}
                    </textarea>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="kyc-btn-sec">
                      <Button
                        className="next-btn"
                        onClick={handleSubmit}
                        disabled={updateUsdtTokenStatus.buttonDisable}
                      >
                        {updateUsdtTokenStatus.buttonDisable
                          ? "Loading"
                          : "Submit"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeclineUsdtTransactionModal);
