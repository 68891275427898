import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { withTranslation, useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { resendUserVerificationStart, userVerificationStart } from "../../store/slices/UserSlice";
import CounterTimer from "../helper/CounterTimer";

const VerificationModal = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);
  const [otp, setOTP] = useState("");
  const [resendEnable, setResendEnable] = useState(false);

  useEffect(() => {
    if (!skipRender && !props.userVerification.loading && Object.keys(props.userVerification.data).length > 0) {
      navigate("/");
    }
  }, [props.userVerification]);

  useEffect(() => {
    console.log(props.resendUserVerification.data);
    if (!skipRender && !props.resendUserVerification.loading) {
      if (Object.keys(props.resendUserVerification.data).length == 0) {
        setResendEnable(true);
      }
    }
    setSkipRender(false);
  }, [props.resendUserVerification]);

  const handleVerification = e => {
    e.preventDefault();
    props.dispatch(userVerificationStart({
      username: props.username,
      verification_code: otp,
    }));
  }

  const handleResendOtp = e => {
    e.preventDefault();
    setResendEnable(false);
    props.dispatch(resendUserVerificationStart({
      username: props.username,
    }));
  }

  return (
    <>
      <Modal
        className="modal-dialog-center change-number-modal"
        size="md"
        centered
        show={true}
      >
        <Modal.Header closeButton onHide={props.closeModal}>
          <Modal.Title>{props.verificationType === "email" ? t("email_verification") : t("mobile_verification")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="setting-form" onSubmit={handleVerification}>
            <div className="change-number-box">
              <div className="change-number-step-1">
                <div className="change-number-info">
                  <h4>{props.verificationType === "email" ?
                    t("email_verification_heading", { email: props.username })
                    : t("mobile_verification_heading", { mobile: props.username })
                  }</h4>
                  <div className="flex-label">
                    <Form.Label>{t("enter_otp_for_verification")}</Form.Label>
                    <Form.Label >
                      {resendEnable ?
                        <Link to="#" onClick={handleResendOtp} className="text-blue">{t("resend")}</Link>
                        : <CounterTimer
                          time={60000}
                          render={({ minutes, seconds }) =>
                            <span>{(minutes < 10 ? "0" : "") + minutes}:{(seconds < 10 ? "0" : "") + seconds} Mins</span>
                          }
                          onComplete={() => { setResendEnable(true) }}
                        />
                      }
                    </Form.Label>
                  </div>
                  <div className="verification-item">
                    <OtpInput
                      shouldAutoFocus={true}
                      value={otp}
                      onChange={value => setOTP(value)}
                      numInputs={6}
                      separator={<span>-</span>}
                    />
                  </div>
                </div>
                <div className="send-otp-btn-sec">
                  <Button
                    type="submit"
                    className="default-btn w-100"
                    disabled={props.userVerification.buttonDisable || otp.length < 6}
                  >
                    {props.userVerification.buttonDisable ?
                      t("loading")
                      : t("proceed")
                    }
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  userVerification: state.user.userVerification,
  resendUserVerification: state.user.resendUserVerification,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(VerificationModal));