import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import api from '../../Environment';
import { getSuccessNotificationMessage, getErrorNotificationMessage } from '../../components/helper/ToastNotification';
import { getCommonSaga } from '../sagaUtils';
import store from '../index';
import {
  amountConversionFailure,
  amountConversionSuccess,
  buyTokenFailure,
  buyTokenSuccess,
  createTokenOfferFailure,
  createTokenOfferSuccess,
  fetchActiveTokenOffersFailure,
  fetchActiveTokenOffersSuccess,
  fetchOfferTransactionListFailure,
  fetchOfferTransactionListSuccess,
  fetchTokenOffersListFailure,
  fetchTokenOffersListSuccess,
  offerFeedbackListFailure,
  offerFeedbackListSuccess,
  tokenOfferDeleteSuccess,
  tokenOfferViewFailure,
  tokenOfferViewSuccess,
  updateBuyData,
  updateBuyTokenStatusFailure,
  updateBuyTokenStatusSuccess,
  updateUsdtTokenStatusFailure,
  updateUsdtTokenStatusSuccess,
} from '../slices/OfferSlice';


function* fetchActiveTokenOffersAPI(action) {
  const activeTokenOffersData = yield select(state => state.offer.activeTokenOffers.data)
  yield getCommonSaga({
    apiUrl: "active_token_offers_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(fetchActiveTokenOffersSuccess({
        token_offers: [...activeTokenOffersData.token_offers, ...data.token_offers],
        total_token_offers: data.total_token_offers
      }))
    },
    failure: fetchActiveTokenOffersFailure,
  });
}

function* createTokenOfferAPI(action) {
  yield getCommonSaga({
    apiUrl: "create_token_offer",
    payload: action.payload,
    success: createTokenOfferSuccess,
    failure: createTokenOfferFailure,
  })
}

function* amountConvertionAPI(action) {
  const { convertFrom, convertTo, isNetwork = false } = action.payload;
  try {
    const response = yield api.getConversion(convertFrom, isNetwork ? convertTo : "usd");
    if (response.status === 200) {
      if (!isNetwork) {
        const secondResponse = yield api.getConversion(convertTo, "usd");
        if (secondResponse.status === 200) {
          const firstConversion = response.data[convertFrom]["usd"];
          const secondConversion = secondResponse.data[convertTo]["usd"];
          yield put(amountConversionSuccess({ amount: firstConversion / secondConversion }));
        } else {
          yield put(amountConversionFailure(response));
          yield call(getErrorNotificationMessage, "Conversion failed please try again later");
        }
      } else {
        yield put(amountConversionSuccess({ amount: response.data[convertFrom][convertTo] }));
      }
    } else {
      yield put(amountConversionFailure(response));
      yield call(getErrorNotificationMessage, "Conversion failed please try again later");
    }
  } catch (error) {
    yield put(amountConversionFailure(error));
    yield call(getErrorNotificationMessage, error.message);
  }
}

function* fetchTokenOffersListAPI(action) {
  const tokenOffersListData = yield select(state => state.offer.tokenOffersList.data)
  yield getCommonSaga({
    apiUrl: "token_offers_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      if(Object.keys(tokenOffersListData).length > 0)
      {
        store.dispatch(fetchTokenOffersListSuccess({
          token_offers: tokenOffersListData.token_offers ? [...tokenOffersListData.token_offers, ...data.token_offers] : [...data.token_offers],
          total_token_offers: data.total_token_offers
        }))
      }
      else
      store.dispatch(fetchTokenOffersListSuccess(data));
    },
    failure: fetchTokenOffersListFailure,
  })
}

function* fetchOfferTransactionListAPI(action) {
  const offerTransactionListData = yield select(state => state.offer.offerTransactionList.data);
  yield getCommonSaga({
    apiUrl: "offer_transactions_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => store.dispatch(fetchOfferTransactionListSuccess({
      ...data,
      offer_transactions: [...offerTransactionListData.offer_transactions, ...data.offer_transactions]
    })),
    failure: fetchOfferTransactionListFailure,
  })
}

function* buyTokenAPI(action) {
  yield getCommonSaga({
    apiUrl: "buy_tokens",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    failureCallback: true,
    success: data => {
      store.dispatch(buyTokenSuccess({ data: data }));
      store.dispatch(updateBuyData());
    },
    failure: error => {
      store.dispatch(buyTokenFailure(error));
      store.dispatch(updateBuyData());
    },
  })
}

function* updateBuyTokenStatusAPI(action) {
  const offerTransactionListData = yield select(state => state.offer.offerTransactionList.data);
  yield getCommonSaga({
    apiUrl: "buy_token_status_update",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateBuyTokenStatusSuccess(data));
      store.dispatch(fetchOfferTransactionListSuccess({
        ...offerTransactionListData,
        offer_transactions: offerTransactionListData.offer_transactions.map((transaction) =>
          transaction.offer_transaction_id === data.offer_transaction.offer_transaction_id ? data.offer_transaction : transaction
        )
      }));
    },
    failure: updateBuyTokenStatusFailure,
  })
}

function* updateUsdtTokenStatusAPI(action) {
  yield getCommonSaga({
    apiUrl: "usdt_token_status_update",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateUsdtTokenStatusSuccess(data));
    },
    failure: updateUsdtTokenStatusFailure,
  })
}

function* tokenOfferViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "token_offer_view",
    payload: action.payload,
    successNotify: false,
    success: tokenOfferViewSuccess,
    failure: tokenOfferViewFailure,
  })
}

function* tokenOfferDeleteAPI(action) {
  const tokenOffersListData = yield select(state => state.offer.tokenOffersList.data)
  yield getCommonSaga({
    apiUrl: "token_offers_delete",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(tokenOfferDeleteSuccess(data));
      store.dispatch(fetchTokenOffersListSuccess({
        token_offers: tokenOffersListData.token_offers.filter(token => token.token_offer_unique_id != action.payload.token_offer_unique_id),
        total_token_offers: tokenOffersListData.total_token_offers - 1,
      }))
    },
    failure: tokenOfferViewFailure,
  })
}

function* offerFeedbackListAPI(action) {
  const offerFeedbackListData = yield select(state => state.offer.offerFeedbackList.data);
  yield getCommonSaga({
    apiUrl: "token_offer_feed_backs_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => store.dispatch(offerFeedbackListSuccess({ ...data, user_feed_backs: [...offerFeedbackListData.user_feed_backs, ...data.user_feed_backs] })),
    failure: offerFeedbackListFailure,
  })
}

export default function* OfferSaga() {
  yield all([
    yield takeLatest('offer/fetchActiveTokenOffersStart', fetchActiveTokenOffersAPI),
    yield takeLatest('offer/fetchMoreActiveTokenOffersStart', fetchActiveTokenOffersAPI),
    yield takeLatest('offer/amountConversionStart', amountConvertionAPI),
    yield takeLatest('offer/createTokenOfferStart', createTokenOfferAPI),
    yield takeLatest('offer/fetchTokenOffersListStart', fetchTokenOffersListAPI),
    yield takeLatest('offer/fetchMoreTokenOffersListStart', fetchTokenOffersListAPI),
    yield takeLatest('offer/fetchOfferTransactionListStart', fetchOfferTransactionListAPI),
    yield takeLatest('offer/fetchMoreOfferTransactionListStart', fetchOfferTransactionListAPI),
    yield takeLatest('offer/buyTokenStart', buyTokenAPI),
    yield takeLatest('offer/updateBuyTokenStatusStart', updateBuyTokenStatusAPI),
    yield takeLatest('offer/updateUsdtTokenStatusStart', updateUsdtTokenStatusAPI),
    yield takeLatest('offer/tokenOfferViewStart', tokenOfferViewAPI),
    yield takeLatest('offer/tokenOfferDeleteStart', tokenOfferDeleteAPI),
    yield takeLatest('offer/offerFeedbackListStart', offerFeedbackListAPI),
    yield takeLatest('offer/moreOfferFeedbackListStart',offerFeedbackListAPI)
  ]);
}