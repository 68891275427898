import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
import SendAmountModal from "./AddAccountModal";
import AddAccountModal from "./AddAccountModal";
import PageLoader from "../Common/PageLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import CenterLoader from "../Common/CenterLoader";
import NoDataFound from "../Common/NoDataFound";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bankAccountListStart, paymentMethodsStart } from "../../store/slices/WalletSlice";
import DeleteAccountModal from "./DeleteAccountModal";
import { getErrorNotificationMessage } from "../helper/ToastNotification";

const BankAccountIndex = ({ isInfinite = false, ...props }) => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addAccount, setAddAccount] = React.useState(false);
  const [editAccount, setEditAccount] = React.useState(false);
  const [deleteAccount, setDeleteAccount] = React.useState(false);
  const [paymentMeathod, setPaymentMeathod] = useState('unavailable')

  const bankAccountList = useSelector((state) => state.wallet.bankAccountList);
  const profile = useSelector((state) => state.user.profile);
  const paymentMethods = useSelector((state) => state.wallet.paymentMethods);

  const closeAddAccountModal = () => {
    setAddAccount(false);
  };

  const closeEditAccountModal = () => {
    setEditAccount(false);
  };

  const closeDeleteAccountModal = () => {
    setDeleteAccount(false);
  };

  const fetchMoreData = () => {};

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(bankAccountListStart({trader_unique_id: profile.data.reference_unique_id}));
      dispatch(
        paymentMethodsStart({
          trader_unique_id: profile.data.reference_unique_id,
        })
      );
    }
  }, [profile]);

  useEffect(() => {
    if (!paymentMethods.loading && Object.keys(paymentMethods.data).length > 0) {
      const pamentMethod = paymentMethods.data.payment_methods.find((method) => method.type != "UPI" && method.status == 1);
      if(pamentMethod)
        setPaymentMeathod('available');
    }
  }, [paymentMethods])

  return (
    <>
      <div className="profile-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="update-wallet-header-sec">
                <h3>Bank Accounts</h3>
                <div className="find-offer-btn-sec">
                  <Button
                    onClick={() => paymentMeathod !== "available" ? getErrorNotificationMessage(t("bank_payment_meathod_required")) : setAddAccount(true)}
                    className="default-btn"
                  >
                    Add Bank Account
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
            <Col md={12}>
              {bankAccountList.loading ? (
                <PageLoader />
              ) : bankAccountList.data.bank_accounts && bankAccountList.data.bank_accounts.length > 0 ? (
                <div className="active-offers-tab-content-sec">
                  <div className="active-offers-list-table">
                    <div className="list-table-sec">
                      <InfiniteScroll
                        dataLength={bankAccountList.data.bank_accounts.length}
                        next={fetchMoreData}
                        // hasMore={
                        //   bankAccountList.data.upis.length < bankAccountList.data.total_upis
                        // }
                        // loader={
                        //   <div className="d-flex justify-content-center mb-5">
                        //     <CenterLoader />
                        //   </div>
                        // }
                      >
                        <Table>
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>Bank Name</td>
                              <td>Account Number</td>
                              <td>IFSC Code</td>
                              <td>Payment Type</td>
                              <td>Account Type</td>
                              <td>Actions</td>
                            </tr>
                            {bankAccountList.data.bank_accounts.map((item, i) => (
                              <tr key={i}>
                                <td>
                                  <div className="list-transfer-speed">
                                    {item.account_holder_name}
                                  </div>
                                </td>
                                <td>
                                  <div className="list-transfer-speed">
                                    {item.bank_name}
                                  </div>
                                </td>
                                <td>
                                  <div className="list-transfer-speed">
                                    {item.account_number}
                                  </div>
                                </td>
                                <td>
                                  <div className="list-transfer-speed">
                                    {item.ifsc_code}
                                  </div>
                                </td>
                                <td>
                                  <div className="list-transfer-speed">
                                    {item.payment_type}
                                  </div>
                                </td>
                                <td>
                                  <span>{item.account_type}</span>
                                </td>
                                <td>
                                  <Button variant="ghost" onClick={()=> setEditAccount(item)}>
                                    <i className="fas fa-edit"></i>
                                  </Button>
                                  <Button variant="ghost" onClick={() => setDeleteAccount(item.trader_bank_account_id)}>
                                    <i className="fas fa-trash"></i>
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </Col>
            </Col>
          </Row>
        </Container>
      </div>
      {addAccount && (
        <AddAccountModal
          closeAddAccountModal={closeAddAccountModal}
          addAccount={addAccount}
        />
      )}
      {editAccount && (  
        <AddAccountModal edit={1} closeAddAccountModal={closeEditAccountModal} addAccount={editAccount} />
      )}
      {deleteAccount && (
        <DeleteAccountModal deleteAccount={deleteAccount} closeDeleteAccountModal={closeDeleteAccountModal}  />
      )}
    </>
  );
};

export default BankAccountIndex;
