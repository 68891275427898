import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { bankAccountDeleteStart } from "../../store/slices/WalletSlice";

const DeleteAccountModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  const bankAccountDelete = useSelector((state) => state.wallet.bankAccountDelete)
  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (
      !skipRender &&
      !bankAccountDelete.loading &&
      Object.keys(bankAccountDelete.data).length > 0
    ) {
      props.closeDeleteAccountModal();
    }
    setSkipRender(false);
  }, [bankAccountDelete]);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.deleteAccount}
        onHide={props.closeDeleteAccountModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete UPI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="send-amount-sec">
            <React.Fragment>
              <p>
                Are you sure to delete this bank account? It will be deleted permanently.
              </p>
              <div className="btn-sec">
                <Button
                  type="submit"
                  onClick={() => dispatch(bankAccountDeleteStart({ trader_bank_account_id: props.deleteAccount, trader_unique_id: profile.data.reference_unique_id }))}
                  className="default-btn w-100"
                  disabled={bankAccountDelete.buttonDisable}
                >
                  {bankAccountDelete.buttonDisable
                    ? "Loading..."
                    : "Delete"}
                </Button>
              </div>
            </React.Fragment>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(DeleteAccountModal);
