import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { getCommonSaga } from '../sagaUtils';
import store from '../index';
import {
  deleteFeedbackFailure,
  deleteFeedbackSuccess,
  fetchFeedbackListFailure,
  fetchFeedbackListSuccess,
  fetchReceivedFeedbackListFailure,
  fetchReceivedFeedbackListSuccess,
  fetchSingleFeedbackFailure,
  fetchSingleFeedbackSuccess,
  saveFeedbackFailure,
  saveFeedbackSuccess
} from '../slices/FeedbackSlice';

function* saveFeedbackAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_feed_backs_save",
    payload: action.payload,
    success: saveFeedbackSuccess,
    failure: saveFeedbackFailure,
  });
}

function* fetchFeedbackListAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_feed_backs_owner_list",
    payload: action.payload,
    successNotify: false,
    success: fetchFeedbackListSuccess,
    failure: fetchFeedbackListFailure,
  });
}

function* fetchSingleFeedbackAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_feed_backs_view",
    payload: action.payload,
    successNotify: false,
    success: fetchSingleFeedbackSuccess,
    failure: fetchSingleFeedbackFailure,
  });
}

function* deleteFeedbackAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_feed_backs_delete",
    payload: action.payload,
    successNotify: false,
    success: deleteFeedbackSuccess,
    failure: deleteFeedbackFailure,
  });
}

function* fetchReceivedFeedbackListAPI(action) {
  const receivedFeedbackListData = yield select(state => state.feedback.receivedFeedbackList.data)
  yield getCommonSaga({
    apiUrl: "user_feed_backs_list",
    payload: action.payload,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(fetchReceivedFeedbackListSuccess({
        user_feed_backs: [...receivedFeedbackListData.user_feed_backs, ...data.user_feed_backs],
        total_user_feed_backs: data.user_feed_backs,
      }));
    },
    failure: fetchReceivedFeedbackListFailure,
  });
}


export default function* FeedbackSaga() {
  yield all([
    yield takeLatest('feedback/saveFeedbackStart', saveFeedbackAPI),
    yield takeLatest('feedback/fetchFeedbackListStart', fetchFeedbackListAPI),
    yield takeLatest('feedback/fetchSingleFeedbackStart', fetchSingleFeedbackAPI),
    yield takeLatest('feedback/deleteFeedbackStart', deleteFeedbackAPI),
    yield takeLatest('feedback/fetchReceivedFeedbackListStart', fetchReceivedFeedbackListAPI),
  ]);
}