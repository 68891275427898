import React, { useState, useEffect } from "react";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  receiveTokenStart,
  fetchWalletDetailsStart,
} from "../../store/slices/WalletSlice";
import { t } from "react-multi-lang";

const ReceiveCryptoCurrencyModal = (props) => {

  const [skipRender, setSkipRender] = useState(true);

  const handleReceiveToken = () => {
    props.dispatch(receiveTokenStart({
      token_type: props.tokenType,
    }));
  }

  useEffect(() => {
    if (!skipRender && !props.receiveToken.loading && Object.keys(props.receiveToken.data).length > 0) {
      props.closeReceiveCryptoCurrencyModal();
      props.dispatch(fetchWalletDetailsStart());
    }
    setSkipRender(false)
  }, [props.receiveToken]);

  return (
    <>
      <Modal
        className="modal-dialog-center send-crypto-currency-modal"
        size="md"
        centered
        show={props.receiveCryptoCurrency}
        onHide={props.closeReceiveCryptoCurrencyModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Image
              className="send-icon"
              src={
                window.location.origin + "/assets/img/wallet/receive-icon.svg"
              }
            />
            <span>{t("receive_bitcoin")}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="send-crypto-currency-form">
            <div className="receive-crypto-currency-card">
              <div className="receive-qr-code-img-sec">
                <Image
                  className="receive-qr-code-img"
                  src={
                    window.location.origin + "/assets/img/wallet/qr-code.png"
                  }
                />
              </div>
              <h4>{t("your_bitcoin_address")}</h4>
              <p>{t("use_this_address_to_deposit_bitcoin")} (t("btc")):</p>
              <h6>36dTNbtVRCvQs5zWpV1aoc2ZkX5cAKrhS6</h6>
            </div>
            <div className="send-crypto-wallet-btn-sec">
              <Button className="default-btn w-100">
               {t("copy_address")}
              </Button>
              <Button
                className="default-btn w-100 mt-3"
                onClick={() => handleReceiveToken()}
                disabled={props.receiveToken.buttonDisable}
              >
                {props.receiveToken.buttonDisable ?
                  t("loading")
                : t("receive_tokens")
                }
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  receiveToken: state.wallet.receiveToken,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(ReceiveCryptoCurrencyModal);