import React, { useState, useEffect } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./Auth.css";
import { Link, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { withTranslation, useTranslation } from "react-multi-lang";
import { userLoginStart } from "../../store/slices/UserSlice";
import VerificationModal from "./VerificationModal";
import TwoStepVerificationModal from "./TwoStepVerificationModal";
import { Error, PasswordField } from '../Common/FormUtils';
import configuration from 'react-global-configuration';

const LoginIndex = (props) => {
  const t = useTranslation();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const [verification, setVerification] = useState({
    user: false,
    twoStep: false,
  });

  const closeVerificationModal = () => {
    setVerification({
      user: false,
      twoStep: false,
    });
  }

  const loginSchema = Yup.object().shape({
    username: Yup.string().required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, t("must_have_6_characters")),
  });

  const handleLogin = values => {
    setUsername(values.username);
    props.dispatch(userLoginStart(values));
  }

  useEffect(() => {
    if (!skipRender && !props.login.loading && Object.keys(props.login.data).length > 0) {
      if (props.login.data.is_two_step_auth_enabled == 1) {
        setVerification({ user: false, twoStep: true });
      } else if (props.login.data.is_email_verified == 0 && props.login.data.is_mobile_verified == 0) {
        setVerification({ user: props.login.data.verification_type, twoStep: false });
      } else {
        navigate("/");
      }
    }
    setSkipRender(false);
  }, [props.login]);

  return (
    <>
      <section className="auth-sec">
      <div className="vertical-center">
        <Container>
            <Row className="align-items-center">
              <Col md={7}>
                <div className="auth-img-sec">
                  <Image
                    className="auth-img"
                    src={
                      window.location.origin + "/assets/img/auth/login-img.png"
                    }
                  />
                </div>
              </Col>
              <Col md={5}>
                <div className="auth-box">
                  <div className="auth-logo-sec">
                    <Image
                      className="auth-logo-img"
                      src={configuration.get("configData.site_dark_logo")}
                    />
                  </div>
                  <h3>{t("login_with_nearbuycoin")}</h3>
                  <Formik
                    initialValues={{
                      username: "",
                      password: "",
                    }}
                    validationSchema={loginSchema}
                    onSubmit={(values) => handleLogin(values)}
                  >
                    {({ touched, errors }) =>
                      <FORM className="auth-form mt-0">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>{t("phone_number_or_email")}</Form.Label>
                          <Field
                            className="form-control"
                            name="username"
                            placeholder={t("phone_number_or_email_placeholder")}
                          />
                          <Error name="username" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("password")}</Form.Label>
                          <PasswordField
                            name="password"
                            placeholder={t("password_placeholder")}
                          />
                          <Error name="password" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Link to="/forgot-password" className="referral-link">
                            {t("forgot_password")}
                          </Link>
                        </Form.Group>
                        <div className="auth-btn-sec">
                          <Button
                            type="submit"
                            className="default-btn w-100"
                            disabled={props.login.buttonDisable}
                          >
                            {props.login.buttonDisable ?
                              t("loading")
                              : t("login")
                            }
                          </Button>
                        </div>
                        <div className="divider"></div>
                        <div className="auth-footer-sec">
                          <h5>{t("new_to_nearbycoin")}</h5>
                          <Link to="/register">
                            {t("create_an_account")}
                          </Link>
                        </div>
                      </FORM>
                    }
                  </Formik>
                </div>
              </Col>
            </Row>
        </Container>
        </div>
      </section>
      {verification.user ?
        <VerificationModal
          closeModal={closeVerificationModal}
          username={username}
          verificationType={verification.user}
        />
        : null
      }
      {verification.twoStep ?
        <TwoStepVerificationModal
          closeModal={closeVerificationModal}
          username={username}
        />
        : null
      }
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.user.login,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(LoginIndex));