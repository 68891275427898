import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import PageLoader from '../Common/PageLoader'
import { fetchSinglePageStart } from '../../store/slices/PageSlice'
import SomethingWentWrong from '../Common/SomethingWentWrong'
import { withTranslation, useTranslation } from 'react-multi-lang'
import { Container, Row } from 'react-bootstrap'

const StaticIndex = (props) => {
  const t = useTranslation()
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if(params)
      props.dispatch(fetchSinglePageStart({
        static_page_unique_id: params.static_page_unique_id
      }))
  }, [params.static_page_unique_id])

  return (
    <>
      {props.pageData.loading ?
        <PageLoader />
        : Object.keys(props.pageData.data).length > 0 && props.pageData.data.title ?
          <Container>
            <Row>
              <div className="wallet-balance-box mt-3">
                <div className="wallet-balance-card">
                  <h6>{props.pageData.data.title}</h6>
                </div>
              </div>
              <div className="wallet-balance-box mt-3">
                <p
                  dangerouslySetInnerHTML={{
                    __html: props.pageData.data.description,
                  }}
                ></p>
              </div>
            </Row>
          </Container>
          : <SomethingWentWrong
            showReload={true}
            reloadText={t("go_to_home")}
            onReload={() => navigate("/")}
          />
      }
    </>
  );
}

const mapStateToPros = (state) => ({
  pageData: state.page.pageData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(StaticIndex))