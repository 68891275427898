import React, { useEffect, useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./Profile.css";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import ActiveOffersSec from "./ActiveOffersSec";
import FeedBackIndex from "./FeedBackIndex";
import { fetchOtherUserDetailsStart, fetchOtherUserDetailsSuccess } from "../../../store/slices/UserSlice";
import { connect } from "react-redux";
import { withTranslation, useTranslation } from "react-multi-lang";
import PageLoader from "../../Common/PageLoader";
import SomethingWentWrong from "../../Common/SomethingWentWrong";
import { fetchActiveTokenOffersStart } from "../../../store/slices/OfferSlice";

const ProfileIndex = (props) => {
  const t = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (params.username) {
      props.dispatch(fetchOtherUserDetailsStart({
        username: params.username,
      }));
    }
  }, [params.username]);

  useEffect(() => {
    if (!params.username && !props.profile.loading && Object.keys(props.profile.data).length > 0) {
      props.dispatch(fetchOtherUserDetailsSuccess(
        props.profile.data
      ));
    }
  }, [params, props.profile]);


  return (
    <>
      {props.otherUser.loading ?
        <PageLoader />
        : props.otherUser.data.user_id ?
          <div className="profile-sec">
            <Container>
              <Row>
                <Col md={12}>
                  <div className="profile-header-sec">
                    <Row className="align-items-center">
                      <Col md={12} xl={8} lg={8} className="resp-marg-btm-md">
                        <div className="profile-header-user-box">
                          <div className="profile-header-user-img-sec">
                            <Image
                              className="profile-header-user-img"
                              src={props.otherUser.data.picture}
                            />
                          </div>
                          <div className="profile-header-user-info">
                            <h3>{props.otherUser.data.name}</h3>
                            <p>
                              <Image
                                className="profile-header-user-seen-icon"
                                src={
                                  window.location.origin + "/assets/img/profile/eye-icon.svg"
                                }
                              />
                              {props.profile.data.last_seen_formatted}
                            </p>
                            <div className="profile-feedback-box">
                              <div className="profile-feedback-card">
                                <div className="profile-feedback-count">
                                  <h5>+{props.otherUser.data.positive_feed_backs_count}</h5>
                                  <p>{t("positive_feedback")}</p>
                                </div>
                                <Image
                                  className="profile-feedback-icon"
                                  src={
                                    window.location.origin + "/assets/img/profile/like-icon.svg"
                                  }
                                />
                              </div>
                              <div className="profile-feedback-card">
                                <div className="profile-feedback-count">
                                  <h5>+{props.otherUser.data.negative_feed_backs_count}</h5>
                                  <p>{t("negative_feedback")}</p>
                                </div>
                                <Image
                                  className="profile-feedback-icon"
                                  src={
                                    window.location.origin + "/assets/img/profile/dislike.svg"
                                  }
                                />
                              </div>
                            </div>
                            {props.otherUser.data.facebook_link ||
                              props.otherUser.data.twitch_link ||
                              props.otherUser.data.twitter_link ||
                              props.otherUser.data.skype_link ||
                              props.otherUser.data.instagram_link ?
                              <div className="profile-social-link-sec">
                                <ul className="list-unstyled profile-social-link">
                                  {props.otherUser.data.facebook_link ?
                                    <li>
                                      <a href={props.otherUser.data.facebook_link} target="_blank">
                                        <Image
                                          className="profile-social-link-icon"
                                          src={
                                            window.location.origin + "/assets/img/profile/facebook-icon.svg"
                                          }
                                        />
                                      </a>
                                    </li> : null
                                  }
                                  {props.otherUser.data.twitch_link ?
                                    <li>
                                      <a href={props.otherUser.data.twitch_link} target="_blank">
                                        <Image
                                          className="profile-social-link-icon"
                                          src={
                                            window.location.origin + "/assets/img/profile/twitch-icon.svg"
                                          }
                                        />
                                      </a>
                                    </li> : null
                                  }
                                  {props.otherUser.data.twitter_link ?
                                    <li>
                                      <a href={props.otherUser.data.twitter_link} target="_blank">
                                        <Image
                                          className="profile-social-link-icon"
                                          src={
                                            window.location.origin + "/assets/img/profile/twitter-icon.svg"
                                          }
                                        />
                                      </a>
                                    </li> : null
                                  }
                                  {props.otherUser.data.skype_link ?
                                    <li>
                                      <a href={props.otherUser.data.skype_link} target="_blank">
                                        <Image
                                          className="profile-social-link-icon"
                                          src={
                                            window.location.origin + "/assets/img/profile/skype-icon.svg"
                                          }
                                        />
                                      </a>
                                    </li> : null
                                  }
                                  {props.otherUser.data.instagram_link ?
                                    <li>
                                      <a href={props.otherUser.data.instagram_link} target="_blank">
                                        <Image
                                          className="profile-social-link-icon"
                                          src={
                                            window.location.origin + "/assets/img/profile/insta-icon.svg"
                                          }
                                        />
                                      </a>
                                    </li> : null
                                  }
                                </ul>
                              </div>
                              : null
                            }
                          </div>
                        </div>
                      </Col>
                      <Col md={12} xl={4} lg={4}>
                        <div className="profile-verified-box">
                          {/* <div className="profile-verified-card">
                            Address Verified
                            <span>
                              <Image
                                className="profile-verified-tick-icon"
                                src={
                                  window.location.origin + "/assets/img/profile/tick-mark-icon.svg"
                                }
                              />
                            </span>
                          </div> */}
                          {props.otherUser.data.email ?
                            <div className="profile-verified-card">
                              {props.otherUser.data.is_email_verified
                                ? t("email_verified")
                                : t("email_not_verified")
                              }
                              <span>
                                <Image
                                  className="profile-verified-tick-icon"
                                  src={props.otherUser.data.is_email_verified ?
                                    window.location.origin + "/assets/img/profile/tick.png"
                                    : window.location.origin + "/assets/img/profile/wrong.png"
                                  }
                                />
                              </span>
                            </div>
                            : null
                          }
                          {props.otherUser.data.mobile ?
                            <div className="profile-verified-card">
                              {props.otherUser.data.is_mobile_verified
                                ? t("phone_verified")
                                : t("phone_not_verified")
                              }
                              <span>
                                <Image
                                  className="profile-verified-tick-icon"
                                  src={props.otherUser.data.is_mobile_verified ?
                                    window.location.origin + "/assets/img/profile/tick.png"
                                    : window.location.origin + "/assets/img/profile/wrong.png"
                                  }
                                />
                              </span>
                            </div> : null
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="profile-details-box">
                    <div className="profile-details-card">
                      <h5>
                        <span>
                          <Image
                            className="profile-location-icon"
                            src={
                              window.location.origin + "/assets/img/profile/vietnam-flag.svg"
                            }
                          />
                        </span>
                        {props.otherUser.data.location}
                      </h5>
                      <p>{t("location")}</p>
                    </div>
                    {props.otherUser.data.language ? 
                    <div className="profile-details-card">
                      <h5>
                     { props.otherUser.data.language }
                      </h5>
                      <p>{t("language")}</p>
                    </div>
                    : null}
                    <div className="profile-details-card">
                      <h5>
                        {props.otherUser.data.trade_partners}
                      </h5>
                      <p>{t("trade_partners")}</p>
                    </div>
                    <div className="profile-details-card">
                      <h5>
                        {props.otherUser.data.trades}
                      </h5>
                      <p>{t("trades")}</p>
                    </div>
                    <div className="profile-details-card">
                      <h5>
                        {props.otherUser.data.trade_volume}
                      </h5>
                      <p>{t("trades_volume")}</p>
                    </div>
                    {props.otherUser.data.trusted_by_count ?
                      <div className="profile-details-card">
                        <h5>
                          {props.otherUser.data.trusted_by_count}
                        </h5>
                        <p>{t("trusted_by")}</p>
                      </div>
                      :
                      null}
                    <div className="profile-details-card">
                      <h5>
                        {props.otherUser.data.blocked_by_count}
                      </h5>
                      <p>{t("has_blocked")}</p>
                    </div>
                    <div className="profile-details-card">
                      <h5>
                        {props.otherUser.data.joined_formatted}
                      </h5>
                      <p>{t("joined")}</p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <ActiveOffersSec
                    username={props.otherUser.data.username}
                  />
                  <FeedBackIndex
                    username={props.otherUser.data.username}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          : <SomethingWentWrong
            showReload={true}
            reloadText={t("go_to_home")}
            onReload={() => navigate("/")}
          />
      }
    </>
  );
};


const mapStateToPros = (state) => ({
  profile: state.user.profile,
  otherUser: state.user.otherUser,
  activeTokenOffers: state.offer.activeTokenOffers,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ProfileIndex));