import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getCommonSaga } from '../sagaUtils';
import store from '../index';
import {
  userLoginSuccess,
  userLoginFailure,
  userRegisterSuccess,
  userRegisterFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyForgotPasswordSuccess,
  resetPasswordSuccess,
  resetPasswordFailure,
  updateUserDetailsSuccess,
  updateUserDetailsFailure,
  fetchUserDetailsSuccess,
  fetchUserDetailsFailure,
  verifyForgotPasswordFailure,
  resendForgotPasswordSuccess,
  resendForgotPasswordFailure,
  userVerificationSuccess,
  userVerificationFailure,
  resendUserVerificationSuccess,
  resendUserVerificationFailure,
  twoStepAuthFailure,
  twoStepAuthSuccess,
  resendTwoStepSuccess,
  resendTwoStepFailure,
  updateTwoStepAuthSuccess,
  updateTwoStepAuthFailure,
  changePasswordSuccess,
  changePasswordFailure,
  usernameValidationSuccess,
  usernameValidationFailure,
  updateProfileCredentialsSuccess,
  updateProfileCredentialsFailure,
  validateUpdateCredentialsSuccess,
  validateUpdateCredentialsFailure,
  updateProfileUPISuccess,
  updateProfileUPIFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateNotificationSuccess,
  updateNotificationFailure,
  fetchOtherUserDetailsSuccess,
  fetchOtherUserDetailsFailure,
  kycApplicantSuccess,
  kycApplicantFailure,
  kycStatusUpdateSuccess,
  kycStatusUpdateFailure,
  becomeASellerSuccess,
  becomeASellerFailure,
  userBillingsListFailure,
  userBillingsListSuccess,
  userBillingsSaveFailure,
  userBillingsSaveSuccess,
  updateExchangeValueSuccess,
  updateExchangeValueFailure,
} from '../slices/UserSlice';

function* userLoginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, },
    successCallback: true,
    success: (data) => {
      if ((data.is_email_verified == 0 && data.is_mobile_verified == 0)
        || data.is_two_step_auth_enabled === 1) {
        localStorage.setItem("email", data.email);
      } else {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("userId", data.user_id);
        localStorage.setItem("accessToken", data.token);
        localStorage.setItem("user_unique_id", data.user_unique_id );
      }
      store.dispatch(userLoginSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: userLoginFailure,
  });
}

function* userRegisterAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, },
    successCallback: true,
    success: (data) => {
      if (data.is_email_verified == 0 && data.is_mobile_verified == 0) {
        localStorage.setItem("email", data.email);
      } else {
        localStorage.setItem("userLoginStatus", true);
        localStorage.setItem("userId", data.user_id);
        localStorage.setItem("accessToken", data.token);
      }
      store.dispatch(userRegisterSuccess(data));
    },
    failure: userRegisterFailure,
  });
}

function* userVerificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_user_account",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("userId", data.user_id);
      localStorage.setItem("accessToken", data.token);
      store.dispatch(userVerificationSuccess(data));
    },
    failure: userVerificationFailure,
  });
}

function* resendUserVerificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "resend_verification_code",
    payload: action.payload,
    successCallback: true,
    success: (data) => store.dispatch(resendUserVerificationSuccess({ data: data })),
    failure: resendUserVerificationFailure,
  });
}

function* twoStepAuthAPI(action) {
  yield getCommonSaga({
    apiUrl: "two_step_auth_login",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, },
    successCallback: true,
    success: (data) => {
      localStorage.setItem("userLoginStatus", true);
      localStorage.setItem("userId", data.user_id);
      localStorage.setItem("accessToken", data.token);
      store.dispatch(twoStepAuthSuccess(data));
    },
    failure: twoStepAuthFailure,
  });
}

function* resendTwoStepAPI(action) {
  yield getCommonSaga({
    apiUrl: "resend_two_step_auth_code",
    payload: action.payload,
    successCallback: true,
    success: (data) => store.dispatch(resendTwoStepSuccess({ data: data })),
    failure: resendTwoStepFailure,
  });
}

function* forgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.payload,
    success: forgotPasswordSuccess,
    failure: forgotPasswordFailure,
  });
}

function* resendForgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "forgot_password",
    payload: action.payload,
    success: resendForgotPasswordSuccess,
    failure: resendForgotPasswordFailure,
  });
}

function* verifyForgotPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "verify_forgot_password_code",
    payload: action.payload,
    success: verifyForgotPasswordSuccess,
    failure: verifyForgotPasswordFailure,
  });
}


function* resetPasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "reset_password",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(resetPasswordSuccess({ data: data }));
    },
    failure: resetPasswordFailure,
  });
}

function* getUserDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.payload,
    successNotify: false,
    success: fetchUserDetailsSuccess,
    failure: fetchUserDetailsFailure,
  });
}

function* updateUserDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: { ...action.payload, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, },
    successCallback: true,
    success: data => {
      store.dispatch(updateUserDetailsSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: updateUserDetailsFailure,
  });
}

function* updateTwoStepAuthAPI(action) {
  yield getCommonSaga({
    apiUrl: "two_step_auth_update",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateTwoStepAuthSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: updateTwoStepAuthFailure,
  });
}

function* changePasswordAPI(action) {
  yield getCommonSaga({
    apiUrl: "change_password",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(changePasswordSuccess({ data: data }))
    },
    failure: changePasswordFailure,
  });
}

function* usernameValidationAPI(action) {
  yield getCommonSaga({
    apiUrl: "username_validation",
    payload: action.payload,
    successNotify: false,
    errorNotify: false,
    successCallback: true,
    failureCallback: true,
    success: data => store.dispatch(usernameValidationSuccess({ isAvailable: true })),
    failure: error => store.dispatch(usernameValidationSuccess({ isAvailable: false, message: error })),
  });
}

function* updateProfileCredentialsAPI(action) {
  yield getCommonSaga({
    apiUrl: "generate_verification_code_for_profile_credentials",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(updateProfileCredentialsSuccess({ data: data })),
    failure: updateProfileCredentialsFailure,
  });
}

function* validateUpdateCredentialsAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile_credentials",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(validateUpdateCredentialsSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: validateUpdateCredentialsFailure,
  });
}

function* updateProfileUPIAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile_upiid",
    payload: action.payload,
    successCallback: true,
    success: data => {
      store.dispatch(updateProfileUPISuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: updateProfileUPIFailure,
  });
}

function* deleteAccountAPI(action) {
  yield getCommonSaga({
    apiUrl: "delete_account",
    payload: action.payload,
    successCallback: true,
    success: data => store.dispatch(deleteAccountSuccess({ data: data })),
    failure: deleteAccountFailure,
  });
}

function* updateNotificationAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_notification_status",
    payload: action.payload,
    successCallback: true,
    success: (data) => {
      store.dispatch(updateNotificationSuccess(data));
      store.dispatch(fetchUserDetailsSuccess(data));
    },
    failure: updateNotificationFailure,
  })
}

function* fetchOtherUserDetailsAPI(action) {
  yield getCommonSaga({
    apiUrl: "other_profile",
    payload: action.payload,
    success: fetchOtherUserDetailsSuccess,
    failure: fetchOtherUserDetailsFailure,
  })
}

function* kycApplicantAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_create_applicant",
    payload: action.payload,
    successNotify: false,
    success: kycApplicantSuccess,
    failure: kycApplicantFailure,
  });
}

function* kycStatusUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_kyc",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(fetchUserDetailsSuccess(data));
      store.dispatch(kycStatusUpdateSuccess(data));
    },
    failure: kycStatusUpdateFailure,
  });
}

function* becomeASellerAPI(action) {
  yield getCommonSaga({
    apiUrl: "request_to_became_seller",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(fetchUserDetailsSuccess(data));
      store.dispatch(becomeASellerSuccess(data));
    },
    failure: becomeASellerFailure,
  });
}

function* userBillingsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_billings_list",
    payload: action.payload,
    successNotify: false,
    success: userBillingsListSuccess,
    failure: userBillingsListFailure,
  });
}

function* userBillingsSaveAPI(action) {
  const userBillingsListData = yield select((state)=> state.user.userBillingsList.data);
  yield getCommonSaga({
    apiUrl: "user_billing_save",
    payload: action.payload,
    successNotify: true,
    successCallback: true,
    success: data => {
      store.dispatch(userBillingsSaveSuccess(data));
      if(Object.keys(userBillingsListData).length > 0)
      {
        store.dispatch(userBillingsListSuccess({
          ...userBillingsListData,
          user_billing_accounts: data.user_billing_account,
        }));
      }
    },
    failure: userBillingsSaveFailure,
  });
}

function* updateExchangeValueAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_exchange_value",
    payload: action.payload,
    successNotify: true,
    success: updateExchangeValueSuccess,
    failure: updateExchangeValueFailure,
  });
}


export default function* UserSaga() {
  yield all([
    yield takeLatest('user/userLoginStart', userLoginAPI),
    yield takeLatest('user/userRegisterStart', userRegisterAPI),
    yield takeLatest('user/userVerificationStart', userVerificationAPI),
    yield takeLatest('user/resendUserVerificationStart', resendUserVerificationAPI),
    yield takeLatest('user/twoStepAuthStart', twoStepAuthAPI),
    yield takeLatest('user/resendTwoStepStart', resendTwoStepAPI),
    yield takeLatest('user/forgotPasswordStart', forgotPasswordAPI),
    yield takeLatest('user/resendForgotPasswordStart', resendForgotPasswordAPI),
    yield takeLatest('user/verifyForgotPasswordStart', verifyForgotPasswordAPI),
    yield takeLatest('user/resetPasswordStart', resetPasswordAPI),
    yield takeLatest('user/fetchUserDetailsStart', getUserDetailsAPI),
    yield takeLatest('user/updateUserDetailsStart', updateUserDetailsAPI),
    yield takeLatest('user/updateTwoStepAuthStart', updateTwoStepAuthAPI),
    yield takeLatest('user/changePasswordStart', changePasswordAPI),
    yield takeLatest('user/usernameValidationStart', usernameValidationAPI),
    yield takeLatest('user/updateProfileCredentialsStart', updateProfileCredentialsAPI),
    yield takeLatest('user/validateUpdateCredentialsStart', validateUpdateCredentialsAPI),
    yield takeLatest('user/updateProfileUPIStart', updateProfileUPIAPI),
    yield takeLatest('user/deleteAccountStart', deleteAccountAPI),
    yield takeLatest('user/updateNotificationStart', updateNotificationAPI),
    yield takeLatest('user/fetchOtherUserDetailsStart', fetchOtherUserDetailsAPI),
    yield takeLatest('user/kycApplicantStart', kycApplicantAPI),
    yield takeLatest('user/kycStatusUpdateStart', kycStatusUpdateAPI),
    yield takeLatest('user/becomeASellerStart', becomeASellerAPI),
    yield takeLatest('user/userBillingsListStart', userBillingsListAPI),
    yield takeLatest('user/userBillingsSaveStart', userBillingsSaveAPI),
    yield takeLatest('user/updateExchangeValueStart', updateExchangeValueAPI),
  ]);
}