import React, { useState, useEffect, useRef } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import 'react-phone-input-2/lib/style.css';
import { Formik, Form as FORM } from "formik";
import { Error } from "../Common/FormUtils";
import { useTranslation, withTranslation } from "react-multi-lang";
import { connect } from "react-redux";

const ChangeNumberForm = (props) => {
  const t = useTranslation();
  const formRef = useRef();
  const [value, setValue] = useState();

  const validateMobileNumber = (value) => {
    console.log(value);
    if (value) {
      if (!isPossiblePhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Not a Phone number");
        return false;
      } else if (!isValidPhoneNumber(value)) {
        formRef.current.setFieldError("mobile", "Invalid Phone number");
        return false;
      } else {
        formRef.current.setFieldError("mobile", "");
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", "Required");
      return false;
    }
  };

  const handleChangeMobile = (values) => {
    if (validateMobileNumber("+" + values.mobile)) {
      const intlNo = formatPhoneNumberIntl("+" + values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      if (props.profile.data.mobile === mobile) {
        formRef.current.setFieldError("mobile", t("mobile_already_using"));
      } else {
        props.handleChangeCredentials({
          mobile: mobile,
          country_code: countryCode,
        });
      }
    }
  }

  return (
    <>
      <Formik
        initialValues={{
          mobile: "",
        }}
        // validationSchema={mobileRegisterSchema}
        onSubmit={(values) => handleChangeMobile(values)}
        innerRef={formRef}
      >
        {({ touched, errors, values, setFieldValue }) =>
          <FORM>
            <div className="change-number-step-1">
              <div className="change-number-info">
                <h4>Step - 1</h4>
                <Form.Group className="mb-3">
                  <Form.Label>Enter the phone number to get the otp</Form.Label>
                  <PhoneInput
                    placeholder={t("enter_phone_number")}
                    value={values.mobile}
                    onChange={val => setFieldValue("mobile", val)}
                    country={'in'}
                  />
                  <Error name="mobile" />
                </Form.Group>
              </div>
              <div className="send-otp-btn-sec">
                <Button
                  type="submit"
                  className="next-btn w-100"
                  disabled={props.updateProfileCredentials.buttonDisable}
                >
                  {props.updateProfileCredentials.buttonDisable ?
                    t("loading")
                    : t("send_verification_code")
                  }
                </Button>
              </div>
            </div>
          </FORM>
        }
      </Formik>
    </>
  );
};

const mapStateToPros = (state) => ({
  updateProfileCredentials: state.user.updateProfileCredentials,
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(withTranslation(ChangeNumberForm));