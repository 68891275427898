import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Image,
  Modal,
  Row,
  Col,
  Tab,
  Nav,
  InputGroup,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import CenterLoader from "../Common/CenterLoader";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-multi-lang";
import { Formik, Form as FORM, Field } from "formik";
import * as Yup from "yup";
import { Error } from "../Common/FormUtils";
import {
  upiSaveStart,
} from "../../store/slices/WalletSlice";

const AddUPIModal = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const [skipRender, setSkipRender] = useState(true);

  const upiSave = useSelector((state) => state.wallet.upiSave);
  const profile = useSelector((state) => state.user.profile);

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required("Required"),
    upi_id: Yup.string().required("Required"),
  });

  const handleSubmit = (values) => {
    dispatch(upiSaveStart(props.edit ? {
      ...values,
      trader_upi_id: props.addUPI.trader_upi_id,
      trader_unique_id: profile.data.reference_unique_id
    } : {
      ...values,
      trader_unique_id: profile.data.reference_unique_id
    }));
  };

  useEffect(() => {
    if (!skipRender && !upiSave.loading && Object.keys(upiSave.data).length > 0) {
      props.closeAddUPIModal();
    }
    setSkipRender(false);
  }, [upiSave]);

  return (
    <>
      <Modal
        className="modal-dialog-center kyc-update-modal"
        size="md"
        centered
        show={props.addUPI}
        onHide={props.closeAddUPIModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.edit ? "Edit" : "Add"} UPI</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="send-amount-sec">
            <React.Fragment>
              <Formik
                initialValues={{
                  // name: props.edit ? props.addUPI.name : "",
                  upi_id: props.edit ? props.addUPI.upi_id : "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
              >
                {({ errors, touched, values }) => (
                  <FORM className="setting-form" style={{ width: "100%" }}>
                    <div className="user-name-card">
                      {/* <Form.Group className="mb-3">
                        <Form.Label>Name</Form.Label>
                        <Field
                          className="form-control"
                          placeholder="Enter Name"
                          name="name"
                          type="text"
                        />
                        <Error name="name" />
                      </Form.Group> */}
                      <Form.Group className="mb-3">
                        <Form.Label>UPI ID</Form.Label>
                        <Field
                          className="form-control"
                          placeholder="Enter UPI"
                          name="upi_id"
                        />
                        <Error name="upi_id" />
                      </Form.Group>
                      <div className="btn-sec">
                        <Button
                          type="submit"
                          className="default-btn w-100"
                          disabled={upiSave.buttonDisable}
                        >
                          {upiSave.buttonDisable ? "Loading..." : props.edit ? "Update" : "Save"}
                        </Button>
                      </div>
                    </div>
                  </FORM>
                )}
              </Formik>
            </React.Fragment>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withTranslation(AddUPIModal);
