import React, { useState } from "react";
import { Container, Image, Nav, Row, Col, Tab, Form, InputGroup, Button } from "react-bootstrap";
import "./LandingPage.css";
import { Link } from "react-router-dom";

const BuySellFeaturesIndex = (props) => {


    return (
        <>
            <div className="buy-sell-feature-sec">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={9}>
                            <div className="section-title">
                                <h2>Methods at Your Fingertips</h2>
                                <p>Discover Our Extensive Marketplace with Over 350 Ways to Trade Crypto – Choose Your Preferred Payment Methods or Find Exciting New Options.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Tab.Container id="left-tabs-example" defaultActiveKey="buy">
                                <div className="buy-sell-tab-sec">
                                    <Row>
                                        <Col sm={12}>
                                            <Nav variant="pills">
                                            <Nav.Item>
                                                <Nav.Link eventKey="buy">BUY</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="sell">SELL</Nav.Link>
                                            </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content>
                                            <Tab.Pane eventKey="buy">
                                                <div className="buy-sell-tab-content-sec">
                                                    <Row className="justify-content-md-center">
                                                        <Col md={12} xl={12} lg={12}>
                                                            <div className="buy-sell-feature-box buy-sell-left">
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Bank Transfer</h4>
                                                                        <p>Streamline your crypto acquisition through our guided bank transfer system.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Bank Card</h4>
                                                                        <p>Instantly acquire crypto from users worldwide with your debit or credit card.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="buy-sell-feature-img-sec">
                                                                    <Image
                                                                        className="buy-sell-feature-img"
                                                                        src={
                                                                            window.location.origin + "/assets/img/landing-page/feature-left-img.png"
                                                                        }
                                                                    />
                                                                </div> */}
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Digital Wallet</h4>
                                                                        <p>Add Crypto to your portfolio through online wallets like PayPal, M-Pesa, and Apple Pay.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        {/* <Col md={12} xl={6} lg={6}>
                                                            <div className="buy-sell-feature-box buy-sell-right">
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Bank Transfer</h4>
                                                                        <p>Cash out your Crypto and collect payments with our guided bank transfer trades.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="buy-sell-feature-img-sec">
                                                                    <Image
                                                                        className="buy-sell-feature-img"
                                                                        src={
                                                                            window.location.origin + "/assets/img/landing-page/feature-right-img.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Digital Wallets</h4>
                                                                        <p>Hassle-free payment collection with widely-used wallets like MTN Mobile Money, M-Pesa. </p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Gift Cards</h4>
                                                                        <p>Leveraging Crypto for discounted deals on favorite gift cards.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col> */}
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="sell">
                                            <div className="buy-sell-tab-content-sec">
                                                    <Row className="justify-content-md-center">
                                                        {/* <Col md={12} xl={6} lg={6}>
                                                            <div className="buy-sell-feature-box buy-sell-left">
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Bank transfer</h4>
                                                                        <p>Our guided bank transfer trades quick and easy to buy Bitcoin.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Bank transfer</h4>
                                                                        <p>Our guided bank transfer trades quick and easy to buy Bitcoin.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="buy-sell-feature-img-sec">
                                                                    <Image
                                                                        className="buy-sell-feature-img"
                                                                        src={
                                                                            window.location.origin + "/assets/img/landing-page/feature-left-img.png"
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Bank transfer</h4>
                                                                        <p>Our guided bank transfer trades quick and easy to buy Bitcoin.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col> */}
                                                        <Col md={12} xl={12} lg={12}>
                                                            <div className="buy-sell-feature-box buy-sell-right">
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Bank Transfer</h4>
                                                                        <p>Cash out your Crypto and collect payments with our guided bank transfer trades.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="buy-sell-feature-img-sec">
                                                                    <Image
                                                                        className="buy-sell-feature-img"
                                                                        src={
                                                                            window.location.origin + "/assets/img/landing-page/feature-right-img.png"
                                                                        }
                                                                    />
                                                                </div> */}
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Digital Wallets</h4>
                                                                        <p>Hassle-free payment collection with widely-used wallets like MTN Mobile Money, M-Pesa. </p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="buy-sell-feature-card">
                                                                    <div className="buy-sell-card-info">
                                                                        <h4>Gift Cards</h4>
                                                                        <p>Leveraging Crypto for discounted deals on favorite gift cards.</p>
                                                                    </div>
                                                                    <div className="buy-sell-card-icon-sec">
                                                                        <Image
                                                                            className="buy-sell-card-icon"
                                                                            src={
                                                                                window.location.origin + "/assets/img/landing-page/bank-icon.svg"
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col> 
                                                    </Row>
                                                </div>
                                            </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default BuySellFeaturesIndex;
