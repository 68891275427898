import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FooterIndex from "./Footer/FooterIndex";
import HeaderIndex from "./Header/HeaderIndex";
import { connect } from 'react-redux';
import { fetchUserDetailsStart } from "../../store/slices/UserSlice";

const MainLayout = (props) => {
  const { isTransparent = false } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("userId") &&
      localStorage.getItem("accessToken") &&
      localStorage.getItem("userLoginStatus") &&
      !props.profile.buttonDisable &&
      Object.keys(props.profile.data).length == 0) {
      props.dispatch(fetchUserDetailsStart());
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!skipRender && !props.profile.loading && Object.keys(props.profile.data).length === 0) {
      navigate("/logout");
    }
    setSkipRender(false);
  }, [props.profile]);

  return (
    <>
      <HeaderIndex isTransparent={isTransparent} />
      <div className="main-wrapper">
        {props.children}
      </div>
      <FooterIndex />
    </>
  );
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(MainLayout);
