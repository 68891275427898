import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Image, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createTokenOfferStart } from "../../store/slices/OfferSlice";
import dayjs from 'dayjs';
import { t } from "react-multi-lang";
import SomethingWentWrong from "../Common/SomethingWentWrong";

const ConfirmPaymentModal = ({ offerData, closeConfirmPaymentModal, create }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const createTokenOffer = useSelector(state => state.offer.createTokenOffer);

  const [step, setStep] = useState(1);
  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    setStep(1)
  }, []);

  const onConfirm = e => {
    e.preventDefault();
    const { token_type, offer_start_at, ...data } = offerData;

    dispatch(createTokenOfferStart({
      ...data,
      token_type: token_type.token,
      offer_start_at: dayjs(offer_start_at).format("YYYY-MM-DD HH:mm:ss"),
    }));
  }

  useEffect(() => {
    if (!skipRender && !createTokenOffer.loading) {
      if (Object.keys(createTokenOffer.data).length > 0) {
        setStep(2);
      } else {
        setStep(3);
      }
    }
    setSkipRender(false);
  }, [createTokenOffer]);

  return (
    <>
      <Modal
        className="modal-dialog-center change-number-modal"
        size="md"
        centered
        show={true}
      >
        <Modal.Header closeButton onHide={step == 1 ? closeConfirmPaymentModal : () => navigate("/my-offers")}>
          <Modal.Title>{create ? "Create Offer" : "Update Offer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="confirm-payment-form">
            <div className="confirm-payment-box">
              {step == 1 && (
                <div className="confirm-payment-sec">
                  <div className="confirm-payment-header-box">
                    <h4>{t("selling")}</h4>
                    <div className="confirm-chain-display">
                      <Image
                        className="sell-bnb-icon"
                        src={offerData.token_type.token_icon}
                      />
                      <span>{offerData.token_type.token}</span>
                    </div>
                  </div>
                  <div className="confirm-payment-value-box">
                    <h4>{offerData.token_type.token} Value</h4>
                    <p>{offerData.total_tokens} {offerData.token_type.token} - <span>{offerData.total_tokens * offerData.single_token_rate} {offerData.token_rate_type}</span></p>
                    <p>{t("offer_start_at")} - <span>{dayjs(offerData.offer_start_at).format('DD/MM/YYYY hh:mm')}</span></p>
                    <p>{t("offer_duration")} - <span>{offerData.offer_time_limit} {t("minutes")}</span></p>
                  </div>
                  <div className="confirm-payment-btn-sec">
                    <Button className="default-btn w-100" onClick={onConfirm} disabled={createTokenOffer.buttonDisable}>
                      {createTokenOffer.buttonDisable ? t("loading") : t("confirm")}
                    </Button>
                  </div>
                </div>
              )}

              {step == 2 && (
                <div className="confirm-payment-success-sec">
                  <div className="confirm-payment-success-img-sec">
                    <Image
                      className="confirm-payment-success-img"
                      src={
                        window.location.origin + "/assets/img/success.png"
                      }
                    />
                  </div>
                  <div className="confirm-payment-success-info">
                    {/* <h4>Offer Confirmed</h4> */}
                    <p>{t("your_offer_has_been")} {create ? t("created") : t("updated")}</p>
                  </div>
                  <div className="confirm-payment-btn-sec">
                    <Button className="default-btn w-100" onClick={() => navigate("/my-offers")}>
                  {  t("confirm")}
                    </Button>
                  </div>
                </div>
              )}

              {step == 3 && (
                <div className="confirm-payment-success-sec">
                  <div className="confirm-payment-success-img-sec">
                    <Image
                      className="confirm-payment-success-img"
                      src={
                        createTokenOffer.error.error_code === 115 || createTokenOffer.error.error_code === 116 ?
                          window.location.origin + "/assets/img/create-offers/low-balance.png"
                          : window.location.origin + "/assets/img/error.png"
                      }
                    />
                  </div>
                  {createTokenOffer.error.error_code === 115 || createTokenOffer.error.error_code === 116 ?
                    <>
                      <div className="confirm-payment-success-info">
                        <h4>{t("low_balance")}</h4>
                        <p>{t("add_amount_to_wallet_to_make_the_payments")}</p>
                      </div>
                      <div className="confirm-payment-btn-sec">
                        <Button className="default-btn w-100" onClick={() => navigate("/wallet")}>
                          {t("add_money_to_wallet")}
                        </Button>
                      </div>
                    </> : <>
                     <SomethingWentWrong />
                    </>
                  }
                </div>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmPaymentModal;