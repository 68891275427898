import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import { reducers } from './slices';

const saga = createSagaMiddleware();

const store = configureStore({
  reducer: reducers,
  middleware: [saga]
});
saga.run(rootSaga);
// saga.run(ProductSaga);

export default store;