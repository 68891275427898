import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  wallet: getCommonInitialState(),
  walletTransactionList: getCommonInitialState({wallet_transactions: [], total_wallet_transactions: 0}),
  singleWalletTransaction: getCommonInitialState(),
  transferToken: getCommonInitialState(),
  initiateUSDTPaymet: getCommonInitialState(),
  receiveToken: getCommonInitialState(),
  walletPaymentList: getCommonInitialState(),
  sendAmount: getCommonInitialState(),
  adminWalletDetails: getCommonInitialState(),
  adminPaymentDetails: getCommonInitialState(),
  upiList: getCommonInitialState(),
  upiSave: getCommonInitialState(),
  upiDelete: getCommonInitialState(),
  bankAccountList: getCommonInitialState(),
  bankAccountSave: getCommonInitialState(),
  bankAccountDelete: getCommonInitialState(),
  paymentMethods: getCommonInitialState(),
  paymentMethodsUpdate: getCommonInitialState(),
  checkPaymentMethod: getCommonInitialState(),
  transactionInitiate: getCommonInitialState(),
  userTransactionList: getCommonInitialState({transactions: [], total_transactions: 0}),
};

const WalletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {

    // Wallet Details
    fetchWalletDetailsStart: (state) => { state.wallet = getCommonStart() },
    fetchWalletDetailsSuccess: (state, action) => { state.wallet = getCommonSuccess(action.payload) },
    fetchWalletDetailsFailure: (state, action) => { state.wallet = getCommonFailure(action.payload) },

    //Wallet Transaction List[]
    fetchWalletTransactionListStart: (state, action) => { state.walletTransactionList = getCommonStart({wallet_transactions: [], total_wallet_transactions: 0}) },
    fetchWalletTransactionListSuccess: (state, action) => { state.walletTransactionList = getCommonSuccess(action.payload) },
    fetchWalletTransactionListFailure: (state, action) => { state.walletTransactionList = getCommonFailure(action.payload) },
    fetchMoreWalletTransaction: (state, action) => { state.walletTransactionList = state.walletTransactionList },

    //Wallet Transaction View
    fetchSingleWalletTransactionStart: (state, action) => { state.singleWalletTransaction = getCommonStart() },
    fetchSingleWalletTransactionSuccess: (state, action) => { state.singleWalletTransaction = getCommonSuccess(action.payload) },
    fetchSingleWalletTransactionFailure: (state, action) => { state.singleWalletTransaction = getCommonFailure(action.payload) },

    //Wallet Transfer Token
    transferTokenStart: (state, action) => { state.transferToken = getCommonStart() },
    transferTokenSuccess: (state, action) => { state.transferToken = getCommonSuccess(action.payload) },
    transferTokenFailure: (state, action) => { state.transferToken = getCommonFailure(action.payload) },

    //Wallet Transfer Token
    initiateUSDTPaymetStart: (state, action) => { state.initiateUSDTPaymet = getCommonStart() },
    initiateUSDTPaymetSuccess: (state, action) => { state.initiateUSDTPaymet = getCommonSuccess(action.payload) },
    initiateUSDTPaymetFailure: (state, action) => { state.initiateUSDTPaymet = getCommonFailure(action.payload) },

    //Wallet Receive Token (receive when product is live)
    receiveTokenStart: (state, action) => { state.receiveToken = getCommonStart() },
    receiveTokenSuccess: (state, action) => { state.receiveToken = getCommonSuccess(action.payload) },
    receiveTokenFailure: (state, action) => { state.receiveToken = getCommonFailure(action.payload) },

    //Wallet Payment List
    fetchWalletPaymentListStart: (state, action) => { state.walletPaymentList = getCommonStart() },
    fetchWalletPaymentListSuccess: (state, action) => { state.walletPaymentList = getCommonSuccess(action.payload) },
    fetchWalletPaymentListFailure: (state, action) => { state.walletPaymentList = getCommonFailure(action.payload) },

    //Send Amount
    sendAmountStart: (state, action) => { state.sendAmount = getCommonStart() },
    sendAmountSuccess: (state, action) => { state.sendAmount = getCommonSuccess(action.payload) },
    sendAmountFailure: (state, action) => { state.sendAmount = getCommonFailure(action.payload) },

    //Admin Payment Details
    adminPaymentDetailsStart: (state, action) => { state.adminPaymentDetails = getCommonStart() },
    adminPaymentDetailsSuccess: (state, action) => { state.adminPaymentDetails = getCommonSuccess(action.payload) },
    adminPaymentDetailsFailure: (state, action) => { state.adminPaymentDetails = getCommonFailure(action.payload) },
    moreAdminPaymentDetailsStart: (state, action) => { state.adminPaymentDetails = state.adminPaymentDetails },

    //Admin Payment Details
    adminWalletDetailsStart: (state, action) => { state.adminWalletDetails = getCommonStart() },
    adminWalletDetailsSuccess: (state, action) => { state.adminWalletDetails = getCommonSuccess(action.payload) },
    adminWalletDetailsFailure: (state, action) => { state.adminWalletDetails = getCommonFailure(action.payload) },

    //UPI List
    upiListStart: (state, action) => { state.upiList = getCommonStart() },
    upiListSuccess: (state, action) => { state.upiList = getCommonSuccess(action.payload) },
    upiListFailure: (state, action) => { state.upiList = getCommonFailure(action.payload) },

    //UPI Save
    upiSaveStart: (state, action) => { state.upiSave = getCommonStart(action.payload) },
    upiSaveSuccess: (state, action) => { state.upiSave = getCommonSuccess(action.payload) },
    upiSaveFailure: (state, action) => { state.upiSave = getCommonFailure(action.payload) },

    //UPI Delete
    upiDeleteStart: (state, action) => { state.upiDelete = getCommonStart(action.payload) },
    upiDeleteSuccess: (state, action) => { state.upiDelete = getCommonSuccess(action.payload) },
    upiDeleteFailure: (state, action) => { state.upiDelete = getCommonFailure(action.payload) },

    //Bank Account List
    bankAccountListStart: (state, action) => { state.bankAccountList = getCommonStart(action.payload) },
    bankAccountListSuccess: (state, action) => { state.bankAccountList = getCommonSuccess(action.payload) },
    bankAccountListFailure: (state, action) => { state.bankAccountList = getCommonFailure(action.payload) },

    //Bank Account Save
    bankAccountSaveStart: (state, action) => { state.bankAccountSave = getCommonStart(action.payload) },
    bankAccountSaveSuccess: (state, action) => { state.bankAccountSave = getCommonSuccess(action.payload) },
    bankAccountSaveFailure: (state, action) => { state.bankAccountSave = getCommonFailure(action.payload) },

    //Bank Account Delete
    bankAccountDeleteStart: (state, action) => { state.bankAccountDelete = getCommonStart(action.payload) },
    bankAccountDeleteSuccess: (state, action) => { state.bankAccountDelete = getCommonSuccess(action.payload) },
    bankAccountDeleteFailure: (state, action) => { state.bankAccountDelete = getCommonFailure(action.payload) },

    //Payment Methods
    paymentMethodsStart: (state, action) => { state.paymentMethods = getCommonStart(action.payload) },
    paymentMethodsSuccess: (state, action) => { state.paymentMethods = getCommonSuccess(action.payload) },
    paymentMethodsFailure: (state, action) => { state.paymentMethods = getCommonFailure(action.payload) },

    //Payment Methods Update
    paymentMethodsUpdateStart: (state, action) => { state.paymentMethodsUpdate = getCommonStart(action.payload) },
    paymentMethodsUpdateSuccess: (state, action) => { state.paymentMethodsUpdate = getCommonSuccess(action.payload) },
    paymentMethodsUpdateFailure: (state, action) => { state.paymentMethodsUpdate = getCommonFailure(action.payload) },

    checkPaymentMethodStart: (state, action) => { state.checkPaymentMethod = getCommonStart() },
    checkPaymentMethodSuccess: (state, action) => { state.checkPaymentMethod = getCommonSuccess(action.payload) },
    checkPaymentMethodFailure: (state, action) => { state.checkPaymentMethod = getCommonFailure(action.payload) },

    transactionInitiateStart: (state, action) => { state.transactionInitiate = getCommonStart() },
    transactionInitiateSuccess: (state, action) => { state.transactionInitiate = getCommonSuccess(action.payload) },
    transactionInitiateFailure: (state, action) => { state.transactionInitiate = getCommonFailure(action.payload) },

    //User Transaction List[]
    fetchUserTransactionListStart: (state, action) => { state.userTransactionList = getCommonStart({transactions: [], total_transactions: 0}) },
    fetchUserTransactionListSuccess: (state, action) => { state.userTransactionList = getCommonSuccess(action.payload) },
    fetchUserTransactionListFailure: (state, action) => { state.userTransactionList = getCommonFailure(action.payload) },
    fetchMoreUserTransaction: (state, action) => { state.userTransactionList = state.userTransactionList },
    
  }
});

export const {
  fetchWalletDetailsStart,
  fetchWalletDetailsSuccess,
  fetchWalletDetailsFailure,
  fetchWalletTransactionListStart,
  fetchWalletTransactionListSuccess,
  fetchWalletTransactionListFailure,
  fetchMoreWalletTransaction,
  fetchSingleWalletTransactionStart,
  fetchSingleWalletTransactionSuccess,
  fetchSingleWalletTransactionFailure,
  transferTokenStart,
  transferTokenSuccess,
  transferTokenFailure,
  initiateUSDTPaymetStart,
  initiateUSDTPaymetSuccess,
  initiateUSDTPaymetFailure,
  receiveTokenStart,
  receiveTokenSuccess,
  receiveTokenFailure,
  fetchWalletPaymentListFailure,
  fetchWalletPaymentListStart,
  fetchWalletPaymentListSuccess,
  sendAmountFailure,
  sendAmountStart,
  sendAmountSuccess,
  adminWalletDetailsFailure,
  adminWalletDetailsStart,
  adminWalletDetailsSuccess,
  adminPaymentDetailsFailure,
  adminPaymentDetailsStart,
  adminPaymentDetailsSuccess,
  moreAdminPaymentDetailsStart,
  upiDeleteFailure,
  upiDeleteStart,
  upiDeleteSuccess,
  upiListFailure,
  upiListStart,
  upiListSuccess,
  upiSaveFailure,
  upiSaveStart,
  upiSaveSuccess,
  bankAccountDeleteFailure,
  bankAccountDeleteStart,
  bankAccountDeleteSuccess,
  bankAccountListFailure,
  bankAccountListStart,
  bankAccountListSuccess,
  bankAccountSaveFailure,
  bankAccountSaveStart,
  bankAccountSaveSuccess,
  paymentMethodsFailure,
  paymentMethodsStart,
  paymentMethodsSuccess,
  paymentMethodsUpdateFailure,
  paymentMethodsUpdateStart,
  paymentMethodsUpdateSuccess,
  checkPaymentMethodStart,
  checkPaymentMethodSuccess,
  checkPaymentMethodFailure,
  transactionInitiateFailure,
  transactionInitiateStart,
  transactionInitiateSuccess,
  fetchUserTransactionListStart,
  fetchUserTransactionListSuccess,
  fetchUserTransactionListFailure,
  fetchMoreUserTransaction,
} = WalletSlice.actions;

export default WalletSlice.reducer;