import React, { useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Tab,
  Form,
  InputGroup,
  Button,
  FormGroup,
} from "react-bootstrap";
import "./CreateOffer.css";
import { Link } from "react-router-dom";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import { ErrorMsg } from "../Common/FormUtils";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../Common/PageLoader";
import {
  adminWalletDetailsStart,
  paymentMethodsStart,
  upiListStart,
  bankAccountListStart,
} from "../../store/slices/WalletSlice";
import { Formik, Form as FORM, Field } from "formik";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";

const PaymentMethodIndex = ({ offerData, setOfferData, setStep }) => {
  const t = useTranslation();

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);
  const adminWalletDetails = useSelector(
    (state) => state.wallet.adminWalletDetails
  );
  const paymentMethods = useSelector((state) => state.wallet.paymentMethods);
  const upiList = useSelector((state) => state.wallet.upiList);
  const bankAccountList = useSelector((state) => state.wallet.bankAccountList);

  // const [selectedToken, setSelectedToken] = useState(
  //   configuration.get("configData.project_tokens_formatted") &&
  //     configuration.get("configData.project_tokens_formatted").length > 0 ?
  //     configuration.get("configData.project_tokens_formatted")[0].token
  //     : null
  // );

  const [errorMsg, setErrorMsg] = useState({
    token_type: "",
    total_tokens: "",
    upi: "",
    transaction_hash: "",
    payment_meathod: "",
  });
  const [paymentMeathod, setPaymentMeathod] = useState("unavailable");

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(
        paymentMethodsStart({
          trader_unique_id: profile.data.reference_unique_id,
        })
      );
      if (Object.keys(upiList.data).length <= 0) {
        dispatch(upiListStart({trader_unique_id: profile.data.reference_unique_id}));
      }
      if (Object.keys(bankAccountList.data).length <= 0) {
        dispatch(bankAccountListStart({trader_unique_id: profile.data.reference_unique_id}));
      }
    }
  }, [profile]);

  const onStepComplete = (e) => {
    e.preventDefault();
    let error = {};
    if (!offerData.token_type) {
      error = { ...error, token_type: "select a cryptocurrency" };
    }
    if (!offerData.total_tokens)
      error = { ...error, total_tokens: t("required") };
    else if (offerData.total_tokens <= 0)
      error = { ...error, total_tokens: t("token should be greater than 0") };
    else if (Number.isNaN(Number(offerData.total_tokens)))
      error = { ...error, total_tokens: t("token should be number") };
    if (
      offerData.token_type.token != "USDT" &&
      !profile.data.upi_id &&
      Object.keys(profile.data.user_billing).length <= 0
    )
      error = {
        ...error,
        upi: "Update your UPI or Billing Account in Settings",
      };
    if (offerData.token_type.token == "USDT") {
      if (profile.data.type == 1 && !offerData.transaction_hash)
        error = {
          ...error,
          transaction_hash: t("required"),
        };
      if (paymentMeathod !== "available")
        error = {
          ...error,
          payment_meathod: t("payment_meathod_required"),
        };
      else if (!upiList.data?.total_upis && !bankAccountList.data?.total_bank_accounts) {
        error = {
          ...error,
          payment_meathod: t("upi_or_bank_required"),
        };
      }
    }
    
    if (Object.keys(error).length === 0) setStep((step) => step + 1);
    else setErrorMsg(error);
  };

  useEffect(() => {
    if (
      offerData.token_type.token == "USDT" &&
      Object.keys(adminWalletDetails.data).length <= 0
    )
      dispatch(adminWalletDetailsStart());
  }, [offerData.token_type.token]);

  useEffect(() => {
    if (
      !paymentMethods.loading &&
      Object.keys(paymentMethods.data).length > 0
    ) {
      const pamentMethod = paymentMethods.data.payment_methods.find(
        (method) => method.status == 1
      );
      if (pamentMethod) setPaymentMeathod("available");
    }
  }, [paymentMethods]);

  return profile.loading ? (
    <PageLoader />
  ) : (
    <>
      <div className="sell-about-this-step-box">
        <h4>{t("make_offer.about_step")}</h4>
        <p>{t("make_offer.about_step_content")}</p>
      </div>
      <div className="payment-method-sec">
        <div className="choose-your-cryptocurrency-box">
          <h4>{t("make_offer.pay_method_step.choose_crypto")}</h4>
          <div className="sell-choose-your-currency-box">
            <div className="sell-choose-your-currency-card">
              {configuration
                .get("configData.project_tokens_formatted")
                ?.map((token, i) => (
                  <label
                    className="radio-card"
                    key={i}
                    onClick={() =>
                      setOfferData({ ...offerData, token_type: token })
                    }
                  >
                    <input
                      type="radio"
                      name="radio-card"
                      checked={token.token === offerData.token_type.token}
                    />
                    <div className="card-content-wrapper">
                      <div className="card-content">
                        <Image
                          className="sell-bnb-icon"
                          src={token.token_icon}
                        />
                        <span>{token.token}</span>
                      </div>
                    </div>
                  </label>
                ))}
            </div>
            {errorMsg.token_type ? (
              <ErrorMsg msg={errorMsg.token_type} />
            ) : null}
          </div>
        </div>
        {Object.keys(adminWalletDetails.data).length > 0 &&
        profile.data.type == 1 ? (
          <div className="choose-your-cryptocurrency-box">
            <h4>Do you want to send money to admin?</h4>
            <div className="sell-choose-your-currency-box">
              <div className="text-center">
                <React.Fragment>
                  <CustomLazyLoad
                    className="qr-code-img mb-3"
                    src={adminWalletDetails.data.qr_code}
                    alt="qr_code"
                  />
                  <div className="user-name-card">
                    <Form.Label>Admin Wallet Address</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        className="form-control border-right"
                        name="language"
                        value={adminWalletDetails.data.wallet_address}
                      />
                      <InputGroup.Text
                        id="basic-addon1"
                        className="border-left"
                      >
                        <CopyToClipboard
                          text={adminWalletDetails.data.wallet_address}
                          onCopy={() =>
                            getSuccessNotificationMessage(
                              t("wallet_address_copied")
                            )
                          }
                        >
                          <Button className="edit-btn" type="submit">
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </React.Fragment>
              </div>
            </div>
          </div>
        ) : null}
        <div className="choose-your-cryptocurrency-box">
          <h4>{t("make_offer.pay_method_step.payment_method")}</h4>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-3">
                <Form.Label>
                  {t("make_offer.pay_method_step.total_tokens")}
                </Form.Label>
                <Form.Control
                  placeholder=""
                  value={offerData.total_tokens}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (Number.isNaN(Number(val))) e.preventDefault();
                    else
                      setOfferData({
                        ...offerData,
                        total_tokens: val,
                      });
                  }}
                />
                {errorMsg.total_tokens ? (
                  <ErrorMsg msg={errorMsg.total_tokens} />
                ) : null}
              </FormGroup>
            </Col>
            {offerData.token_type.token == "USDT" && profile.data.type == 1 ? (
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Form.Label>
                    {t("make_offer.pay_method_step.transaction_hash")}
                  </Form.Label>
                  <Form.Control
                    placeholder=""
                    value={offerData.transaction_hash}
                    type="text"
                    onChange={(e) => {
                      setOfferData({
                        ...offerData,
                        transaction_hash: e.target.value,
                      });
                    }}
                  />
                  {errorMsg.transaction_hash ? (
                    <ErrorMsg msg={errorMsg.transaction_hash} />
                  ) : null}
                </FormGroup>
              </Col>
            ) : null}
          </Row>
        </div>
        <div className="sell-footer-box">
          <Button className="next-step-btn" onClick={onStepComplete}>
            {t("make_offer.next_step")}
          </Button>
        </div>
        {errorMsg.upi ? (
          <ErrorMsg msg={errorMsg.upi} />
        ) : errorMsg.payment_meathod ? (
          <ErrorMsg msg={errorMsg.payment_meathod} />
        ) : null}
      </div>
    </>
  );
};

export default PaymentMethodIndex;
